import { Col, Row } from 'reactstrap';
import React, { useEffect, useMemo, useState } from 'react';

import AnimatedList from 'components/AnimatedList/AnimatedList';
import Button from 'components/Button';
import Form from 'components/Form/Form';
import { Input } from 'reactstrap';
import Link from 'components/Link';
import Pagination from 'components/Pagination';
import RegenerateConfigForAllSites from './components/RegenerateConfigForAllSites';
import SiteListItem from 'partials/sites/SiteListItem';
import Spinner from 'components/Spinner';
import _debounce from 'lodash/debounce';
import _sortBy from 'lodash/sortBy';
import _trim from 'lodash/trim';
import classNames from 'helpers/classNames';
import styles from './SitesListView.scss';
import { url } from 'core/Router';
import useCountries from 'hooks/useCountries';
import { useHistory } from 'react-router-dom';
import usePlans from 'hooks/usePlans';
import useSites from 'hooks/useSites';
import { useTranslation } from 'react-i18next';
import useUrlQuery from 'hooks/useUrlQuery';

const cx = classNames(styles);

const SitesListView = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [allCountries, allCountriesState] = useCountries({
        page: 1,
        per_page: -1,
    });
    const [allPlans, allPlansState] = usePlans();
    const countryOptions = useMemo(() => {
        const options = _sortBy(allCountries.data, (i) =>
            _trim((i.country_code || '').toLowerCase())
        );

        return options.map((country) => ({
            value: country.country_code,
            label: country.country_code || 'Not Set',
        }));
    }, [allCountries.data]);
    const planOptions = _sortBy(allPlans.data, (i) => i.config_name).filter(
        (p, j) => !allPlans.data[j + 1] || allPlans.data[j + 1].name !== p.name
    );
    const [query, updateQuery] = useUrlQuery();
    const [clients, setClients] = useState(query.clients ? query.clients : []);
    const [countries, setCountries] = useState(
        query.countries ? query.countries : []
    );

    const [planTypes, setPlanTypes] = useState(
        query.planTypes ? query.planTypes : []
    );

    const [search, setSearch] = useState(query.search || '');

    const updateSearchInQuery = useMemo(
        () =>
            _debounce((newSearch) => {
                updateQuery({
                    ...query,
                    search: newSearch,
                    page: undefined, // when searching, go back to first page
                });
            }, 300),
        [JSON.stringify(query)]
    );

    useEffect(() => updateSearchInQuery(search), [search]);
    useEffect(() => {
        updateQuery({
            ...query,
            clients,
            planTypes,
            countries,
            page: undefined, // when changing clients filter, go back to first page
        });
    }, [
        JSON.stringify(clients),
        JSON.stringify(countries),
        JSON.stringify(planTypes),
    ]);

    const resetFilters = () => {
        history.push({ search: '?search=' });
        setClients([]);
        setPlanTypes([]);
        setCountries([]);
        setSearch('');
    };
    const [sites, fetchMeta] = useSites({
        ...query,
        clients,
        planTypes,
        countries,
    });
    return (
        <>
            <div className={cx('mb-3')}>
                <h1 className={cx('mb-3')}>
                    {t('admin:sites.sites_list_view.title')}
                </h1>
                <h2 className={cx('h5')}>
                    {t('admin:sites.sites_list_view.intro')}
                </h2>
                <Row
                    className={cx(
                        'mt-3',
                        'justify-content-between',
                        'flex-md-row',
                        'flex-column'
                    )}
                >
                    <Col xs="12" md>
                        <Link
                            button
                            className={cx('actionBtn', 'mb-2', 'mb-md-0')}
                            icon="md/ic_add"
                            to={url('admin.sites.create')}
                        >
                            {t('admin:sites.sites_list_view.add_domain')}
                        </Link>
                    </Col>
                    <Col xs="12" md="auto">
                        <RegenerateConfigForAllSites
                            className={cx('actionBtn', 'mb-2')}
                        />
                    </Col>
                    <Col xs="12" md="auto">
                        <Button
                            label="Reset filters"
                            onClick={resetFilters}
                            className={cx('actionBtn', 'mt-6')}
                        />
                    </Col>
                </Row>
                <div className={cx('mt-3')}>
                    <Form
                        initialValues={{
                            search: '',
                            clients,
                            countries,
                            planTypes,
                        }}
                        onSubmit={() => null}
                    >
                        <Form.Row>
                            <Col xs="12" md="4" lg={3}>
                                <Form.Label>
                                    {t('common:common.search')}
                                </Form.Label>
                                <Input
                                    value={search}
                                    name="search"
                                    type="text"
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </Col>
                            <Col
                                xs="12"
                                md="4"
                                lg="3"
                                className={cx('mt-3', 'mt-md-0')}
                            >
                                <Form.Field
                                    label={t('common:common.filter_by_clients')}
                                    name="clients"
                                    type="SelectClient"
                                    placeholder="---"
                                    value={clients}
                                    onChange={(f, v) => {
                                        setClients(v ? [v] : []);
                                    }}
                                />
                            </Col>
                            <Col
                                xs="12"
                                md="4"
                                lg="3"
                                className={cx('mt-3', 'mt-md-0')}
                            >
                                <Form.Field
                                    label={t(
                                        'common:common.filter_by_countries'
                                    )}
                                    name="countries"
                                    type="DropdownInput"
                                    searchable
                                    placeholder={
                                        allCountriesState.isLoading
                                            ? t('common:common.loading')
                                            : '---'
                                    }
                                    value={countries}
                                    multiple
                                    onChange={(f, v) => setCountries([...v])}
                                    options={countryOptions}
                                />
                            </Col>
                            <Col
                                xs="12"
                                md="4"
                                lg="3"
                                className={cx('mt-3', 'mt-md-0')}
                            >
                                <Form.Field
                                    label={t('common:common.filter_by_plans')}
                                    name="planTypes"
                                    type="DropdownInput"
                                    searchable
                                    placeholder={
                                        allPlansState.isLoading
                                            ? t('common:common.loading')
                                            : '---'
                                    }
                                    value={planTypes}
                                    multiple
                                    onChange={(f, v) => setPlanTypes([...v])}
                                    options={planOptions.map((plan) => ({
                                        value: plan.config_name,
                                        label: plan.name,
                                    }))}
                                />
                            </Col>
                        </Form.Row>
                    </Form>
                </div>
            </div>

            {fetchMeta.isLoading && <Spinner />}
            {!fetchMeta.isLoading && fetchMeta.isSuccess && (
                <>
                    <AnimatedList
                        list={sites.data}
                        itemComponent={SiteListItem}
                        itemKey="uuid"
                    />
                    <Pagination
                        className={cx('mt-3')}
                        currentPage={sites.current_page}
                        lastPage={sites.last_page}
                    />
                </>
            )}
        </>
    );
};

export default SitesListView;
