import { Col, Row } from 'reactstrap';
import React, { useState } from 'react';

import API from 'helpers/api';
import AnimatedList from 'components/AnimatedList/AnimatedList';
import Button from 'components/Button';
import ClientBadge from 'components/ClientBadge';
import Link from 'components/Link';
import PropTypes from 'prop-types';
import ResellerBadge from 'components/ResellerBadge';
import Site from 'models/Site';
import SiteListItem from 'partials/sites/SiteListItem';
import _get from 'lodash/get';
import classNames from 'helpers/classNames';
import styles from './GroupListItem.scss';
import useAppState from 'hooks/useAppState';
import useExecuteRequest from 'hooks/useExecuteRequest';
import { useTranslation } from 'react-i18next';
import useUrl from 'hooks/useUrl';

const cx = classNames(styles);

const GroupListItem = ({ item }) => {
    const group = item;
    const [isExpanded, setIsExpanded] = useState(false);
    const { t } = useTranslation();
    const { user } = useAppState();
    const url = useUrl();
    const [del, , , delMeta] = useExecuteRequest();

    const handleDelete = (group) => {
        const monit = t('common:site_groups.common.are_you_sure_delete_group', {
            name: group.name,
        });
        if (!confirm(monit)) {
            return;
        }

        del(API.del(`common/site-groups/${group.uuid}`));
    };
    if (delMeta.isSuccess) {
        return null;
    }
    return (
        <>
            <div className={cx('item')}>
                <Row className={cx('align-items-center')}>
                    <Col xs="12" md>
                        <div>
                            {(user.is_admin || user.is_reseller) && (
                                <ClientBadge client={group.client} />
                            )}
                            {user.is_admin && (
                                <ResellerBadge client={group.client} />
                            )}
                        </div>
                        <div className={cx('font-weight-bold')}>
                            {group.name}
                        </div>
                    </Col>
                    <Col xs="12" md="auto">
                        <Link
                            className={cx('mr-2')}
                            icon="md/ic_build"
                            button
                            to={url('userNS.siteGroups.edit', {
                                group: group.uuid,
                            })}
                        >
                            {t('common:site_groups.common.edit')}
                        </Link>
                        <Button
                            color="danger"
                            disabled={delMeta.isLoading}
                            loading={delMeta.isLoading}
                            onClick={() => handleDelete(group)}
                            className={cx('mr-2')}
                            icon="md/ic_delete_forever"
                        >
                            {t('common:site_groups.common.delete')}
                        </Button>
                    </Col>
                    {group.sites.length > 0 && (
                        <Col xs="12" className={cx('mt-2')}>
                            <Button
                                size="sm"
                                outline
                                onClick={() => setIsExpanded((i) => !i)}
                                label={t('common:site_groups.list.sites')}
                                icon={
                                    isExpanded
                                        ? 'md/ic_expand_less'
                                        : 'md/ic_expand_more'
                                }
                                className={cx('mr-2')}
                            />
                        </Col>
                    )}
                </Row>
            </div>
            {group.sites.length > 0 && (
                <div
                    className={cx('pl-3', 'pl-md-5', 'mb-3', {
                        'd-none': !isExpanded,
                    })}
                >
                    <AnimatedList
                        list={group.sites.map((s) => new Site(s))}
                        itemComponent={SiteListItem}
                        noDelete
                    />
                </div>
            )}
        </>
    );
};

GroupListItem.propTypes = {
    item: PropTypes.object,
};

export default GroupListItem;
