import React, { useCallback, useMemo, useState } from 'react';

import Button from 'components/Button';
import ChoosePlanModal from 'components/ChoosePlanModal';
import PropTypes from 'prop-types';
import classNames from 'helpers/classNames';
import styles from './ChooseSiteSubscriptionPlan.scss';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const ChooseSiteSubscriptionPlan = ({
    value,
    onChange,
    disabled,
    invalid,
    btnLabel,
    ...props
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(value);
    const { t } = useTranslation();

    const handlePlanChosen = useCallback((plan) => {
        setSelectedPlan(plan);
        onChange(plan);
        setIsModalOpen(false);
    });

    const buttonLabel = useMemo(() => {
        if (btnLabel) {
            return btnLabel;
        }

        if (!selectedPlan) {
            return t('components:choose_site_plan_field.button_label');
        }

        const billingPeriod = t(
            `components:choose_plan.billing_period.${selectedPlan.billing_period}`
        );
        return `${selectedPlan.name} (${billingPeriod})`;
    }, [btnLabel, selectedPlan]);
    return (
        <div className={cx('form-control', { 'is-invalid': invalid }, 'input')}>
            <Button
                type="button"
                disabled={disabled}
                onClick={() => setIsModalOpen(true)}
            >
                {buttonLabel}
            </Button>
            {isModalOpen && (
                <ChoosePlanModal
                    {...props}
                    title={t('components:choose_site_plan_field.title')}
                    onClose={() => setIsModalOpen(false)}
                    isModalOpen
                    onPlanChosen={handlePlanChosen}
                    preselectedPlan={selectedPlan ? selectedPlan.uuid : null}
                />
            )}
        </div>
    );
};

ChooseSiteSubscriptionPlan.propTypes = {
    invalid: PropTypes.bool,
    btnLabel: PropTypes.node,
    disabled: PropTypes.bool,
    value: PropTypes.shape({
        uuid: PropTypes.string,
    }),
    onChange: PropTypes.func,
};

export default ChooseSiteSubscriptionPlan;
