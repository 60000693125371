import dayjs from 'helpers/dayjs';
import getPlanLabel from 'helpers/getPlanLabel';
import { useClientPolicy } from 'policies/ClientPolicy';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const FALSE_SHOW_TRIAL_RESPONSE = [false, null];

const usePlanButtonConfig = (site) => {
    const { t } = useTranslation();
    const canViewBilling = useClientPolicy('viewBilling', site.client);

    const [showTrialInfo, trialEndDate] = useMemo(() => {
        // user can't view billing
        if (!canViewBilling) {
            return FALSE_SHOW_TRIAL_RESPONSE;
        }

        // site uses free plan
        if (site.plan && site.plan.config_name === 'free') {
            return FALSE_SHOW_TRIAL_RESPONSE;
        }

        // client has no trial end date set
        if (!site.client.trial_ends_at) {
            return FALSE_SHOW_TRIAL_RESPONSE;
        }

        const end = dayjs.unix(site.client.trial_ends_at);
        if (
            end.isAfter(dayjs()) || // trial end is in the future
            !site.client.has_filled_payment_details // end is in the past and hasn't filled payment details
        ) {
            return [true, end];
        }

        return FALSE_SHOW_TRIAL_RESPONSE;
    }, [site, canViewBilling]);

    const [color, label, icon] = useMemo(() => {
        let color = 'danger';
        if (site.plan && (site.plan.config_name === 'free' || site.status)) {
            color = 'success';
        }

        let text = site.plan
            ? getPlanLabel(site.plan, t)
            : t('components:choose_site_plan_field.title');
        let icon = site.plan ? 'md/ic_done' : 'md/ic_clear';

        if (!showTrialInfo) {
            return [color, text, icon];
        }

        // check if trial is in future
        if (trialEndDate.isAfter(dayjs())) {
            icon = 'md/ic_hourglass_empty';
            color = 'warning';
            text += ` ${t('common:site_list_item.trial_ends_at')}`;
        } else {
            icon = 'md/ic_error_outline';
            color = 'danger';
            text += ` ${t('common:site_list_item.trial_ended')}`;
        }
        text += ` ${trialEndDate.format('LLL')}`;

        return [color, text, icon];
    }, [showTrialInfo, site, trialEndDate]);

    return { color, icon, label };
};

export default usePlanButtonConfig;
