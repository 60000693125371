import { Badge, Col, Row } from 'reactstrap';
import React, { useState } from 'react';

import Button from 'components/Button';
import DeleteClientModal from '../DeleteClientModal';
import Link from 'components/Link';
import PropTypes from 'prop-types';
import classNames from 'helpers/classNames';
import styles from './ClientListItem.scss';
import useAppState from 'hooks/useAppState';
import { useTranslation } from 'react-i18next';
import useUrl from 'hooks/useUrl';

const cx = classNames(styles);

const ClientListItem = ({ item, onDeleted }) => {
    const client = item || {};
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const { user } = useAppState();
    const { t } = useTranslation();
    const url = useUrl();
    const canDelete =
        user.is_admin ||
        (user.client &&
            user.client.is_reseller &&
            client.manager_uuid === user.client_uuid);
    return (
        <div className={cx('item', 'border-primary')}>
            <Row form className={cx('align-items-center')}>
                <Col md="auto">
                    <div className={cx('mb-2')}>
                        {client.name}
                        {!!client.email && (
                            <Link
                                to={url('userNS.clients.edit', {
                                    client: client.uuid,
                                })}
                                className={cx('ml-2')}
                            >
                                {client.email}
                            </Link>
                        )}
                    </div>
                    <div className={cx('d-flex', 'align-items-center')}>
                        {!!user.is_admin && !!client.is_reseller && (
                            <Badge color="success" className={cx('mr-2')}>
                                {t('common:client_listing_columns.is_reseller')}
                            </Badge>
                        )}
                        {!!user.is_admin && client.stripe_id && (
                            <Badge color="primary" className={cx('mr-2')}>
                                {t('common:client_listing_columns.stripe_id')}:{' '}
                                <span className={cx('user-select-all')}>
                                    {client.stripe_id}
                                </span>
                            </Badge>
                        )}
                        {!!user.is_admin && !client.is_self_managed && (
                            <Link
                                to={url('userNS.clients.edit', {
                                    client: client.manager_uuid,
                                })}
                                className={cx('mr-2', 'link')}
                            >
                                <Badge color="primary-darker">
                                    {t(
                                        'common:client_listing_columns.reseller'
                                    )}
                                    : {client.manager?.name || '---'}
                                </Badge>
                            </Link>
                        )}
                        <Link
                            to={url('userNS.sites.client', {
                                client: client.uuid,
                            })}
                            className={cx('mr-2', 'link')}
                        >
                            <Badge color="info">
                                {t(
                                    'common:client_listing_columns.number_of_sites'
                                )}
                                : {client.sites_count || 0}
                            </Badge>
                        </Link>
                        <Link
                            to={url('userNS.users.client', {
                                client: client.uuid,
                            })}
                            className={cx('mr-2', 'link')}
                        >
                            <Badge color="info">
                                {t(
                                    'common:client_listing_columns.number_of_users'
                                )}
                                : {client.users_count || 0}
                            </Badge>
                        </Link>
                    </div>
                </Col>
                <Col md />
                <Col md="auto" xs="12" className={cx('mt-3', 'mt-md-0')}>
                    <Link
                        icon="md/ic_build"
                        button
                        className={cx('w-100')}
                        to={url('userNS.clients.edit', {
                            client: client.uuid,
                        })}
                    >
                        {t('common:buttons.settings')}
                    </Link>
                </Col>
                <Col md="auto" xs="12" className={cx('mt-3', 'mt-md-0')}>
                    <Button
                        icon="md/ic_delete_forever"
                        // don't allow deletion of the current user's client
                        disabled={user.client.uuid === client.uuid}
                        color="danger"
                        className={cx('w-100')}
                        onClick={() => {
                            setShowDeleteModal(true);
                        }}
                    >
                        {t('common:buttons.delete')}
                    </Button>
                </Col>
            </Row>
            {!!canDelete && (
                <DeleteClientModal
                    isOpen={showDeleteModal}
                    client={client}
                    onSuccess={onDeleted}
                    onCancel={() => setShowDeleteModal(false)}
                />
            )}
        </div>
    );
};

ClientListItem.propTypes = {
    item: PropTypes.shape({}),
    onDeleted: PropTypes.func,
};

export default ClientListItem;
