import PropTypes from 'prop-types';
import React from 'react';
import createAsyncComponent from 'helpers/createAsyncComponent';
const PaginationContainer = createAsyncComponent(() =>
    import('./PaginationContainer')
);
const PaginationComponent = createAsyncComponent(() => import('./Pagination'));

const Pagination = ({ onChange, ...props }) => {
    if (typeof onChange !== 'function') {
        return <PaginationContainer {...props} />;
    }

    return <PaginationComponent onChange={onChange} {...props} />;
};

Pagination.propTypes = {
    onChange: PropTypes.func,
};

Pagination.defaultProps = {
    onChange: undefined,
};

export default Pagination;
