import useApiNamespace from 'hooks/useApiNamespace';
import useListing from 'hooks/useListing';

const useClients = (params, execute = true) => {
    const namespace = useApiNamespace();
    const res = useListing(`${namespace}/clients`, params, execute);
    return res;
};

export default useClients;
