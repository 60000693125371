import { Col, Row } from 'reactstrap';

import API from 'helpers/api';
import Button from 'components/Button/Button';
import Chart from 'react-apexcharts';
import React from 'react';
import Spinner from 'components/Spinner';
import _get from 'lodash/get';
import classNames from 'helpers/classNames';
import styles from './HomeView.scss';
import useConsentsChart from './useConsentsChart';
import useConsentsPerSiteChart from './useConsentsPerSiteChart';
import useCustomersChart from './useCustomersChart';
import usePlansChart from './usePlansChart';
import usePlansPerCountryChart from './usePlansPerCountryChart';
import useRequest from 'hooks/useRequest';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const HomeView = () => {
    const { t } = useTranslation();
    const [res, err, fetchingMeta, reload] = useRequest(
        API.get('/admin/dashboard')
    );

    const customersChart = useCustomersChart(res);
    const plansChartData = usePlansChart(res);
    const plansPerCountryChartData = usePlansPerCountryChart(res);
    const consentsChartData = useConsentsChart(res);
    const consentsPerSiteData = useConsentsPerSiteChart(res);

    if (fetchingMeta.isLoading || !res) {
        return <Spinner />;
    }

    return (
        <>
            <div
                className={cx(
                    'd-flex',
                    'align-items-xs-center',
                    'justify-content-between',
                    'align-items-start',
                    'flex-xs-row',
                    'flex-column',
                    'mb-xs-0',
                    'mb-4'
                )}
            >
                <h1>Dashboard</h1>
                <Button
                    icon="md/ic_autorenew"
                    label="Reload"
                    onClick={reload}
                />
            </div>
            <Row className={cx('align-items-stretch')}>
                <Col xl="6">
                    <div
                        className={cx('p-3', 'shadow-lg', 'bg-white', 'h-100')}
                    >
                        <h2>Clients and sites created over the last month</h2>
                        <Chart {...customersChart} type="line" height={355} />
                    </div>
                </Col>
                <Col xl="6" className={cx('mt-2', 'mt-md-0')}>
                    <div
                        className={cx('p-3', 'shadow-lg', 'bg-white', 'h-100')}
                    >
                        <h2>Plans used for sites</h2>
                        <Chart {...plansChartData} type="donut" height={355} />
                    </div>
                </Col>
                <Col lg="12" className={cx('mt-2', 'mt-md-3')}>
                    <div
                        className={cx('p-3', 'shadow-lg', 'bg-white', 'h-100')}
                    >
                        <h2>Plan spread across client countries</h2>
                        <Chart {...plansPerCountryChartData} />
                    </div>
                </Col>
                <Col lg="12" className={cx('mt-2', 'mt-md-3')}>
                    <div
                        className={cx('p-3', 'shadow-lg', 'bg-white', 'h-100')}
                    >
                        <h2>Consents (excluding today)</h2>
                        <Chart {...consentsChartData} height={355} />
                    </div>
                </Col>
                <Col lg="12" className={cx('mt-2', 'mt-md-3')}>
                    <div
                        className={cx('p-3', 'shadow-lg', 'bg-white', 'h-100')}
                    >
                        <h2>
                            Top consents per domain over the last 30 days
                            (excluding today and consent resets)
                        </h2>
                        <Chart {...consentsPerSiteData} />
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default HomeView;
