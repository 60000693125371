import { Col, Row } from 'reactstrap';
import React, { useCallback, useEffect } from 'react';

import API from 'helpers/api';
import Form from 'components/Form';
import Site from 'models/Site';
import _get from 'lodash/get';
import classNames from 'helpers/classNames';
import { clearCache as clearPlansCache } from 'hooks/usePlans';
import { url } from 'core/Router';
import useAppState from 'hooks/useAppState';
import { useClientPolicy } from 'policies/ClientPolicy';
import useClients from 'hooks/useClients';
import useExecuteRequest from 'hooks/useExecuteRequest';
import { useHistory } from 'react-router-dom';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';

const cx = classNames();
const INITIAL_VALUES = {
    name: '',
    base_url: '',
    privacy_policy_url: '',
    additional_link: '',
    dev_urls: [''],
    client_uuid: '',
    plan: null,
    widget_languages: ['en'],
    used_cookie_categories: ['necessary'],
};
const CreateSiteView = () => {
    const { t } = useTranslation();
    const { user } = useAppState();
    const notify = useNotifications();
    const history = useHistory();
    const canListClients = useClientPolicy('viewAny');
    const [request, res, err, creationState] = useExecuteRequest();
    const [clients] = useClients({ page: 1, per_page: -1 }, canListClients);

    const handleSubmit = useCallback(
        (form) => {
            request(
                API.post('/client/sites', {
                    ...form,
                    plan_uuid: form.plan_uuid ? form.plan_uuid.uuid : null,
                    client_uuid: canListClients
                        ? Array.isArray(form.client_uuid)
                            ? form.client_uuid[0]
                            : form.client_uuid
                        : user.client_uuid,
                })
            );
        },
        [request]
    );

    useEffect(() => {
        if (creationState.isSuccess) {
            notify.success(t('common:create_site_view.domain_created'));
            clearPlansCache();
            history.push(url('dash.sites'));
        }
        if (creationState.isError) {
            notify.error(err.message);
        }
    }, [creationState.isError, creationState.isSuccess]);
    return (
        <>
            <h1>{t('common:create_site_view.title')}</h1>
            <Form
                initialValues={{
                    ...INITIAL_VALUES,
                    client_uuid: user.client_uuid,
                }}
                isSubmitting={creationState.isLoading}
                error={err}
                onSubmit={handleSubmit}
            >
                {({ data: formData }) => {
                    const newSite = new Site(formData);
                    const selectedClientUuid = Array.isArray(
                        formData.client_uuid
                    )
                        ? formData.client_uuid[0]
                        : formData.client_uuid;
                    const selectedClient = selectedClientUuid
                        ? clients.data.find(
                              (x) => x.uuid === selectedClientUuid
                          )
                        : false;
                    return (
                        <Row>
                            <Col xs="12" lg="6">
                                <Row>
                                    <Col xs="12" md="6">
                                        <Form.Field
                                            label={t(
                                                'client:field_labels.site.name'
                                            )}
                                            name="name"
                                            required
                                        />
                                    </Col>
                                    {!!canListClients && (
                                        <Col xs="12" md="6">
                                            <Form.Field
                                                label={t(
                                                    'client:field_labels.site.client_uuid'
                                                )}
                                                type="DropdownInput"
                                                searchable
                                                name="client_uuid"
                                                required
                                                showDefaultOption
                                                disabled={!clients.data.length}
                                                defaultOptionLabel={
                                                    clients.data.length
                                                        ? t(
                                                              'common:common.select_owner'
                                                          )
                                                        : t(
                                                              'common:common.loading'
                                                          )
                                                }
                                                options={clients.data.map(
                                                    (client) => ({
                                                        value: client.uuid,
                                                        label: client.name,
                                                    })
                                                )}
                                            />
                                        </Col>
                                    )}
                                    <Col xs="12" md="6">
                                        <Form.Field
                                            label={t(
                                                'client:field_labels.site.plan'
                                            )}
                                            type="ChooseSiteSubscriptionPlan"
                                            name="plan_uuid"
                                            clientUuid={formData.client_uuid}
                                            trialEndsAt={_get(
                                                selectedClient,
                                                'trial_ends_at'
                                            )}
                                            disabled={!formData.client_uuid}
                                            required
                                        />
                                    </Col>
                                    <Col xs="12" md="6">
                                        <Form.Field
                                            label={t(
                                                'client:field_labels.site.base_url'
                                            )}
                                            type="url"
                                            placeholder="https://yourdomain.com"
                                            name="base_url"
                                            required
                                        />
                                    </Col>
                                    <Col xs="12" md="6">
                                        <Form.Field
                                            label={t(
                                                'client:field_labels.site.privacy_policy_url'
                                            )}
                                            placeholder="https://yourdomain.com/privacy-policy"
                                            name="privacy_policy_url"
                                            type="url"
                                            required
                                        />
                                    </Col>
                                    <Col xs="12" md="6">
                                        <Form.Field
                                            label={t(
                                                'client:field_labels.site.widget_languages'
                                            )}
                                            placeholder={t(
                                                'client:field_labels.site.select_language'
                                            )}
                                            name="widget_languages"
                                            required
                                            type="LanguageDropdown"
                                            multiple={
                                                newSite.getPlanFeature(
                                                    'lang_limit'
                                                ) === false
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Form.Submit icon="md/ic_save">
                                    {t('common:buttons.save')}
                                </Form.Submit>
                            </Col>
                        </Row>
                    );
                }}
            </Form>
        </>
    );
};

export default CreateSiteView;
