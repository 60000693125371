import { Col, Row } from 'reactstrap';
import React, { useEffect } from 'react';

import API from 'helpers/api';
import EditCustomScriptForm from '../components/EditCustomScriptForm';
import Notification from 'components/Notification';
import Spinner from 'components/Spinner';
import classNames from 'helpers/classNames';
import { convertImageBase64ToBlob } from 'helpers/images';
import styles from './CreateCustomScriptView.scss';
import { url } from 'core/Router';
import useExecuteRequest from 'hooks/useExecuteRequest';
import { useHistory } from 'react-router-dom';
import useNotifications from 'hooks/useNotifications';
import useRequest from 'hooks/useRequest';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const CreateCustomScriptView = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const notify = useNotifications();
    const [script, fetchErr, fetchMeta] = useRequest(
        API.get('/client/custom-scripts/create')
    );
    const [save, saveRes, saveErr, saveMeta] = useExecuteRequest();

    const handleSubmit = (input) => {
        const url = '/client/custom-scripts';
        const saveRequest = API.request('POST', url, {
            ...input,
            logo: convertImageBase64ToBlob(input.logo || '') || null,
        });
        save(saveRequest);
    };

    useEffect(() => {
        if (saveRes && saveRes.uuid) {
            notify.success(
                t('client:custom-scripts.create_custom_script_view.created')
            );
            history.push(
                url('dash.customScripts.edit', {
                    script: saveRes.uuid,
                })
            );
        }
    }, [saveRes]);

    if (fetchMeta.isLoading || !script) {
        return <Spinner />;
    }

    return (
        <>
            {saveMeta.isError && (
                <Notification type="error">{saveErr.message}</Notification>
            )}
            <h1>
                {t('client:custom-scripts.create_custom_script_view.title')}
            </h1>
            <Row>
                <Col xs="12" sm="8" md="4">
                    <EditCustomScriptForm
                        script={script}
                        saveError={saveErr}
                        isSaving={saveMeta.isLoading}
                        onSubmit={handleSubmit}
                    />
                </Col>
            </Row>
        </>
    );
};

CreateCustomScriptView.propTypes = {};

CreateCustomScriptView.defaultProps = {};

export default CreateCustomScriptView;
