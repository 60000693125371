import { Input } from 'reactstrap';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'helpers/classNames';
import styles from './TextInput.scss';

const cx = classNames(styles);

const TextInput = ({ value, ...props }) => {
    return <Input value={value || ''} {...props} />;
};

TextInput.propTypes = {
    value: PropTypes.string,
};

export default TextInput;
