import 'react-quill/dist/quill.snow.css';

import PropTypes from 'prop-types';
import React from 'react';
import ReactQuill from 'react-quill';
import classNames from 'helpers/classNames';
import modules from 'constants/quillDefalutModules';
import styles from './QuillTextEditor.scss';

const cx = classNames(styles);

const QuillTextEditor = ({ name, value, inlineLabel, onChange, ...props }) => {
    return (
        <ReactQuill
            className={cx('h5', 'quill-field')}
            modules={modules}
            theme="snow"
            value={value || ''}
            onChange={(content) => onChange(content)}
        />
    );
};

QuillTextEditor.propTypes = {
    name: PropTypes.string,
    inlineLabel: PropTypes.node,
    onChange: PropTypes.func,
    value: PropTypes.any,
};

export default QuillTextEditor;
