import { Col, Row } from 'reactstrap';

import PropTypes from 'prop-types';
import React from 'react';
import Switch from 'components/Switch';
import _noop from 'lodash/noop';
import classNames from 'helpers/classNames';
import styles from './ChoiceGroup.scss';

const cx = classNames(styles);

const ChoiceItem = ({
    name,
    value,
    label,
    checked,
    type = 'checkbox',
    invalid,
    onChange,
    disabled,
    ...props
}) => (
    <label className={cx('label', { disabled })} aria-invalid={invalid}>
        <input
            type={type}
            tabIndex="-1"
            value={value}
            readOnly
            name={name}
            onChange={onChange}
            className={cx('checkbox', 'form-control', {
                'is-invalid': invalid,
            })}
            checked={!!checked}
            disabled={disabled}
        />
        <Row noGutters className={cx('row', { disabled })}>
            <Col xs="auto" className={cx('switchContainer', 'px-0')}>
                <Switch
                    checked={!!checked}
                    onChange={_noop}
                    disabled={disabled}
                />
            </Col>
            <Col xs className={cx('pl-2')}>
                {label}
            </Col>
        </Row>
    </label>
);

ChoiceItem.propTypes = {
    className: PropTypes.string,
    label: PropTypes.node,
    name: PropTypes.string,
    type: PropTypes.oneOf(['checkbox', 'radio']),
    onChange: PropTypes.func,
    errorFeedback: PropTypes.node,
    value: PropTypes.any,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    invalid: PropTypes.bool,
};

export default ChoiceItem;
