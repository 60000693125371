import React, { useCallback, useEffect } from 'react';

import API from 'helpers/api';
import Button from 'components/Button';
import Form from 'components/Form';
import Modal from 'components/Modal';
import MultilineText from 'components/MultilineText';
import PropTypes from 'prop-types';
import classNames from 'helpers/classNames';
import styles from './ConfigureConfigRegeneration.scss';
import useExecuteRequest from 'hooks/useExecuteRequest';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const ConfigureConfigRegeneration = ({ isOpen, onClose }) => {
    const { t } = useTranslation();
    const notify = useNotifications();
    const [req, res, err, regenState] = useExecuteRequest();

    const handleRegenerate = useCallback((settings) => {
        notify.info('Starting generation...');
        req(API.post('/admin/sites/regenerate-configs', settings));
    }, []);

    useEffect(() => {
        if (regenState.isSuccess) {
            notify.success(
                <>
                    Generation finished. <MultilineText text={res.output} />
                </>,
                { timeout: 100 * 1000, className: cx('successNotification') }
            );
        }
        if (regenState.isError) {
            notify.error(err.message);
        }
    }, [JSON.stringify(regenState)]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            disabled={regenState.isLoading}
            title="Configure regeneration"
        >
            <Form
                onSubmit={handleRegenerate}
                error={err}
                initialValues={{
                    site_uuid: [],
                    plan: [],
                    billing_period: [],
                    client: [],
                    check_dangling: false,
                }}
            >
                <Modal.Body>
                    <Form.Field
                        label="Domains"
                        helpText="Enter UUIDs of domains which should be regenerated. Leave empty for all."
                        name="site_uuid"
                        type="Repeater"
                        itemType="text"
                        getNewRow={() => ''}
                    />
                    <Form.Field
                        label="Plans"
                        helpText="Select for domains with which plans configs should be regenerated. Leave empty for all."
                        name="plan"
                        type="Repeater"
                        itemType="Select"
                        rowProps={{
                            showDefaultOption: true,
                            options: [
                                { value: 'free', label: 'Free' },
                                { value: 'basic', label: 'Basic' },
                                { value: 'plus', label: 'Plus' },
                            ],
                        }}
                    />
                    <Form.Field
                        label="Billing periods"
                        helpText="Select for domains with which billing periods configs should be regenerated. Leave empty for all."
                        name="billing_period"
                        type="Repeater"
                        itemType="Select"
                        rowProps={{
                            showDefaultOption: true,
                            options: [
                                { value: 'monthly', label: 'Monthly' },
                                { value: 'yearly', label: 'Yearly' },
                            ],
                        }}
                    />
                    <Form.Field
                        label="Clients"
                        helpText="Specify clients, whose sites should be regenerated. Leave empty for all."
                        name="client"
                        type="Repeater"
                        itemType="SelectClient"
                    />
                    <Form.Field
                        label="Dangling files"
                        helpText="Select if generator should check for undeleted config files (leftovers after url has been changed, domain disabled, etc). These undeleted files allow to still show banner on such urls."
                        inlineLabel="Check for undeleted files"
                        name="check_dangling"
                        type="Toggle"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        outline
                        disabled={regenState.isLoading}
                        label={t('common:confirm_modal.cancel')}
                        onClick={onClose}
                    />
                    <Button
                        type="submit"
                        loading={regenState.isLoading}
                        label={t('common:confirm_modal.confirm')}
                    />
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

ConfigureConfigRegeneration.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
};

ConfigureConfigRegeneration.defaultProps = {};

export default ConfigureConfigRegeneration;
