import { useCallback, useEffect, useMemo, useState } from 'react';
import useListing from './useListing';

const INITIAL_STATE = Object.freeze({
    data: [],
    page: 1,
    hasMore: false,
});

const useListingLoadMore = (endpoint = null, params = {}, execute = true) => {
    const [{ data, page, hasMore }, setState] = useState(INITIAL_STATE);
    const finalParams = useMemo(
        () => ({
            ...params,
            page,
        }),
        [JSON.stringify(params), page]
    );
    const [res, fetchMeta, err, reload] = useListing(
        endpoint,
        finalParams,
        execute
    );

    const handleReload = useCallback(() => {
        setState(INITIAL_STATE);
        reload();
    }, [reload]);

    const loadMore = useCallback(() => {
        setState((state) => ({ ...state, page: state.page + 1 }));
    }, [page]);

    // reset state when params change
    useEffect(() => {
        setState(INITIAL_STATE);
    }, [JSON.stringify(params)]);

    // set correct state when request finishes
    useEffect(() => {
        if (fetchMeta.isSuccess) {
            setState((state) => ({
                data: [...state.data, ...(res.data || [])],
                page: res.current_page,
                hasMore: res.current_page < res.last_page,
            }));
        }
    }, [fetchMeta.isSuccess]);

    return [
        { ...res, data },
        {
            ...fetchMeta,
            hasMore,
            isLoadingInitial: fetchMeta.isLoading && page === 1,
            isLoadingMore: fetchMeta.isLoading && page > 1,
            loadMore,
        },
        err,
        handleReload,
    ];
};

export default useListingLoadMore;
