import { Col, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';

import AnimatedList from 'components/AnimatedList/AnimatedList';
import Form from 'components/Form';
import Link from 'components/Link';
import Pagination from 'components/Pagination';
import SearchField from 'components/SearchField';
import Spinner from 'components/Spinner';
import ThirdPartyScriptListItem from './components/ThirdPartyScriptListItem';
import classNames from 'helpers/classNames';
import styles from './ThirdPartyScriptsListView.scss';
import { url } from 'core/Router';
import useListing from 'hooks/useListing';
import useScriptCategories from 'hooks/useScriptCategories';
import { useTranslation } from 'react-i18next';
import useUrlQuery from 'hooks/useUrlQuery';

const cx = classNames(styles);

const ThirdPartyScriptsListView = () => {
    const { t } = useTranslation();
    const [query, updateQuery] = useUrlQuery();
    const [list, fetchMeta] = useListing('/admin/third-party-scripts', query);
    const [searchedCategories, setSearchedCategories] = useState(
        query.categories || []
    );
    const [categories, categoriesMeta] = useScriptCategories();

    useEffect(() => {
        updateQuery({
            ...query,
            categories: searchedCategories,
            page: undefined,
        });
    }, [searchedCategories]);

    return (
        <>
            <div>
                <h1 className={cx('mb-3')}>
                    {t(
                        'admin:third-party-scripts.third_part_scripts_list_view.title'
                    )}
                </h1>
            </div>

            <Row className={cx('align-items-end', 'mb-3')}>
                <Col sm="12" lg="4" xl="6">
                    <Link
                        button
                        icon="md/ic_add"
                        to={url('admin.thirdPartyScripts.create')}
                    >
                        {t(
                            'admin:third-party-scripts.third_part_scripts_list_view.new_script'
                        )}
                    </Link>
                </Col>
                <Col sm="12" lg="8" xl="6" className={cx('mt-3 mt-lg-0')}>
                    <Form
                        initialValues={{
                            search: '',
                            categories: searchedCategories,
                        }}
                        onSubmit={() => null}
                    >
                        <Form.Row className={cx('justify-content-end')}>
                            <Col cx="12" sm="6">
                                <SearchField
                                    label={t('common:common.search')}
                                    className={cx('d-flex')}
                                />
                            </Col>
                            <Col xs="12" sm="6" lg="6">
                                <Form.Field
                                    name="categories"
                                    type="DropdownInput"
                                    searchable
                                    placeholder={t(
                                        'common:site_third_party_scripts.select_script_card:all_categories'
                                    )}
                                    multiple
                                    onChange={(f, v) => {
                                        setSearchedCategories(v);
                                    }}
                                    label={t(
                                        'common:common.filter_by_categories'
                                    )}
                                    options={categories.data}
                                    className={cx('mb-0')}
                                />
                            </Col>
                        </Form.Row>
                    </Form>
                </Col>
            </Row>

            {fetchMeta.isLoading && <Spinner />}
            {!fetchMeta.isLoading && fetchMeta.isSuccess && (
                <div className={cx('px-3')}>
                    <AnimatedList
                        list={list.data}
                        itemComponent={ThirdPartyScriptListItem}
                        itemKey="uuid"
                    />
                    <div className={cx('pb-3', 'pb-md-0')}>
                        <Pagination
                            className={cx('mt-3')}
                            currentPage={list.current_page}
                            lastPage={list.last_page}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

ThirdPartyScriptsListView.propTypes = {};

ThirdPartyScriptsListView.defaultProps = {};

export default ThirdPartyScriptsListView;
