import { Col, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';

import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import Form from 'components/Form';
import PropTypes from 'prop-types';
import api from 'helpers/api';
import classNames from 'helpers/classNames';
import styles from './DeleteClientModal.scss';
import useApiNamespace from 'hooks/useApiNamespace';
import useExecuteRequest from 'hooks/useExecuteRequest';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const DeleteClientModal = ({ isOpen, client, onCancel, onSuccess }) => {
    const { t } = useTranslation();
    const VALIDATE_TEXT = t('common:buttons.delete');
    const notify = useNotifications();
    const [isConfirmFormInValid, setIsConfirmFormInValid] = useState(false);
    const [confirmText, setConfirmText] = useState(false);
    const [del, delRes, delErr, delMeta] = useExecuteRequest();
    const apiNamespace = useApiNamespace();

    const handleFormSubmit = () => {
        if (!client) {
            return;
        }
        if (confirmText !== VALIDATE_TEXT) {
            setIsConfirmFormInValid(true);
            return;
        }

        del(api.del(`${apiNamespace}/clients/${client.uuid}`));
    };

    useEffect(() => {
        if (delMeta.isSuccess) {
            notify.success(t('common:clients.clients_list_view.deleted'));
            onSuccess();
        }
        if (delMeta.isError) {
            notify.error(delErr.message);
        }
    }, [delMeta.isError, delMeta.isSuccess]);

    if (!client || !isOpen) {
        return null;
    }

    return (
        <ConfirmModal
            isOpen
            title={`${t('common:buttons.delete')} ${client.name || ''}`}
            isConfirming={delMeta.isLoading}
            onConfirm={handleFormSubmit}
            onCancel={onCancel}
        >
            <h6
                className={cx('notice')}
                dangerouslySetInnerHTML={{
                    __html: t(
                        'common:clients.clients_list_view.are_you_sure_delete'
                    ),
                }}
            />
            <Form initialValues={{ confirmation: '' }}>
                <Row className={cx('align-items-end')}>
                    <Col lg={12}>
                        <Form.Field
                            className={cx('mb-lg-0')}
                            errors={
                                isConfirmFormInValid
                                    ? [
                                          t(
                                              'common:clients.clients_list_view.delete_validation_error',
                                              {
                                                  validate: VALIDATE_TEXT,
                                              }
                                          ),
                                      ]
                                    : null
                            }
                            name="confirmation"
                            type="text"
                            onChange={(name, val) => {
                                setIsConfirmFormInValid(false);
                                setConfirmText(val);
                            }}
                            label={t(
                                'common:clients.clients_list_view.delete_notice',
                                { validate: VALIDATE_TEXT }
                            )}
                        />
                    </Col>
                </Row>
            </Form>
        </ConfirmModal>
    );
};

DeleteClientModal.propTypes = {
    client: PropTypes.shape({
        uuid: PropTypes.string,
        name: PropTypes.string,
    }),
    onSuccess: PropTypes.func,
    onCancel: PropTypes.func,
    isOpen: PropTypes.bool,
};

export default DeleteClientModal;
