import React, { useEffect, useState } from 'react';

import Icon from 'components/Icon';
import PropTypes from 'prop-types';
import SecondarySideBarLink from 'core/SecondarySideBarLink';
import classNames from 'helpers/classNames';
import styles from './SecondarySideBarMenu.scss';
import { useLocation } from 'react-router';
import { useMedia } from 'react-use';

const cx = classNames(styles);

const SecondarySideBarMenu = ({ links = [], mobileLabel }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const location = useLocation();
    const isMobile = useMedia('(max-width: 767px)');

    useEffect(() => {
        setIsDropdownOpen(false);
    }, [location.pathname, isMobile]);
    return (
        <div className={cx('menu')}>
            <div
                className={cx(
                    'menu-mobile-header',
                    'd-flex',
                    'd-md-none',
                    'align-items-center',
                    'justify-content-between',
                    'text-white',
                    'font-weight-bold'
                )}
                onClick={() => setIsDropdownOpen((open) => !open)}
            >
                <span className={cx('nowrap')}>{mobileLabel}</span>
                <Icon
                    className={cx('icon')}
                    icon={`md/ic_arrow_drop_${isDropdownOpen ? 'up' : 'down'}`}
                />
            </div>
            <div
                className={cx({
                    'menu-mobile-body': isMobile,
                    open: isDropdownOpen,
                })}
            >
                {links.map((link, index) => (
                    <SecondarySideBarLink
                        key={index}
                        to={link.url}
                        icon={link.icon}
                        disabled={link.disabled}
                    >
                        {link.label}
                    </SecondarySideBarLink>
                ))}
            </div>
        </div>
    );
};

SecondarySideBarMenu.propTypes = {
    links: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string,
            icon: PropTypes.string,
            label: PropTypes.node,
        })
    ),
    mobileLabel: PropTypes.node,
};

SecondarySideBarMenu.defaultProps = {};

export default SecondarySideBarMenu;
