import _get from 'lodash/get';
import { countries } from 'constants/geoData';
import styles from './HomeView.scss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const usePlansPerCountryChart = (apiResponse = {}) => {
    const { t } = useTranslation();
    const chartData = useMemo(() => {
        const series = [
            {
                name: 'Plus (monthly)',
                data_key: 'plus-monthly',
                color: styles['plus-monthly-plan'],
                data: [],
            },
            {
                name: 'Plus (yearly)',
                data_key: 'plus-yearly',
                color: styles['plus-yearly-plan'],
                data: [],
            },
            {
                name: 'Basic (monthly)',
                data_key: 'basic-monthly',
                color: styles['basic-monthly-plan'],
                data: [],
            },
            {
                name: 'Basic (yearly)',
                data_key: 'basic-yearly',
                color: styles['basic-yearly-plan'],
                data: [],
            },
            {
                name: 'Free',
                data_key: 'free-monthly',
                color: styles['free-monthly-plan'],
                data: [],
            },
            {
                name: t('common:site_list_item.site_not_configured'),
                data_key: 'no-plan',
                color: styles['no-plan'],
                data: [],
            },
        ];

        const data = {
            type: 'bar',
            height: 355,
            series: [],
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    stackType: '100%',
                    fontFamily: 'Noto Sans, sans-serif',
                    toolbar: {
                        show: false,
                    },
                },
                xaxis: {
                    categories: [],
                    labels: {
                        formatter: (val) => `${val}%`,
                    },
                },
                dataLabels: {
                    formatter: function (percent, opt) {
                        const { dataPointIndex, seriesIndex, w } = opt;
                        const serie = w.config.series[seriesIndex];
                        const count = serie.data[dataPointIndex];
                        const percentLabel =
                            percent % 1 === 0 ? percent : percent.toFixed(2);
                        return `${count} (${percentLabel}%)`;
                    },
                },
                tooltip: {
                    x: false,
                },
                colors: series.map((i) => i.color),
                plotOptions: {
                    bar: {
                        horizontal: true,
                    },
                },
                stroke: {
                    width: 1,
                    colors: ['#fff'],
                },
                fill: {
                    opacity: 1,
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'center',
                    offsetX: 40,
                },
            },
        };

        if (!apiResponse) {
            return data;
        }

        const res = apiResponse.plans_per_country || {};
        const countryCodes = Object.keys(res).sort((a, b) => (a > b ? 1 : -1));

        data.height = 100 + countryCodes.length * 44;
        data.options.xaxis.categories = countryCodes.map((c) => {
            const fallback = 'not provided';
            if (!c) {
                return fallback;
            }
            const country = countries.find((i) => i.value === c);
            return country ? country.label : fallback;
        });
        data.series = series.map((plan) => {
            return {
                ...plan,
                data: countryCodes.map((country) => {
                    return _get(res, `${country}.${plan.data_key}.count`, 0);
                }),
            };
        });

        return data;
    }, [apiResponse]);

    return chartData;
};

export default usePlansPerCountryChart;
