import AuthViewLayout from 'views/auth/components/AuthViewLayout';
import Form from 'components/Form';
import React from 'react';
import classNames from 'helpers/classNames';
import styles from './ResetPasswordView.scss';
import { url } from 'core/Router';
import useFlashData from 'hooks/useFlashData';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const ResetPasswordView = () => {
    const { t } = useTranslation();
    const flash = useFlashData();
    const { token } = useParams();
    return (
        <AuthViewLayout
            title={t('guest:reset_password_view.title')}
            footer={
                <h1 className={cx('heading')}>
                    {t('guest:reset_password_view.remember_password')}{' '}
                    <a href={url('auth.login')}>
                        {t('guest:reset_password_view.log_in')}
                    </a>
                </h1>
            }
        >
            <Form
                uncontrolled
                initialValues={flash.input()}
                error={{ validation: flash.errors() }}
                method="POST"
                action={url('auth.resetPassword')}
            >
                <input type="hidden" name="token" value={token} />
                <Form.Field
                    className={cx('mb-4')}
                    placeholder={t('guest:reset_password_view.email')}
                    type="email"
                    name="email"
                    required
                />
                <Form.Field
                    className={cx('mb-4')}
                    placeholder={t('guest:reset_password_view.password')}
                    type="password"
                    name="password"
                    required
                />
                <Form.Field
                    className={cx('mb-4')}
                    placeholder={t(
                        'guest:reset_password_view.password_confirmation'
                    )}
                    type="password"
                    name="password_confirmation"
                    required
                />
                <Form.Submit block className={cx('font-weight-bold')}>
                    {t('guest:reset_password_view.reset_password')}
                </Form.Submit>
            </Form>
        </AuthViewLayout>
    );
};

export default ResetPasswordView;
