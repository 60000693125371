import BasePolicy from './BasePolicy';
import User from 'helpers/user';
import usePolicy from 'hooks/usePolicy';

export const VIEW_ALL_PERMISSION = 'USERS.VIEW_ALL';
export const VIEW_OWN_PERMISSION = 'USERS.VIEW_OWN';
export const CREATE_PERMISSION = 'USERS.CREATE';
export const UPDATE_ALL_PERMISSION = 'USERS.UPDATE_ALL';
export const UPDATE_OWN_PERMISSION = 'USERS.UPDATE_OWN';
export const UPDATE_SELF_PERMISSION = 'USERS.UPDATE_SELF';
export const INVITE_RESELLER_PERMISSION = 'USERS.INVITE_RESELLER';
export const CHANGE_CLIENT_ALL_PERMISSION = 'USERS.CHANGE_CLIENT_ALL';
export const CHANGE_CLIENT_OWN_PERMISSION = 'USERS.CHANGE_CLIENT_OWN';

export default class UserPolicy extends BasePolicy {
    static viewAny(user) {
        return user.canAny([VIEW_ALL_PERMISSION, VIEW_OWN_PERMISSION]);
    }

    static view(user, model) {
        if (user.can(VIEW_ALL_PERMISSION)) {
            return true;
        }

        if (user.can(VIEW_OWN_PERMISSION)) {
            return UserPolicy.userOwnsModel(user, model);
        }
    }

    static create(user) {
        return user.can(CREATE_PERMISSION);
    }

    static update(user, model) {
        // user with specific permission can manage all models
        if (user.can(UPDATE_ALL_PERMISSION)) {
            return true;
        }

        // user can update models belonging to their own client
        if (
            user.can(UPDATE_OWN_PERMISSION) &&
            UserPolicy.userOwnsModel(user, model)
        ) {
            return true;
        }
        // user who can update themselves
        if (user.can(UPDATE_SELF_PERMISSION) && user.uuid === model.uuid) {
            return true;
        }
    }

    static delete(user, model) {
        return UserPolicy.update(user, model);
    }

    static inviteReseller(user) {
        return user.can(INVITE_RESELLER_PERMISSION);
    }

    static changeClient(user, model) {
        if (user.can(CHANGE_CLIENT_ALL_PERMISSION)) {
            return true;
        }
        if (user.can(CHANGE_CLIENT_OWN_PERMISSION)) {
            return UserPolicy.userOwnsModel(user, model);
        }
    }
}

/**
 * Check if user is allowed to perform an action
 * @param {string} permission
 * @param {User} model
 * @returns {boolean}
 */
export const useUserPolicy = (permission, model) => {
    return usePolicy(UserPolicy, permission, model);
};
