import {
    Pagination as BsPagination,
    PaginationItem,
    PaginationLink,
} from 'reactstrap';
import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import _range from 'lodash/range';
import classNames from 'helpers/classNames';
import styles from './Pagination.scss';

const cx = classNames(styles);

const Pagination = ({
    currentPage = 1,
    lastPage = 10,
    onChange = _noop,
    showIfOnePage = false,
    pagesAround = 3,
    className = '',
}) => {
    const prevPages = useMemo(() => {
        let pages = _range(currentPage - pagesAround, currentPage, 1);
        pages = pages.filter((i) => i > 0); // remove negative results and 0
        return pages;
    }, [currentPage, lastPage]);

    const nextPages = useMemo(() => {
        let pages = _range(currentPage + 1, currentPage + pagesAround + 1, 1);
        pages = pages.filter((i) => i <= lastPage); // remove negative results and 0
        return pages;
    }, [currentPage, lastPage]);

    if (lastPage <= 1 && !showIfOnePage) {
        return null;
    }

    return (
        <BsPagination listClassName={cx(className, 'justify-content-center')}>
            <PaginationItem disabled={currentPage === 1}>
                <PaginationLink
                    previous
                    onClick={() => onChange(currentPage - 1)}
                />
            </PaginationItem>
            {currentPage - pagesAround > 2 && (
                <>
                    <PaginationItem>
                        <PaginationLink onClick={() => onChange(1)}>
                            1
                        </PaginationLink>
                    </PaginationItem>
                    <PaginationItem disabled>
                        <PaginationLink>...</PaginationLink>
                    </PaginationItem>
                </>
            )}
            {prevPages.map((page) => (
                <PaginationItem key={page}>
                    <PaginationLink onClick={() => onChange(page)}>
                        {page}
                    </PaginationLink>
                </PaginationItem>
            ))}
            <PaginationItem active>
                <PaginationLink>{currentPage}</PaginationLink>
            </PaginationItem>
            {nextPages.map((page) => (
                <PaginationItem key={page}>
                    <PaginationLink onClick={() => onChange(page)}>
                        {page}
                    </PaginationLink>
                </PaginationItem>
            ))}
            {lastPage - currentPage - 1 > pagesAround && (
                <>
                    <PaginationItem disabled>
                        <PaginationLink>...</PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                        <PaginationLink onClick={() => onChange(lastPage)}>
                            {lastPage}
                        </PaginationLink>
                    </PaginationItem>
                </>
            )}
            <PaginationItem disabled={currentPage === lastPage}>
                <PaginationLink
                    next
                    onClick={() => onChange(currentPage + 1)}
                />
            </PaginationItem>
        </BsPagination>
    );
};

Pagination.propTypes = {
    className: PropTypes.string,
    currentPage: PropTypes.number,
    lastPage: PropTypes.number,
    pagesAround: PropTypes.number,
    onChange: PropTypes.func,
    showIfOnePage: PropTypes.bool,
};

export default Pagination;
