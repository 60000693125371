import React, { useMemo } from 'react';

import DropdownInput from 'components/Form/inputs/DropdownInput';
import LangFlag from 'components/LangFlag';
import PropTypes from 'prop-types';
import classNames from 'helpers/classNames';
import useAppState from 'hooks/useAppState';

const cx = classNames();

const LanguageDropdown = ({ adminOnly, ...props }) => {
    const { availableLanguages, adminLanguages } = useAppState();
    const options = useMemo(() => {
        let langs = [...availableLanguages];
        if (adminOnly) {
            langs = langs.filter((l) => adminLanguages.includes(l.value));
        }

        return langs;
    }, [availableLanguages, adminLanguages, adminOnly]);
    return (
        <DropdownInput
            {...props}
            options={options.map((option) => ({
                ...option,
                label: (
                    <>
                        <LangFlag className={cx('mr-2')} lang={option.value} />
                        {option.label}
                    </>
                ),
            }))}
        />
    );
};

LanguageDropdown.propTypes = {
    adminOnly: PropTypes.bool,
};

LanguageDropdown.defaultProps = {
    adminOnly: false,
};

export default LanguageDropdown;
