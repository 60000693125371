import { useCallback, useEffect, useState } from 'react';

import API from 'helpers/api';
import Site from 'models/Site';
import useApiNamespace from 'hooks/useApiNamespace';
import useExecuteRequest from './useExecuteRequest';

const CACHE = {};

const useSite = (siteUuid) => {
    const apiNS = useApiNamespace();
    const [site, setSite] = useState(null);
    const [isUsingCache, setIsUsingCache] = useState(false);
    const [request, res, error, fetchingMeta] = useExecuteRequest();

    const fetchSite = useCallback(() => {
        request(API.get(`/${apiNS}/sites/${siteUuid}`));
        setIsUsingCache(false);
    }, [siteUuid, apiNS]);

    useEffect(() => {
        const cached = CACHE[siteUuid];
        if (cached) {
            setIsUsingCache(true);
            setSite(cached);
            return;
        }

        fetchSite();
    }, [siteUuid, apiNS]);

    useEffect(() => {
        if (!fetchingMeta.isSuccess) {
            if (site !== null) {
                setSite(null);
            }

            return;
        }

        if (typeof res !== 'object') {
            return;
        }

        const newSite = new Site(res);
        CACHE[siteUuid] = newSite;
        setSite(newSite);
    }, [fetchingMeta.isSuccess]);

    return [
        site,
        {
            isSuccess: isUsingCache
                ? true
                : fetchingMeta.isSuccess && site instanceof Site,
            isLoading: isUsingCache
                ? false
                : fetchingMeta.isLoading || (fetchingMeta.isSuccess && !site),
            isError: isUsingCache ? false : fetchingMeta.isError,
        },
        fetchSite,
    ];
};

export default useSite;
