import { Col, Row } from 'reactstrap';
import React, { useEffect } from 'react';

import API from 'helpers/api';
import EditThirdPartyScriptForm from '../components/EditThirdPartyScriptForm';
import Notification from 'components/Notification';
import Spinner from 'components/Spinner';
import classNames from 'helpers/classNames';
import { convertImageBase64ToBlob } from 'helpers/images';
import styles from './CreateThirdPartyScriptView.scss';
import { url } from 'core/Router';
import useExecuteRequest from 'hooks/useExecuteRequest';
import { useHistory } from 'react-router-dom';
import useNotifications from 'hooks/useNotifications';
import useRequest from 'hooks/useRequest';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const CreateThirdPartyScriptView = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const notify = useNotifications();
    const [script, fetchErr, fetchMeta] = useRequest(
        API.get('/admin/third-party-scripts/create')
    );
    const [save, saveRes, saveErr, saveMeta] = useExecuteRequest();

    const handleSubmit = (input) => {
        const url = '/admin/third-party-scripts';
        const saveRequest = API.request('POST', url, {
            ...input,
            logo: convertImageBase64ToBlob(input.logo || '') || null,
        });
        save(saveRequest);
    };

    useEffect(() => {
        if (saveRes && saveRes.uuid) {
            notify.success('Script created.');
            history.push(
                url('admin.thirdPartyScripts.edit', {
                    script: saveRes.uuid,
                })
            );
        }
    }, [saveRes]);

    if (fetchMeta.isLoading || !script) {
        return <Spinner />;
    }

    return (
        <>
            {saveMeta.isError && (
                <Notification type="error">{saveErr.message}</Notification>
            )}
            <h1>
                {t(
                    'admin:third-party-scripts.create_third_party_script_view.title'
                )}
            </h1>
            <Row>
                <Col xs="12" sm="8" md="4">
                    <EditThirdPartyScriptForm
                        script={script}
                        saveError={saveErr}
                        isSaving={saveMeta.isLoading}
                        onSubmit={handleSubmit}
                    />
                </Col>
            </Row>
        </>
    );
};

CreateThirdPartyScriptView.propTypes = {};

CreateThirdPartyScriptView.defaultProps = {};

export default CreateThirdPartyScriptView;
