import React, { useCallback } from 'react';

import Dropdown from 'components/Dropdown';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import classNames from 'helpers/classNames';
import styles from './SingleDropdownInput.scss';

const cx = classNames(styles);

const SingleDropdownInput = ({
    options,
    placeholder,
    invalid,
    disabled,
    allowClear,
    onChange,
    value,
    ...props
}) => {
    return (
        <Dropdown
            {...props}
            className={cx('dropdown', 'form-control', {
                'is-invalid': invalid,
                disabled,
            })}
            invalid={invalid}
            multiple={false}
            disabled={disabled}
            toggleClassName={cx('custom-select')}
            label={placeholder}
            onChoose={(newValue) => {
                if (
                    allowClear &&
                    !!value &&
                    !!newValue &&
                    value.value === newValue.value
                ) {
                    onChange(null);
                    return;
                }
                onChange(Array.isArray(newValue) ? newValue[0] : null);
            }}
            options={options}
            value={value}
            renderLabel={(option) => (
                <span className={cx('value')}>{option.label}</span>
            )}
        />
    );
};

SingleDropdownInput.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            label: PropTypes.node,
        })
    ),
    placeholder: PropTypes.node,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    invalid: PropTypes.bool,
    value: PropTypes.any,
    allowClear: PropTypes.bool,
};

SingleDropdownInput.defaultProps = {
    options: [],
    placeholder: '',
    onChange: _noop,
    disabled: false,
    invalid: false,
    allowClear: false,
};

export default SingleDropdownInput;
