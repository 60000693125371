import { Col, Row } from 'reactstrap';
import React, { useCallback, useEffect } from 'react';

import API from 'helpers/api';
import Form from 'components/Form';
import Notification from 'components/Notification';
import Spinner from 'components/Spinner';
import classNames from 'helpers/classNames';
import styles from './EditUserView.scss';
import useExecuteRequest from 'hooks/useExecuteRequest';
import useNotifications from 'hooks/useNotifications';
import { useParams } from 'react-router-dom';
import useRequest from 'hooks/useRequest';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const EditUserView = () => {
    const { t } = useTranslation();
    const notify = useNotifications();
    const { user: userUuid } = useParams();

    const [user, userFetchErr, fetchMeta, reload] = useRequest(
        API.get(`/admin/users/${userUuid}`)
    );
    const [request, res, err, saveStatus] = useExecuteRequest();

    const handleSubmit = useCallback(
        (form) =>
            request(
                API.put(`/admin/users/${userUuid}`, {
                    ...form,
                    preferred_language: Array.isArray(form.preferred_language)
                        ? form.preferred_language[0]
                        : form.preferred_language,
                    client_uuid: Array.isArray(form.client_uuid)
                        ? form.client_uuid[0]
                        : form.client_uuid,
                })
            ),
        [request]
    );

    useEffect(() => {
        if (fetchMeta.isLoading) {
            return;
        }

        if (fetchMeta.isSuccess) {
            user.password = '';
            user.password_confirmation = '';
        }
    }, [fetchMeta]);

    useEffect(() => {
        if (saveStatus.isSuccess) {
            notify.success(t('admin:users.edit_user_view.user_updated'));
            reload();
        }
    }, [saveStatus.isSuccess]);

    // don't render anything except spinner if loading initial data
    // this avoids inputs switching from uncontrolled to controlled
    if (!user || fetchMeta.isLoading || saveStatus.isLoading) {
        return (
            <>
                <h1>{t('admin:users.edit_user_view.title')}</h1>
                <Spinner />
            </>
        );
    }

    return (
        <>
            <h1>{t('admin:users.edit_user_view.title')}</h1>
            {saveStatus.isError && (
                <Notification type="error">
                    <p>{t('admin:users.edit_user_view.failed_to_update')}</p>
                    <p>{err.message}</p>
                    {Object.values(err.validation).map((el, index) => (
                        <p key={index}>{el}</p>
                    ))}
                </Notification>
            )}
            <Form
                initialValues={{
                    ...user,
                    password: '',
                    password_confirmation: '',
                    is_verified: !!user.email_verified_at,
                }}
                error={err}
                className={cx({
                    'd-none': saveStatus.isLoading || fetchMeta.isLoading,
                })}
                onSubmit={handleSubmit}
            >
                <Row>
                    <Col xs="12" lg="6">
                        <Row>
                            <Col xs="12" md="6">
                                <Form.Field
                                    label={t(
                                        'admin:field_labels.user.full_name'
                                    )}
                                    name="full_name"
                                    required
                                />
                            </Col>
                            <Col xs="12" md="6">
                                <Form.Field
                                    label={t(
                                        'admin:field_labels.user.display_name'
                                    )}
                                    name="display_name"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" md="6">
                                <Form.Field
                                    type="email"
                                    label={t('admin:field_labels.user.email')}
                                    name="email"
                                    required
                                />
                            </Col>
                            <Col xs="12" md="6">
                                <Form.Field
                                    label={t(
                                        'admin:field_labels.user.client_uuid'
                                    )}
                                    type="SelectClient"
                                    name="client_uuid"
                                    required
                                    placeholder={t('common:common.select')}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" md="6">
                                <Form.Field
                                    type="password"
                                    label={t(
                                        'admin:field_labels.user.password'
                                    )}
                                    name="password"
                                />
                            </Col>
                            <Col xs="12" md="6">
                                <Form.Field
                                    type="password"
                                    label={t(
                                        'admin:field_labels.user.password_confirmation'
                                    )}
                                    name="password_confirmation"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" lg="6">
                                <Form.Field
                                    label={t(
                                        'admin:field_labels.user.preferred_language'
                                    )}
                                    name="preferred_language"
                                    required
                                    adminOnly
                                    type="LanguageDropdown"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" md="6">
                                <Form.Field
                                    type="Toggle"
                                    inlineLabel={t(
                                        'admin:field_labels.user.is_admin'
                                    )}
                                    name="is_admin"
                                />
                            </Col>
                            <Col xs="12" md="6">
                                <Form.Field
                                    type="Toggle"
                                    inlineLabel={t(
                                        'admin:field_labels.user.is_verified'
                                    )}
                                    name="is_verified"
                                />
                            </Col>
                        </Row>
                        <Form.Submit icon="md/ic_save">
                            {t('common:buttons.save')}
                        </Form.Submit>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default EditUserView;
