import React, { useCallback, useEffect, useRef } from 'react';

import ChoiceItem from './ChoiceItem';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';
import _noop from 'lodash/noop';
import classNames from 'helpers/classNames';
import styles from './ChoiceGroup.scss';

const cx = classNames(styles);

const ChoiceGroup = ({
    disabled,
    required,
    name,
    invalid,
    placeholder,
    value,
    onChange,
    options,
    multiple,
    doubleColumn,
}) => {
    const inputValue = Array.isArray(value) ? value : [value];
    const input = useRef(null);
    const handleChange = useCallback(() => {
        if (!input.current || disabled || !options.length) {
            return;
        }

        const inputs = input.current.querySelectorAll(`input[name=${name}]`);
        const selectedValues = [...inputs]
            .filter((i) => !!i.checked)
            .map((i) => i.value);
        // value hasn't changed
        if (_isEqual(selectedValues, inputValue)) {
            return;
        }
        onChange(selectedValues);
    });

    useEffect(handleChange, [multiple]); // trigger change when multiple parameter changes
    return (
        <div
            ref={input}
            className={cx('form-control', 'input', {
                'is-invalid': invalid,
            })}
        >
            <input
                type="hidden"
                required={required}
                name={`hidden-input-${name}`}
                value={inputValue.join(',')}
            />
            {!options.length && placeholder}
            {options.map((option, index) => {
                const isChecked = inputValue.includes(option.value);
                return (
                    <div
                        key={index}
                        className={cx({ 'double-column': doubleColumn })}
                    >
                        <ChoiceItem
                            type={multiple ? 'checkbox' : 'radio'}
                            disabled={disabled || option.disabled}
                            invalid={invalid}
                            id={Math.random()}
                            label={option.label}
                            value={option.value || ''}
                            name={name}
                            checked={isChecked}
                            onChange={handleChange}
                        />
                    </div>
                );
            })}
        </div>
    );
};

ChoiceGroup.propTypes = {
    name: PropTypes.string,
    placeholder: PropTypes.node,
    invalid: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.any, PropTypes.array]),
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            label: PropTypes.node,
        })
    ),
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    multiple: PropTypes.bool,
    doubleColumn: PropTypes.bool,
};

ChoiceGroup.defaultProps = {
    name: '',
    placeholder: '',
    invalid: false,
    value: [],
    onChange: _noop,
    options: [],
    disabled: false,
    required: false,
    multiple: false,
    doubleColumn: false,
};

export default ChoiceGroup;
