import BasePolicy from 'policies/BasePolicy';
import ClientPolicy from 'policies/ClientPolicy';
import CustomScriptPolicy from 'policies/CustomScriptPolicy';
import PropTypes from 'prop-types';
import React from 'react';
import SiteGroupPolicy from 'policies/SiteGroupPolicy';
import SitePolicy from 'policies/SitePolicy';
import UserPolicy from 'policies/UserPolicy';
import classNames from 'helpers/classNames';
import styles from './Authorize.scss';
import usePolicy from 'hooks/usePolicy';

const cx = classNames(styles);

const POLICIES = [
    SitePolicy,
    ClientPolicy,
    CustomScriptPolicy,
    SiteGroupPolicy,
    UserPolicy,
];

const Authorize = ({
    policy = BasePolicy,
    action = '',
    model = {},
    children,
}) => {
    const can = usePolicy(policy, action, model);
    if (typeof children === 'function') {
        return children(can);
    }

    return can ? children : null;
};

Authorize.propTypes = {
    children: PropTypes.node,
    policy: PropTypes.oneOf(POLICIES),
    action: PropTypes.string,
    model: PropTypes.object,
};

Authorize.SitePolicy = SitePolicy;
Authorize.ClientPolicy = ClientPolicy;
Authorize.CustomScriptPolicy = CustomScriptPolicy;
Authorize.SiteGroupPolicy = SiteGroupPolicy;
Authorize.UserPolicy = UserPolicy;

export default Authorize;
