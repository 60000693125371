import { Col, Row } from 'reactstrap';

import Authorize from 'core/Authorize/Authorize';
import Button from 'components/Button';
import Link from 'components/Link';
import PropTypes from 'prop-types';
import React from 'react';
import ScriptLogo from 'components/ScriptLogo';
import _noop from 'lodash/noop';
import classNames from 'helpers/classNames';
import styles from './CustomScriptListItem.scss';
import { url } from 'core/Router';

const cx = classNames(styles);

const CustomScriptListItem = ({ item, onDelete, isDeleting }) => {
    return (
        <Row className={cx('item', 'align-items-center')}>
            <Col className={cx('logoContainer')}>
                <ScriptLogo src={item.logo} />
            </Col>
            <Col cx="auto">
                <strong className={cx('text-primary')}>{item.name}</strong>
                <br />
                <small className={cx('text-muted', 'text-uppercase')}>
                    Cookie categories:{' '}
                    <strong>
                        {item.allows_custom_categories
                            ? 'custom'
                            : item.cookie_categories.join(', ')}
                    </strong>
                </small>
            </Col>
            <Col xs />
            <Authorize
                policy={Authorize.CustomScriptPolicy}
                action="view"
                model={item}
            >
                <Col xs="auto">
                    <Link
                        button
                        icon="md/ic_build"
                        to={url('dash.customScripts.edit', {
                            script: item.uuid,
                        })}
                    >
                        Edit
                    </Link>
                </Col>
            </Authorize>
            <Authorize
                policy={Authorize.CustomScriptPolicy}
                action="delete"
                model={item}
            >
                <Col xs="auto">
                    <Button
                        color="danger"
                        icon="md/ic_delete_forever"
                        onClick={onDelete}
                        loading={isDeleting}
                    >
                        Delete
                    </Button>
                </Col>
            </Authorize>
        </Row>
    );
};

CustomScriptListItem.propTypes = {
    index: PropTypes.number,
    item: PropTypes.shape({
        logo: PropTypes.string,
        name: PropTypes.string,
        uuid: PropTypes.string,
        cookie_categories: PropTypes.arrayOf(PropTypes.string),
        allows_custom_categories: PropTypes.bool,
    }),
    isDeleting: PropTypes.bool,
    onDelete: PropTypes.func,
};

CustomScriptListItem.defaultProps = {
    isDeleting: false,
    onDelete: _noop,
};

export default CustomScriptListItem;
