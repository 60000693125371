import 'react-datepicker/dist/react-datepicker.css';

import React, { useRef } from 'react';

import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import classNames from 'helpers/classNames';
import styles from './Date.scss';

const cx = classNames(styles);

let instance = 0;
const Date = ({ value, ...props }) => {
    const id = useRef(instance++);
    return (
        <div className={cx('picker')}>
            <DatePicker
                selected={value}
                className={cx('form-control')}
                onChange={props.onChange}
                dateFormat="dd/MM/yyyy"
            />
        </div>
    );
};

Date.propTypes = {
    className: PropTypes.string,
    value: PropTypes.object, //Date object
    onChange: PropTypes.func,
};

Date.defaultProps = {};

export default Date;
