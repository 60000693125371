import { Col, Row } from 'reactstrap';
import React, { useRef } from 'react';

import Button from 'components/Button';
import Form from 'components/Form';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';
import _noop from 'lodash/noop';
import classNames from 'helpers/classNames';
import styles from './Repeater.scss';

const cx = classNames(styles);

const Repeater = ({
    name = '',
    placeholder = '',
    value = [],
    onChange = _noop,
    disabled = false,
    required = false,
    itemType = 'text',
    addRowLabel = '',
    maxRows = undefined,
    getNewRow = () => null,
    rowProps = {},
}) => {
    const rowsIds = useRef([]);
    const inputValue = Array.isArray(value) ? value : [value];
    const addRow = () => {
        const newRow = getNewRow();
        onChange([...inputValue, newRow]);
        rowsIds.current.push(Math.random());
    };

    const removeRow = (index) => {
        rowsIds.current = rowsIds.current.filter((id, i) => i !== index);
        onChange(inputValue.filter((item, i) => i !== index));
    };

    return (
        <div>
            {!inputValue.length && placeholder}
            {inputValue.map((itemValue, index) => {
                if (!rowsIds.current[index]) {
                    rowsIds.current[index] = Math.random();
                }
                return (
                    <Row form key={rowsIds.current[index]}>
                        <Col>
                            <Form.Field
                                {...rowProps}
                                name={`${name}[${index}]`}
                                type={itemType}
                                required={required}
                                disabled={disabled}
                            />
                        </Col>
                        <Col xs="auto">
                            <Button
                                disabled={disabled}
                                onClick={() => removeRow(index)}
                                color="danger"
                                icon="md/ic_remove_circle"
                            />
                        </Col>
                    </Row>
                );
            })}
            {(typeof maxRows === 'undefined' ||
                inputValue.length < maxRows) && (
                <Button
                    color="primary"
                    icon="md/ic_add_circle"
                    onClick={addRow}
                    disabled={disabled}
                    label={addRowLabel}
                />
            )}
        </div>
    );
};

Repeater.propTypes = {
    name: PropTypes.string,
    placeholder: PropTypes.node,
    invalid: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.any, PropTypes.array]),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    maxRows: PropTypes.number,
    addRowLabel: PropTypes.string,
    itemType: PropTypes.string,
    rowProps: PropTypes.object,
    getNewRow: PropTypes.func,
};

export default Repeater;
