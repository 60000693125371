import React, { useEffect, useState } from 'react';

import Button from 'components/Button/Button';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import MultilineText from 'components/MultilineText/MultilineText';
import PropTypes from 'prop-types';
import api from 'helpers/api';
import classNames from 'helpers/classNames';
import styles from './ResetGroupConsentsButton.scss';
import useExecuteRequest from 'hooks/useExecuteRequest';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const ResetGroupConsentsButton = ({ groupUuid = '', disabled = false }) => {
    const [request, res, err, submitState] = useExecuteRequest();
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const { t } = useTranslation();
    const notify = useNotifications();

    useEffect(() => {
        if (submitState.isLoading) {
            return;
        }

        if (submitState.isSuccess) {
            notify.success(t('common:group_consent_resetting.success'));
            setIsConfirmModalOpen(false);
        }
        if (submitState.isError) {
            notify.error(err.message);
        }
    }, [submitState.isLoading, submitState.isSuccess, submitState.isError]);

    const handleReset = () => {
        if (!groupUuid || disabled) {
            return;
        }
        if (submitState.isLoading) {
            return;
        }

        request(api.post(`/common/site-groups/${groupUuid}/reset-consents`));
    };
    return (
        <>
            <Button
                disabled={!groupUuid || disabled}
                color="danger"
                icon="md/ic_do_not_disturb_alt"
                onClick={() => setIsConfirmModalOpen(true)}
                label={t('common:group_consent_resetting.reset')}
            />
            <ConfirmModal
                isConfirming={submitState.isLoading}
                isOpen={!disabled && isConfirmModalOpen}
                onCancel={() => setIsConfirmModalOpen(false)}
                title={t('common:group_consent_resetting.reset')}
                onConfirm={handleReset}
            >
                <MultilineText
                    text={t('common:group_consent_resetting.confirm')}
                />
            </ConfirmModal>
        </>
    );
};

ResetGroupConsentsButton.propTypes = {
    disabled: PropTypes.bool,
    groupUuid: PropTypes.string,
};

export default ResetGroupConsentsButton;
