import styles from './HomeView.scss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const usePlansChart = (apiResponse = {}) => {
    const { t } = useTranslation();
    const plansChartData = useMemo(() => {
        const plans = apiResponse ? apiResponse.plans || [] : [];
        return {
            series: plans.map((plan) => plan.count || 0),
            options: {
                chart: {
                    width: 420,
                    fontFamily: 'Noto Sans, sans-serif',
                    toolbar: {
                        show: false,
                        offsetX: 0,
                        offsetY: 0,
                    },
                },
                colors: plans.map((plan) => {
                    const color =
                        styles[
                            `${plan.config_name}-${plan.billing_period}-plan`
                        ] || styles['no-plan'];

                    return color;
                }),
                labels: plans.map((plan) => {
                    const name =
                        plan.name ||
                        t('common:site_list_item.site_not_configured');
                    const billingPeriod = plan.name
                        ? t(
                              `components:choose_plan.billing_period.${plan.billing_period}`
                          )
                        : '';

                    if (plan.config_name === 'free') {
                        return plan.name;
                    }

                    if (!billingPeriod) {
                        return name;
                    }

                    return `${name} (${billingPeriod})`;
                }),
                legend: {
                    show: true,
                },
            },
        };
    }, [apiResponse]);

    return plansChartData;
};

export default usePlansChart;
