import React, { useEffect, useState } from 'react';

import CookieMetaSuggestionItem from '../CookieMetaSuggestionItem';
import EditCookieDataModal from '../EditCookieDataModal';
import Pagination from 'components/Pagination';
import Spinner from 'components/Spinner';
import { Table } from 'reactstrap';
import useListing from 'hooks/useListing';
import useTranslate from 'hooks/useTranslate';
import useUrlQuery from 'hooks/useUrlQuery';

const CookieMetaSuggestionsTab = () => {
    const [query] = useUrlQuery();
    const [suggestions, fetchMeta, err, reload] = useListing(
        '/admin/cookie-meta-suggestions',
        query
    );
    const [trans] = useTranslate(
        'admin:cookies.cookies_list_view.cookie_listing_columns'
    );
    const [currentCookie, setCurrentCookie] = useState(null);
    const [suggestionUuid, setSuggestionUuid] = useState(null);

    useEffect(() => {
        if (suggestionUuid) {
            const currentSuggestion = suggestions.data.find(
                (suggestion) => suggestion.uuid === suggestionUuid
            );
            const cookie = {
                id: currentSuggestion.cookie_id,
                name: currentSuggestion.cookie_name,
                category: currentSuggestion.category,
                goal: currentSuggestion.goal,
            };

            setCurrentCookie(cookie);
        }
    }, [suggestionUuid]);

    if (fetchMeta.isLoading) {
        return <Spinner />;
    }

    const resetCookieState = (shouldReloadData = false) => {
        setCurrentCookie(null);
        setSuggestionUuid(null);
        if (shouldReloadData) {
            reload();
        }
    };
    return (
        <>
            <Table>
                <thead>
                    <tr>
                        <th>{trans('client')}</th>
                        <th>{trans('site')}</th>
                        <th>{trans('cookie_name')}</th>
                        <th>{trans('category')}</th>
                        <th>{trans('goal')}</th>
                        <th>{trans('was_edited')}</th>
                        <th>{trans('last_edit_by')}</th>
                        <th>{trans('last_edit_time')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {suggestions.data.map((suggestion, index) => (
                        <CookieMetaSuggestionItem
                            key={index}
                            reload={reload}
                            onSuggestionModified={setSuggestionUuid}
                            suggestion={suggestion}
                        />
                    ))}
                </tbody>
            </Table>
            <Pagination
                currentPage={suggestions.current_page}
                lastPage={suggestions.last_page}
            />
            {!!currentCookie && (
                <EditCookieDataModal
                    cookie={currentCookie}
                    suggestionUuid={suggestionUuid}
                    onClose={() => resetCookieState()}
                    onSaved={() => resetCookieState(true)}
                    isOpen={true}
                />
            )}
        </>
    );
};

export default CookieMetaSuggestionsTab;
