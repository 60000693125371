import React, { useCallback, useEffect } from 'react';

import API from 'helpers/api';
import CookieDataFormFields from '../CookieDataFormFields';
import Form from 'components/Form';
import Modal from 'components/Modal';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _noop from 'lodash/noop';
import classNames from 'helpers/classNames';
import styles from './EditCookieDataModal.scss';
import useExecuteRequest from 'hooks/useExecuteRequest';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const EditCookieDataModal = ({
    cookie,
    suggestionUuid,
    onClose,
    onSubmit,
    onRequestEnd,
    isOpen,
}) => {
    const { t } = useTranslation();
    const [request, res, submitErr, submitState] = useExecuteRequest();
    const notify = useNotifications();
    const handleSubmit = useCallback(
        (form) => {
            if (!suggestionUuid) {
                const url = `/admin/cookies/${cookie.id}/update`;
                request(API.put(url, form));
            } else {
                const url = `/admin/cookie-meta-suggestions/${suggestionUuid}/modify`;
                request(API.put(url, form));
            }
            onClose();
            onSubmit();
        },
        [request]
    );

    useEffect(() => {
        if (submitState.isSuccess) {
            notify.success(
                `${cookie.display_name} ${t('common:common.saved')}`
            );
            onRequestEnd(true);
        }
    }, [submitState.isSuccess, notify]);

    useEffect(() => {
        if (submitErr && submitErr.message) {
            notify.error(t(submitErr.message));
            onRequestEnd(false);
        }
    }, [submitErr, notify]);

    return (
        <Modal
            size="lg"
            title={t(
                'admin:cookies.cookies_list_view.edit_cookie_data_modal.title'
            )}
            isOpen={isOpen}
            onClose={onClose}
        >
            <Form
                onSubmit={handleSubmit}
                initialValues={cookie}
                error={submitErr}
            >
                <Modal.Body>
                    <CookieDataFormFields />
                </Modal.Body>
                <Modal.Footer>
                    <Form.Submit
                        icon="md/ic_save"
                        label={t('common:buttons.save')}
                    />
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

EditCookieDataModal.propTypes = {
    cookie: PropTypes.shape({
        id: PropTypes.number,
        display_name: PropTypes.string,
    }),
    suggestionUuid: PropTypes.string,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    onRequestEnd: PropTypes.func,
    isOpen: PropTypes.bool,
};

EditCookieDataModal.defaultProps = {
    onSubmit: _noop,
};

export default EditCookieDataModal;
