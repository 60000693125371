import { Col, Row } from 'reactstrap';

import Button from 'components/Button';
import Link from 'components/Link';
import PropTypes from 'prop-types';
import React from 'react';
import ScriptLogo from 'components/ScriptLogo';
import _noop from 'lodash/noop';
import classNames from 'helpers/classNames';
import styles from './ThirdPartyScriptListItem.scss';
import { url } from 'core/Router';

const cx = classNames(styles);

const ThirdPartyScriptListItem = ({ item, onDelete, isDeleting }) => (
    <Row className={cx('item', 'align-items-center')}>
        <Col className={cx('logoContainer')}>
            <ScriptLogo src={item.logo} />
        </Col>
        <Col cx="auto">
            <strong className={cx('text-primary')}>{item.name}</strong>
            <br />
            <small className={cx('text-muted', 'text-uppercase')}>
                Category: <strong>{item.category.replace('_', ' ')}</strong>
            </small>{' '}
            <small className={cx('text-muted', 'text-uppercase')}>
                Cookie categories:{' '}
                <strong>
                    {item.allows_custom_categories
                        ? 'custom'
                        : item.cookie_categories.join(', ')}
                </strong>
            </small>
        </Col>
        <Col xs="auto" className={cx('d-none', 'd-lg-block', 'flex-grow-1')} />
        <Col md="auto" xs="12" className={cx('ml-md-auto', 'mt-4', 'mt-md-0')}>
            <Row className={cx('align-items-center')}>
                <Col xs="6" md="auto" className={cx('pr-2')}>
                    <Link
                        button
                        className={cx('w-100')}
                        icon="md/ic_build"
                        to={url('admin.thirdPartyScripts.edit', {
                            script: item.uuid,
                        })}
                    >
                        Edit
                    </Link>
                </Col>
                <Col xs="6" md="auto" className={cx('pl-2')}>
                    <Button
                        color="danger"
                        className={cx('w-100')}
                        icon="md/ic_delete_forever"
                        onClick={onDelete}
                        loading={isDeleting}
                    >
                        Delete
                    </Button>
                </Col>
            </Row>
        </Col>
    </Row>
);

ThirdPartyScriptListItem.propTypes = {
    index: PropTypes.number,
    item: PropTypes.shape({
        logo: PropTypes.string,
        name: PropTypes.string,
        uuid: PropTypes.string,
        category: PropTypes.string,
        cookie_categories: PropTypes.arrayOf(PropTypes.string),
        allows_custom_categories: PropTypes.bool,
    }),
    isDeleting: PropTypes.bool,
    onDelete: PropTypes.func,
};

ThirdPartyScriptListItem.defaultProps = {
    isDeleting: false,
    onDelete: _noop,
};

export default ThirdPartyScriptListItem;
