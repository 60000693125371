import React, { useCallback, useEffect } from 'react';

import API from 'helpers/api';
import CustomScriptListItem from './CustomScriptListItem';
import PropTypes from 'prop-types';
import useExecuteRequest from 'hooks/useExecuteRequest';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';

const CustomScriptListItemContainer = ({ item }) => {
    const [req, delRes, delErr, delMeta] = useExecuteRequest();
    const notify = useNotifications();
    const { t } = useTranslation();
    const handleDelete = useCallback(() => {
        if (
            !confirm(
                t(
                    'client:custom-scripts.custom_scripts_list_view.delete_confirmation',
                    { scriptCount: item.site_scripts.length }
                )
            )
        ) {
            return;
        }

        req(API.del(`/client/custom-scripts/${item.uuid}`));
    }, [item.uuid]);

    useEffect(() => {
        if (delMeta.isSuccess) {
            notify.success(
                <>
                    <strong>{item.name}</strong>{' '}
                    {t('client:custom-script.custom_scripts_list_view.deleted')}
                </>
            );
        }
        if (delMeta.isError) {
            notify.success(delErr.message);
        }
    }, [delMeta.isSuccess, delMeta.isError]);

    if (delMeta.isSuccess) {
        return null;
    }

    return (
        <CustomScriptListItem
            item={item}
            onDelete={handleDelete}
            isDeleting={delMeta.isLoading}
        />
    );
};

CustomScriptListItemContainer.propTypes = {
    item: PropTypes.shape({
        uuid: PropTypes.string,
        name: PropTypes.string,
        site_scripts: PropTypes.array,
    }),
};

export default CustomScriptListItemContainer;
