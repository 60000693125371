import React, { useEffect, useState } from 'react';

import API from 'helpers/api';
import Button from 'components/Button';
import FormattedDate from 'components/FormattedDate';
import Icon from 'components/Icon';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import classNames from 'helpers/classNames';
import dayjs from 'helpers/dayjs';
import styles from './CookieMetaSuggestionItem.scss';
import useExecuteRequest from 'hooks/useExecuteRequest';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const STATUS_SUCCESS = 2;
const STATUS_PENDING = 1;
const STATUS_FAILURE = -1;

const CookieMetaSuggestionItem = ({
    onSuggestionModified,
    suggestion,
    reload,
    ...props
}) => {
    const { t } = useTranslation();
    const [approveRequest, approveRes, approveErr, approveState] =
        useExecuteRequest();
    const [declineRequest, declineRes, declineErr, declineState] =
        useExecuteRequest();
    const notify = useNotifications();
    const [approveStatus, setApproveStatus] = useState(false);
    const [declineStatus, setDeclineStatus] = useState(false);

    const approveSuggestion = () => {
        approveRequest(
            API.put(`/admin/cookie-meta-suggestions/${suggestion.uuid}/approve`)
        );
        setApproveStatus(STATUS_PENDING);
    };

    const declineSuggestion = () => {
        declineRequest(
            API.del(`/admin/cookie-meta-suggestions/${suggestion.uuid}/decline`)
        );
        setDeclineStatus(STATUS_PENDING);
    };

    useEffect(() => {
        if (approveState.isSuccess) {
            notify.success(
                t(
                    'admin:cookies.cookies_list_view.cookie_meta_suggestion_item.approve_success'
                )
            );
            setApproveStatus(STATUS_SUCCESS);
        }

        if (declineState.isSuccess) {
            notify.success(
                t(
                    'admin:cookies.cookies_list_view.cookie_meta_suggestion_item.decline_success'
                )
            );
            setDeclineStatus(STATUS_SUCCESS);
        }

        if (approveState.isError) {
            notify.error(approveErr.message);
            setApproveStatus(STATUS_FAILURE);
        }

        if (declineState.isError) {
            notify.error(declineErr.message);
            setDeclineStatus(STATUS_FAILURE);
        }
    }, [
        approveState.isSuccess,
        approveState.isError,
        declineState.isSuccess,
        declineState.isError,
        notify,
    ]);

    return (
        <tr>
            <td>{_get(suggestion, 'client.name') || '----'}</td>
            <td>{_get(suggestion, 'site.name') || '----'}</td>
            <td>{suggestion.cookie_name}</td>
            <td>{suggestion.category}</td>
            <td>{suggestion.goal}</td>
            <td className={cx('text-center')}>
                {suggestion.was_edited_by_admin ? (
                    <Icon icon="md/ic_check_circle" />
                ) : (
                    <Icon icon="md/ic_cancel" />
                )}
            </td>
            <td>
                {suggestion.was_edited_by_admin
                    ? t(
                          'admin:cookies.cookies_list_view.cookie_meta_suggestion_item.admin'
                      )
                    : t(
                          'admin:cookies.cookies_list_view.cookie_meta_suggestion_item.client'
                      )}
            </td>
            <td>
                <FormattedDate from={suggestion.updated_at} format="L LT" />
            </td>
            <td className={cx('text-right')}>
                <Button
                    className={cx('mr-3', 'btn-select-cookie')}
                    icon="md/ic_done"
                    color="success"
                    onClick={approveSuggestion}
                    disabled={
                        approveStatus === STATUS_PENDING ||
                        approveStatus === STATUS_SUCCESS ||
                        declineStatus === STATUS_PENDING ||
                        declineStatus === STATUS_SUCCESS
                    }
                    loading={approveStatus === STATUS_PENDING}
                >
                    {t('common:buttons.approve')}
                </Button>
                <Button
                    className={cx('mr-3', 'btn-select-cookie')}
                    icon="md/ic_mode_edit"
                    color="warning"
                    onClick={() => {
                        onSuggestionModified(suggestion.uuid);
                    }}
                    disabled={
                        approveStatus === STATUS_PENDING ||
                        approveStatus === STATUS_SUCCESS ||
                        declineStatus === STATUS_PENDING ||
                        declineStatus === STATUS_SUCCESS
                    }
                >
                    {t('common:buttons.modify')}
                </Button>
                <Button
                    className={cx('btn-select-cookie')}
                    icon="md/ic_delete"
                    color="danger"
                    onClick={declineSuggestion}
                    disabled={
                        approveStatus === STATUS_PENDING ||
                        approveStatus === STATUS_SUCCESS ||
                        declineStatus === STATUS_PENDING ||
                        declineStatus === STATUS_SUCCESS
                    }
                    loading={declineStatus === STATUS_PENDING}
                >
                    {t('common:buttons.decline')}
                </Button>
            </td>
        </tr>
    );
};

CookieMetaSuggestionItem.propTypes = {
    suggestion: PropTypes.object,
    onSuggestionModified: PropTypes.func,
    reload: PropTypes.func,
};

CookieMetaSuggestionItem.defaultProps = {
    suggestion: null,
    reload: () => null,
};

export default CookieMetaSuggestionItem;
