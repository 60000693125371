import React, { useMemo } from 'react';

import Icon from 'components/Icon';
import NotFound from 'views/other/NotFound';
import SecondarySideBar from 'core/SecondarySideBar';
import SecondarySideBarMenu from 'core/SecondarySideBarMenu';
import classNames from 'helpers/classNames';
import { getTabs } from './tabs';
import styles from './Billing.scss';
import useAppState from 'hooks/useAppState';
import { useClientPolicy } from 'policies/ClientPolicy';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const Billing = () => {
    const { t } = useTranslation();
    const { user } = useAppState();
    const { tab: tabKey } = useParams();
    const canView = useClientPolicy('viewBilling', user.client);

    const [tabs, activeTab] = useMemo(() => {
        const tabs = getTabs(user, t);

        const activeTab = tabs.find((tab) => tab.key === tabKey) || tabs[0];
        return [tabs, activeTab];
    }, [user, t, tabKey]);

    if (!canView) {
        return <NotFound />;
    }

    return (
        <>
            <SecondarySideBar>
                <div
                    className={cx(
                        'mb-5',
                        'pr-3',
                        'pl-4',
                        'text-white',
                        'd-none',
                        'd-md-block'
                    )}
                >
                    <Icon icon="md/ic_person" className={cx('mr-2')} />
                    {user.display_name || user.full_name}
                </div>
                <SecondarySideBarMenu
                    links={tabs}
                    mobileLabel={user.display_name || user.full_name}
                />
            </SecondarySideBar>
            {!activeTab && <NotFound />}
            {!!activeTab && (
                <div className={cx('activeTab')}>
                    <h2>{activeTab.label}</h2>
                    <activeTab.component />
                </div>
            )}
        </>
    );
};

export default Billing;
