import { Col, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';

import AnimatedList from 'components/AnimatedList';
import Authorize from 'core/Authorize/Authorize';
import Form from 'components/Form';
import Link from 'components/Link';
import Pagination from 'components/Pagination';
import SearchField from 'components/SearchField';
import Spinner from 'components/Spinner';
import UserListItem from 'partials/users/UserListItem';
import _get from 'lodash/get';
import classNames from 'helpers/classNames';
import styles from './UsersListView.scss';
import { useClientPolicy } from 'policies/ClientPolicy';
import { useTranslation } from 'react-i18next';
import useUrl from 'hooks/useUrl';
import useUrlQuery from 'hooks/useUrlQuery';
import useUsers from 'hooks/useUsers';

const cx = classNames(styles);

const UsersListView = () => {
    const { t } = useTranslation();
    const url = useUrl();
    const [query, updateQuery] = useUrlQuery();
    const [users, fetchMeta, , reload] = useUsers({ ...query });
    const canListClients = useClientPolicy('viewAny');
    const [clients, setClients] = useState(
        canListClients && query.clients ? query.clients : []
    );

    useEffect(() => {
        updateQuery({
            ...query,
            clients,
            page: undefined, // when changing clients filter, go back to first page
        });
    }, [JSON.stringify(clients)]);

    return (
        <>
            <div>
                <h1>{t('common:users_list_view.title')}</h1>
            </div>
            <Row form className={cx('align-items-end', 'my-4')}>
                <Authorize policy={Authorize.UserPolicy} action="create">
                    <Col md="auto">
                        <Link
                            button
                            to={url('userNS.users.create')}
                            icon="md/ic_add"
                        >
                            {t('common:users_list_view.add_new_user')}
                        </Link>
                    </Col>
                </Authorize>

                <Form
                    className={cx('col-md-8')}
                    initialValues={{ search: '', clients }}
                    onSubmit={() => null}
                >
                    <Form.Row>
                        <Col lg="4" className={cx('mt-lg-0', 'mt-4')}>
                            <SearchField label={t('common:common.search')} />
                        </Col>
                        {canListClients && (
                            <Col
                                xs="12"
                                md="4"
                                lg="3"
                                className={cx('mt-3', 'mt-md-0')}
                            >
                                <Form.Field
                                    label={t('common:common.filter_by_clients')}
                                    className={cx('mb-0')}
                                    name="clients"
                                    type="SelectClient"
                                    placeholder="---"
                                    value={clients}
                                    onChange={(f, v) => {
                                        setClients(v ? [v] : []);
                                    }}
                                />
                            </Col>
                        )}
                    </Form.Row>
                </Form>
            </Row>
            {fetchMeta.isLoading && <Spinner />}
            {!fetchMeta.isLoading && fetchMeta.isSuccess && (
                <>
                    <AnimatedList
                        list={users.data}
                        itemComponent={UserListItem}
                        itemKey="uuid"
                        reloadList={reload}
                    />
                    <Pagination
                        className={cx('mt-3')}
                        currentPage={users.current_page}
                        lastPage={users.last_page}
                    />
                </>
            )}
        </>
    );
};

export default UsersListView;
