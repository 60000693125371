import React, { useCallback, useEffect, useState } from 'react';

import API from 'helpers/api';
import { Alert } from 'reactstrap';
import AuthViewLayout from 'views/auth/components/AuthViewLayout';
import Form from 'components/Form';
import VerifyCodeModal from '../components/VerifyCodeModal';
import classNames from 'helpers/classNames';
import styles from './LoginView.scss';
import { url } from 'core/Router';
import useExecuteRequest from 'hooks/useExecuteRequest';
import useFlashData from 'hooks/useFlashData';
import useInstanceSettings from 'hooks/useInstanceSettings';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';
import useUrlQuery from 'hooks/useUrlQuery';

const cx = classNames(styles);

const INITIAL_VALUES = {
    email: '',
    password: '',
};

const LoginView = () => {
    const { t } = useTranslation();
    const notify = useNotifications();
    const flash = useFlashData();
    const flashStatus = flash.status();
    const [query] = useUrlQuery();
    const { allow_register } = useInstanceSettings();

    const [resendEmail, , , resendEmailMeta] = useExecuteRequest();
    const [login, logRes, logErr, logMeta] = useExecuteRequest();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleVerificationEmailResend = useCallback(
        () =>
            resendEmail(
                API.post('/resend-verification-email', {
                    user_uuid: flashStatus.user_uuid,
                })
            ),
        [resendEmail]
    );

    const handleLogin = (form) => login(API.post('/login', form));

    if (!!flashStatus && flashStatus.stripe_id && window.tap) {
        window.tap('trial', flashStatus.stripe_id);
    }

    useEffect(() => {
        if (resendEmailMeta.isSuccess) {
            notify.success(t('guest:login_view.email_resent'));
        }
    }, [resendEmailMeta.isSuccess]);

    useEffect(() => {
        if (logMeta.isSuccess) {
            if (logRes.redirect) {
                setIsModalOpen(true);
            } else {
                window.location.href = '/';
            }
        }
    }, [logMeta.isSuccess, logRes]);

    return (
        <AuthViewLayout
            title={t('guest:login_view.title')}
            footer={
                <>
                    <h1 className={cx('heading')}>
                        <a href={url('auth.resetPassword')}>
                            {t('guest:login_view.cant_login')}
                        </a>
                    </h1>
                    {!!allow_register && (
                        <div className={cx('mt-5', 'mb-3')}>
                            <a href={url('auth.register')}>
                                {t('guest:register_view.sign_up')}
                            </a>{' '}
                            {t('guest:register_view.for_an_account')}
                        </div>
                    )}
                </>
            }
        >
            {!!flashStatus && flashStatus.verified == false && (
                <Alert color="danger" className={cx('mb-3')}>
                    <div className={cx('verification-message')}>
                        {flashStatus.expired === false &&
                            t(
                                'guest:login_view.verify_message_before_resend_link'
                            )}
                        {!!flashStatus.expired &&
                            t(
                                'guest:login_view.expired_message_before_resend_link'
                            )}{' '}
                        <u
                            className={cx('clickable')}
                            onClick={handleVerificationEmailResend}
                        >
                            {t('guest:login_view.verify_message_resend_link')}
                        </u>{' '}
                        {t('guest:login_view.verify_message_after_resend_link')}
                    </div>
                </Alert>
            )}
            {!!flashStatus && flashStatus.stripe_id && (
                <Alert color="success" className={cx('mb-3')}>
                    {flashStatus && t('guest:register_view.success_message')}
                </Alert>
            )}
            {query.verified && (
                <Alert color="success" className={cx('mb-3')}>
                    {t('guest:login_view.email_verified')}
                </Alert>
            )}
            <Form
                initialValues={{
                    ...INITIAL_VALUES,
                    ...flash.input(),
                }}
                error={logErr || { validation: flash.errors() }}
                onSubmit={handleLogin}
            >
                <Form.Field
                    className={cx('mb-4')}
                    placeholder={t('guest:login_view.enter_email')}
                    disabled={logMeta.isLoading}
                    type="email"
                    name="email"
                    required
                />
                <Form.Field
                    className={cx('mb-4')}
                    placeholder={t('guest:login_view.enter_password')}
                    disabled={logMeta.isLoading}
                    type="password"
                    name="password"
                    id="current-password"
                    autoComplete="current-password"
                    required
                />
                <Form.Submit
                    loading={logMeta.isLoading}
                    block
                    className={cx('font-weight-bold')}
                    label={t('guest:login_view.continue')}
                />
            </Form>

            {isModalOpen && (
                <VerifyCodeModal isOpen onClose={() => setIsModalOpen(false)} />
            )}
        </AuthViewLayout>
    );
};

export default LoginView;
