import React, { useEffect, useRef, useState } from 'react';

import { Collapse } from 'reactstrap';
import Icon from 'components/Icon';
import PropTypes from 'prop-types';
import classNames from 'helpers/classNames';
import styles from './Accordion.scss';
import useFormState from 'hooks/useFormState';
import { useLayoutEffect } from 'react';

const cx = classNames(styles);

/**
 * Accordion component with header and collapsed content
 * @param {boolean} openByDefault - determinates if content should be not collapsed at start or not
 * @param {*} headerContent - content that will be render inside header of the accordion
 * @param {string} className - css class that will be added to accordion element
 * @param {string} contentClassName - css class that will be added to accordion content element
 */
const Accordion = ({
    className = '',
    contentClassName = '',
    openByDefault = false,
    headerContent = null,
    children = null,
}) => {
    const [isOpen, setIsOpen] = useState(openByDefault);
    const accordionRef = useRef(null);
    const formState = useFormState();
    const error = (formState || {}).error || null;
    /**
     * Toggle open state
     */
    const handleClick = () => setIsOpen((open) => !open);

    useEffect(() => {
        if (error) {
            handleValidationError();
        }
    }, [error]);

    /**
     * Handle check if there are any validation errors inside the accordion and open it if true.
     */
    const handleValidationError = () => {
        // console.log('validation error');
        const allFields = accordionRef.current.querySelectorAll(
            ':invalid, [aria-invalid="true"]'
        );

        // console.log(allFields);

        if (allFields.length) {
            setIsOpen(true);
        }
    };

    return (
        <>
            <div
                className={cx(className, 'accordion', { isOpen })}
                ref={accordionRef}
            >
                <h2
                    className={cx(
                        'header',
                        'h4',
                        'font-weight-bold',
                        'mt-3',
                        'mt-lg-4'
                    )}
                >
                    <button
                        className={cx('button')}
                        onClick={handleClick}
                        type="button"
                    >
                        <span className={cx('headerContent')}>
                            {headerContent}
                        </span>
                        <Icon className={cx('icon')} icon="md/ic_expand_more" />
                    </button>
                </h2>
                <Collapse isOpen={isOpen} className={cx('content')}>
                    {children}
                </Collapse>
            </div>
        </>
    );
};

Accordion.propTypes = {
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    openByDefault: PropTypes.bool,
    headerContent: PropTypes.node,
    children: PropTypes.node,
};

export default Accordion;
