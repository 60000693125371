import Client from 'models/Client';
import { FEATURE_WHITELABEL } from 'constants/features';
import Site from 'models/Site';
import useAppState from './useAppState';
import useInstanceSettings from './useInstanceSettings';
import { useMemo } from 'react';

const useSiteBannerFlavor = (site = new Site()) => {
    const { banner_static_files_url } = useInstanceSettings();
    const { enabledFeatures } = useAppState();

    const result = useMemo(() => {
        const data = {
            url: banner_static_files_url,
            eventsPrefix: 'cf',
            consentCookie: 'cookiefirst-consent',
        };

        if (!site || !(site instanceof Site)) {
            return data;
        }

        const client = site.client || new Client();
        const usesWhitelabelBanner =
            !!client.whitelabel_banner_enabled &&
            enabledFeatures[FEATURE_WHITELABEL];

        if (!usesWhitelabelBanner) {
            return data;
        }

        data.url = client.whitelabel_banner_cdn || banner_static_files_url;
        data.eventsPrefix = 'banner';
        data.consentCookie = 'visitor-consent';

        return data;
    }, [site]);

    return result;
};

export default useSiteBannerFlavor;
