import React, { useMemo } from 'react';

import Icon from 'components/Icon/Icon';
import NotFound from 'views/other/NotFound/NotFound';
import SecondarySideBar from 'core/SecondarySideBar';
import SecondarySideBarMenu from 'core/SecondarySideBarMenu';
import Spinner from 'components/Spinner';
import classNames from 'helpers/classNames';
import { getTabs } from './tabs';
import styles from './EditClientView.scss';
import useAppState from 'hooks/useAppState';
import useClient from 'hooks/useClient';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const EditClientView = () => {
    const { t } = useTranslation();
    const { user, enabledFeatures } = useAppState();
    const { client: clientUuid, tab: tabKey } = useParams();
    const [client, fetchMeta, reload] = useClient(clientUuid);

    const [tabs, activeTab] = useMemo(() => {
        if (!fetchMeta.isSuccess || !client) {
            return [[], null];
        }

        const tabs = getTabs(user, client, t, enabledFeatures);

        const activeTab = tabs.find((tab) => tab.key === tabKey) || tabs[0];
        return [tabs, activeTab];
    }, [client, fetchMeta.isSuccess, user, t, tabKey]);

    return (
        <>
            <SecondarySideBar>
                {client ? (
                    <>
                        <div
                            className={cx(
                                'mb-5',
                                'pr-3',
                                'pl-4',
                                'text-white',
                                'd-none',
                                'd-md-block'
                            )}
                        >
                            <Icon
                                icon="md/ic_business_center"
                                className={cx('mr-2')}
                            />
                            {client.name}
                        </div>
                        <SecondarySideBarMenu
                            links={tabs}
                            mobileLabel={client ? client.name : ''}
                        />
                    </>
                ) : (
                    <Spinner className={cx('sidebarSpinner')} />
                )}
            </SecondarySideBar>
            <h1>{t('common:edit_client_view.title')}</h1>
            {fetchMeta.isLoading && <Spinner />}
            {!fetchMeta.isLoading && !activeTab && <NotFound />}
            {!fetchMeta.isLoading && !!activeTab && (
                <div className={cx('activeTab')}>
                    <h2>{activeTab.label}</h2>
                    <activeTab.component client={client} reload={reload} />
                </div>
            )}
        </>
    );
};

export default EditClientView;
