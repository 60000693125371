import { Badge } from 'reactstrap';
import Link from 'components/Link';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'helpers/classNames';
import styles from './SiteGroupBadge.scss';
import { useTranslation } from 'react-i18next';
import useUrl from 'hooks/useUrl';

const cx = classNames(styles);

const SiteGroupBadge = ({ group }) => {
    const url = useUrl();
    const { t } = useTranslation();
    if (!group) {
        return null;
    }

    const label = t('common:site_list_item.badge.site_group');
    const href = url('userNS.siteGroups.edit', { group: group.uuid });
    return (
        <Badge color="primary" className={cx('mr-2')}>
            <Link className={cx('text-light')} to={href}>
                {label}: {group.name}
            </Link>
        </Badge>
    );
};

SiteGroupBadge.propTypes = {
    group: PropTypes.shape({
        uuid: PropTypes.string,
        name: PropTypes.string,
    }),
};

SiteGroupBadge.defaultProps = {};

export default SiteGroupBadge;
