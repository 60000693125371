import { url } from 'core/Router';
import useAppState from 'hooks/useAppState';
import { useCallback } from 'react';

const useUrl = () => {
    const { user } = useAppState();
    const userNS = user.is_admin ? 'admin' : 'dash';

    const getUrl = useCallback(
        (key, ...args) => {
            let finalKey = key;
            if (key.indexOf('userNS') === 0) {
                finalKey = finalKey.replace('userNS', userNS);
            }

            return url(finalKey, ...args);
        },
        [userNS]
    );

    return getUrl;
};

export default useUrl;
