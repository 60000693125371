import useApiNamespace from 'hooks/useApiNamespace';
import useListing from 'hooks/useListing';

const useInfoBarMessages = (params) => {
    const namespace = useApiNamespace();
    const res = useListing(`${namespace}/info-bar-messages`, params);
    return res;
};

export default useInfoBarMessages;
