import { Badge, Col, Row } from 'reactstrap';
import React, { useCallback, useEffect, useState } from 'react';
import API from 'helpers/api';
import Authorize from 'core/Authorize/Authorize';
import Button from 'components/Button';
import ClientBadge from 'components/ClientBadge';
import DeleteUserModal from './components/DeleteUserModal';
import Link from 'components/Link';
import PropTypes from 'prop-types';
import classNames from 'helpers/classNames';
import styles from './UserListItem.scss';
import useAppState from 'hooks/useAppState';
import useExecuteRequest from 'hooks/useExecuteRequest';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';
import useUrl from 'hooks/useUrl';

const cx = classNames(styles);

const UserListItem = ({ item, reloadList }) => {
    const { t } = useTranslation();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const { user } = useAppState();
    const url = useUrl();
    const notify = useNotifications();
    const [resend, , resendErr, resendMeta] = useExecuteRequest();
    const handleResend = useCallback(() => {
        resend(API.post('resend-verification-email', { user_uuid: item.uuid }));
    }, [item.uuid]);

    useEffect(() => {
        if (resendMeta.isSuccess) {
            notify.success(t('common:users_list_view.resent'));
        }
        if (resendMeta.isError) {
            notify.error(resendErr.message);
        }
    }, [resendMeta.isSuccess, resendMeta.isError]);

    return (
        <div className={cx('item', 'border-primary')}>
            <Row form className={cx('align-items-center')}>
                <Col md="auto">
                    <div className={cx('mb-2')}>
                        {item.display_name || item.full_name}
                        {!!item.email && (
                            <Link
                                to={url('userNS.users.edit', {
                                    user: item.uuid,
                                })}
                                className={cx('ml-2')}
                            >
                                {item.email}
                            </Link>
                        )}
                    </div>
                    <div className={cx('d-flex', 'align-items-center')}>
                        {/* show client badge if user is allowed to view clients listing */}
                        <Authorize
                            policy={Authorize.ClientPolicy}
                            action="viewAny"
                        >
                            <ClientBadge client={item.client} />
                        </Authorize>
                        {!!user.is_admin && (
                            <Badge color="secondary" className={cx('mr-2')}>
                                {t('common:permissions.roles.role')}:{' '}
                                {(item.roles || []).length
                                    ? t(
                                          `common:permissions.roles.${item.roles[0]}`
                                      )
                                    : '---'}
                            </Badge>
                        )}
                    </div>
                </Col>
                <Col md />
                <Authorize
                    policy={Authorize.UserPolicy}
                    action="view"
                    model={item}
                >
                    <Col md="auto" xs="12" className={cx('mt-3', 'mt-md-0')}>
                        <Link
                            icon="md/ic_build"
                            button
                            className={cx('w-100')}
                            to={url('userNS.users.edit', {
                                user: item.uuid,
                            })}
                        >
                            {t('common:buttons.settings')}
                        </Link>
                    </Col>
                </Authorize>
                {!item.email_verified_at && (
                    <Authorize
                        policy={Authorize.UserPolicy}
                        action="update"
                        model={item}
                    >
                        <Button
                            icon="md/ic_mail_outline"
                            loading={resendMeta.isLoading}
                            onClick={handleResend}
                        >
                            {t('common:users_list_view.resend_email')}
                        </Button>
                    </Authorize>
                )}
                <Authorize
                    policy={Authorize.UserPolicy}
                    action="delete"
                    model={item}
                >
                    <Col md="auto" xs="12" className={cx('mt-3', 'mt-md-0')}>
                        <Button
                            icon="md/ic_delete_forever"
                            // don't allow deletion of the current user's client
                            disabled={user.uuid === item.uuid}
                            color="danger"
                            className={cx('w-100')}
                            onClick={() => {
                                setShowDeleteModal(true);
                            }}
                        >
                            {t('common:buttons.delete')}
                        </Button>
                    </Col>
                    <DeleteUserModal
                        user={item}
                        isOpen={showDeleteModal}
                        onSuccess={reloadList}
                        onCancel={() => setShowDeleteModal(false)}
                    />
                </Authorize>
            </Row>
        </div>
    );
};

UserListItem.propTypes = {
    item: PropTypes.shape({
        uuid: PropTypes.string,
        full_name: PropTypes.string,
        display_name: PropTypes.string,
        email: PropTypes.string,
        client_uuid: PropTypes.string,
        email_verified_at: PropTypes.string,
        client: PropTypes.shape({
            name: PropTypes.string,
        }),
        roles: PropTypes.arrayOf(PropTypes.string),
    }),
    reloadList: PropTypes.func,
};

UserListItem.defaultProps = {};

export default UserListItem;
