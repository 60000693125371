import Button from 'components/Button';
import PropTypes from 'prop-types';
import React from 'react';
import Spinner from 'components/Spinner';
import classNames from 'helpers/classNames';
import styles from './CookiesListItem.scss';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const CookiesListItem = ({
    cookie,
    onCookieSelected,
    isLoading,
    status,
    ...props
}) => {
    const { t } = useTranslation();

    return (
        <tr>
            <td>{cookie.display_name}</td>
            <td>{cookie.category || '----'}</td>
            <td>{cookie.goal || '----'}</td>
            <td>
                <Button
                    className={cx('btn-select-cookie')}
                    icon={
                        isLoading
                            ? ''
                            : status && status.result
                            ? 'md/ic_check'
                            : status && !status.result
                            ? 'md/ic_cross'
                            : 'md/ic_build'
                    }
                    color="primary"
                    onClick={() => onCookieSelected(cookie)}
                    loading={isLoading}
                >
                    {t('common:buttons.settings')}
                </Button>
            </td>
        </tr>
    );
};

CookiesListItem.propTypes = {
    cookie: PropTypes.object,
    onCookieSelected: PropTypes.func,
    isLoading: PropTypes.bool,
    status: PropTypes.object,
};

CookiesListItem.defaultProps = {
    onCookieSelected: () => null,
    cookie: null,
    isLoading: false,
};

export default CookiesListItem;
