import { Card as BsCard } from 'reactstrap';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'helpers/classNames';
import styles from './Card.scss';

const cx = classNames(styles);

const Card = ({ className, ...props }) => (
    <BsCard {...props} className={cx(className, 'card')} body />
);

Card.propTypes = {
    className: PropTypes.string,
};

Card.defaultProps = {
    className: null,
};

export default Card;
