import React, { useCallback } from 'react';

import Icon from 'components/Icon';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'helpers/classNames';
import styles from './SecondarySideBarLink.scss';

const cx = classNames(styles);

const SecondarySideBarLink = ({ to, icon, children, disabled }) => {
    const handleClick = useCallback(
        (e) => {
            if (disabled) {
                e.preventDefault();
            }
        },
        [disabled]
    );

    return (
        <NavLink
            to={to}
            isActive={(match, location) => to === location.pathname}
            exact
            activeClassName={cx('active')}
            onClick={handleClick}
            className={cx(
                'btn',
                'btn-primary',
                'btn-block',
                'menu-item',
                'text-left',
                'd-flex',
                'align-items-start', // this is done for correct layout when a menu item label is longer than one line
                { disabled }
            )}
        >
            {!!icon && <Icon className={cx('icon', 'mr-2')} icon={icon} />}
            <span>{children}</span>
        </NavLink>
    );
};
SecondarySideBarLink.propTypes = {
    to: PropTypes.string,
    icon: PropTypes.string,
    children: PropTypes.node,
    disabled: PropTypes.bool,
};

SecondarySideBarLink.defaultProps = {};

export default SecondarySideBarLink;
