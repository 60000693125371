import { Col, Row } from 'reactstrap';
import React, { useCallback } from 'react';

import API from 'helpers/api';
import Form from 'components/Form';
import Notification from 'components/Notification';
import classNames from 'helpers/classNames';
import styles from './InviteResellerView.scss';
import useExecuteRequest from 'hooks/useExecuteRequest';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const INITIAL_VALUES = {
    reseller_email: '',
    language: ['en'],
};

const InviteResellerView = () => {
    const { t } = useTranslation();
    const [request, res, err, saveStatus] = useExecuteRequest();
    const handleSubmit = useCallback(
        (data) => {
            request(API.post('/admin/invite-reseller', data));
        },
        [request]
    );

    return (
        <>
            <h1>{t('admin:clients.invite_reseller_view.title')}</h1>
            {saveStatus.isSuccess && (
                <Notification type="success">{res.message}</Notification>
            )}
            {saveStatus.isError && (
                <Notification type="error">
                    <p>
                        {t(
                            'admin:clients.invite_reseller_view.failed_to_invite'
                        )}
                    </p>
                    <p>{err.message}</p>
                    {Object.values(err.validation).map((el, index) => (
                        <p key={index}>{el}</p>
                    ))}
                </Notification>
            )}
            <Form
                error={err}
                initialValues={INITIAL_VALUES}
                method="POST"
                onSubmit={handleSubmit}
                isSubmitting={saveStatus.isLoading}
            >
                <Row>
                    <Col xs="12" lg="6">
                        <h4>
                            {t('admin:clients.invite_reseller_view.form_title')}
                        </h4>
                        <Form.Field
                            type="email"
                            label={t('common:common.email')}
                            name="reseller_email"
                            required
                        />
                        <Form.Field
                            label={t(
                                'admin:clients.invite_reseller_view.language'
                            )}
                            name="language"
                            required
                            type="LanguageDropdown"
                            adminOnly
                        />
                        <Form.Submit icon="md/ic_mail_outline">
                            {t('common:buttons.invite')}
                        </Form.Submit>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default InviteResellerView;
