import BasePolicy from './BasePolicy';
import User from 'helpers/user';
import usePolicy from 'hooks/usePolicy';

export const VIEW_ALL_PERMISSION = 'SITE_GROUPS.VIEW_ALL';
export const VIEW_OWN_PERMISSION = 'SITE_GROUPS.VIEW_OWN';
export const CREATE_PERMISSION = 'SITE_GROUPS.CREATE';
export const UPDATE_ALL_PERMISSION = 'SITE_GROUPS.UPDATE_ALL';
export const UPDATE_OWN_PERMISSION = 'SITE_GROUPS.UPDATE_OWN';

export default class SiteGroupPolicy extends BasePolicy {
    /**
     *
     * @param {User} user
     * @returns {boolean}
     */
    static viewAny(user) {
        return user.canAny([VIEW_ALL_PERMISSION, VIEW_OWN_PERMISSION]);
    }

    static view(user, site) {
        if (user.can(VIEW_ALL_PERMISSION)) {
            return true;
        }

        if (user.can(VIEW_OWN_PERMISSION)) {
            return SiteGroupPolicy.userOwnsModel(user, site);
        }
    }

    static create(user) {
        return user.can(CREATE_PERMISSION);
    }

    static update(user, site) {
        // user with specific permission can manage all sites
        if (user.can(UPDATE_ALL_PERMISSION)) {
            return true;
        }

        // user can update sites belonging to their own client
        if (
            user.can(UPDATE_OWN_PERMISSION) &&
            SiteGroupPolicy.userOwnsModel(user, site)
        ) {
            return true;
        }
    }

    static delete(user, site) {
        return SiteGroupPolicy.update(user, site);
    }
}

/**
 * Check if user is allowed to perform an action
 * @param {string} permission
 * @param {object} model
 * @returns {boolean}
 */
export const useSiteGroupPolicy = (permission, model) => {
    return usePolicy(SiteGroupPolicy, permission, model);
};
