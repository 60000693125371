import React, { useCallback, useState } from 'react';

import EditImageModal from './components/EditImageModal';
import ImagePickerPreview from './components/ImagePickerPreview';
import PropTypes from 'prop-types';
import classNames from 'helpers/classNames';
import styles from './ImagePicker.scss';

const cx = classNames(styles);

const ImagePicker = ({
    value,
    name,
    previewClassName,
    renderPreview,
    invalid,
    aspect,
    width,
    height,
    required,
    onChange,
    disabled,
    ...props
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleChange = useCallback(
        (image) => {
            if (!image) {
                return;
            }

            onChange(image);
        },
        [onChange]
    );
    const openModal = useCallback(() => setIsModalOpen(true));
    return (
        <div
            className={cx('control', 'form-control', { 'is-invalid': invalid })}
        >
            <ImagePickerPreview
                className={previewClassName}
                renderPreview={renderPreview}
                allowEdit={!disabled}
                allowDelete={!disabled && !required}
                onEdit={openModal}
                onDelete={() => onChange('')}
                src={value}
            />
            {isModalOpen && (
                <EditImageModal
                    isOpen
                    onClose={() => setIsModalOpen(false)}
                    aspect={aspect}
                    width={width}
                    height={height}
                    onChange={handleChange}
                />
            )}
        </div>
    );
};

ImagePicker.propTypes = {
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    invalid: PropTypes.bool,
    renderPreview: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.string,
    previewClassName: PropTypes.string,
    aspect: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    onChange: PropTypes.func,
};

ImagePicker.defaultProps = {
    // aspect: 16 / 9
};

export default ImagePicker;
