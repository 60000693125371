import { Col, Row } from 'reactstrap';
import React, { useCallback, useEffect } from 'react';

import API from 'helpers/api';
import Form from 'components/Form';
import Notification from 'components/Notification';
import Spinner from 'components/Spinner';
import classNames from 'helpers/classNames';
import styles from './EditUserView.scss';
import useAppState from 'hooks/useAppState';
import useExecuteRequest from 'hooks/useExecuteRequest';
import { useParams } from 'react-router-dom';
import useRequest from 'hooks/useRequest';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const EditUserView = () => {
    const { t } = useTranslation();
    const { user: userUuid } = useParams();
    const { user: currentUser } = useAppState();

    const [user, userFetchErr, fetchMeta] = useRequest(
        API.get(`/client/users/${userUuid}`)
    );

    const [request, res, err, saveStatus] = useExecuteRequest();

    const handleSubmit = useCallback(
        (form) => {
            request(
                API.put(`/client/users/${userUuid}`, {
                    ...form,
                    preferred_language: Array.isArray(form.preferred_language)
                        ? form.preferred_language[0]
                        : form.preferred_language,
                    client_uuid: Array.isArray(form.client_uuid)
                        ? form.client_uuid[0]
                        : form.client_uuid,
                })
            );
        },
        [request]
    );

    useEffect(() => {
        if (fetchMeta.isLoading) {
            return;
        }

        if (fetchMeta.isSuccess) {
            user.password = '';
            user.password_confirmation = '';
        }
    }, [fetchMeta]);

    return (
        <>
            <h1>{t('client:users.edit_user_view.title')}</h1>
            {(saveStatus.isLoading || fetchMeta.isLoading) && <Spinner />}
            {saveStatus.isSuccess && (
                <Notification type="success">
                    {t('client:users.edit_user_view.user_updated')}
                </Notification>
            )}
            {saveStatus.isError && (
                <Notification type="error">
                    <p>{t('client:users.edit_user_view.failed_to_update')}</p>
                    <p>{err.message}</p>
                    {Object.values(err.validation).map((el, index) => (
                        <p key={index}>{el}</p>
                    ))}
                </Notification>
            )}
            {fetchMeta.isError && (
                <Notification type="error">
                    <p>{t('client:users.edit_user_view.not_allowed')}</p>
                </Notification>
            )}
            {!fetchMeta.isLoading && !fetchMeta.isError && (
                <Form
                    initialValues={user}
                    error={err}
                    className={cx({
                        'd-none': saveStatus.isLoading || fetchMeta.isLoading,
                    })}
                    onSubmit={handleSubmit}
                >
                    <Row>
                        <Col xs="12" lg="6">
                            <Row>
                                <Col xs="12" md="6">
                                    <Form.Field
                                        label={t(
                                            'client:field_labels.user.full_name'
                                        )}
                                        name="full_name"
                                        required
                                    />
                                </Col>
                                <Col xs="12" md="6">
                                    <Form.Field
                                        label={t(
                                            'client:field_labels.user.display_name'
                                        )}
                                        name="display_name"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" md="6">
                                    <Form.Field
                                        type="email"
                                        label={t(
                                            'client:field_labels.user.email'
                                        )}
                                        name="email"
                                        required
                                    />
                                </Col>
                                {(currentUser.is_reseller ||
                                    currentUser.is_admin) && (
                                    <>
                                        <Form.Field
                                            type="hidden"
                                            name="client_uuid"
                                        />
                                        <Col xs="12" md="6">
                                            <Form.Field
                                                label={t(
                                                    'client:field_labels.user.client_uuid'
                                                )}
                                                type="SelectClient"
                                                name="client_uuid"
                                                required
                                                placeholder={t(
                                                    'common:common.select_owner'
                                                )}
                                            />
                                        </Col>
                                    </>
                                )}
                            </Row>
                            <Row>
                                <Col xs="12" md="6">
                                    <Form.Field
                                        type="password"
                                        label={t(
                                            'client:field_labels.user.password_confirmation'
                                        )}
                                        name="password_confirmation"
                                    />
                                </Col>
                                <Col xs="12" md="6">
                                    <Form.Field
                                        type="password"
                                        label={t(
                                            'client:field_labels.user.password'
                                        )}
                                        name="password"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" lg="6">
                                    <Form.Field
                                        label={t(
                                            'client:field_labels.user.preferred_language'
                                        )}
                                        name="preferred_language"
                                        required
                                        adminOnly
                                        type="LanguageDropdown"
                                    />
                                </Col>
                            </Row>
                            <Form.Submit icon="md/ic_save">
                                {t('common:buttons.save')}
                            </Form.Submit>
                        </Col>
                    </Row>
                </Form>
            )}
        </>
    );
};

export default EditUserView;
