import { Alert, Col, Row } from 'reactstrap';
import {
    CONSENT_POLICIES,
    CONSENT_POLICY_CATEGORY_OPTIN,
    CONSENT_POLICY_GRANULAR_OPTIN,
} from 'constants/consentPolicies';
import React, { useEffect, useMemo, useState } from 'react';

import { FEATURE_GRANULAR_CONSENT } from 'constants/features';
import Form from 'components/Form';
import Link from 'components/Link';
import PropTypes from 'prop-types';
import ResetGroupConsentsButton from 'components/ResetGroupConsentsButton';
import Spinner from 'components/Spinner';
import _get from 'lodash/get';
import _noop from 'lodash/noop';
import classNames from 'helpers/classNames';
import dayjs from 'dayjs';
import styles from './EditSiteGroupFormFields.scss';
import useAppState from 'hooks/useAppState';
import useCookieCategories from 'hooks/useCookieCategories';
import { useDebouncedCallback } from 'use-debounce';
import useFormState from 'hooks/useFormState';
import useSites from 'hooks/useSites';
import useTranslate from 'hooks/useTranslate';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);
const CONSENT_POLICIES_LABELS = {
    [CONSENT_POLICY_CATEGORY_OPTIN]: 'category_optin',
    [CONSENT_POLICY_GRANULAR_OPTIN]: 'granular_optin',
};
const filterSite = (i, g) => {
    if (i.group && i.group.uuid !== g.uuid) {
        return false;
    }
    if (!i.name && !i.base_url) {
        return false;
    }

    return true;
};

const EditSiteGroupFormFields = ({ group, canSelectOwner }) => {
    const [groupTrans] = useTranslate('common:site_groups.common');
    const [siteTrans] = useTranslate('common:site_basic_settings_tab');
    const { t } = useTranslation();
    const { data: form, handleChange: onChange } = useFormState();
    const { consentExpiration, enabledFeatures } = useAppState();
    const [siteSearch, setSiteSearch] = useState('');

    const siteParams = {
        page: 1,
        per_page: -1,
        search: siteSearch,
        clients: form.client_uuid ? [form.client_uuid] : null,
    };
    const [sites, sitesState] = useSites(siteParams);

    const [cookieCategories] = useCookieCategories();

    const isEligibleForBulk = useMemo(() => {
        const plans = sites.data
            .filter((i) => form.sites.includes(i.uuid))
            .map((i) => _get(i, 'plan.config_name', 'free'));
        if (form.sites.length !== plans.length) {
            return false;
        }

        return plans.includes('plus') && !plans.includes('free');
    }, [form.sites, sites.data]);

    useEffect(() => {
        if (sitesState.isSuccess && !isEligibleForBulk) {
            // automatically disable bulk consent when not eligible
            onChange('bulk_consent_enabled', false);
        }
    }, [JSON.stringify(form.sites)]);

    const handleSearchChange = useDebouncedCallback(
        (_, s) => setSiteSearch(s),
        300
    );

    const disabledFeaturesMask = (
        <div
            className={cx('disabled-mask', {
                'd-none': form.bulk_consent_enabled,
            })}
        />
    );

    return (
        <>
            <Row>
                <Col xs="12" md="6">
                    <Form.Field
                        label={groupTrans('name')}
                        name="name"
                        required
                    />
                </Col>
                <Col xs="12" md="6">
                    <Form.Field
                        label={groupTrans('belongs_to')}
                        type="SelectClient"
                        name="client_uuid"
                        required={canSelectOwner}
                        placeholder={groupTrans('select_owner')}
                        disabled={!canSelectOwner}
                        onChange={() => onChange('sites', [])} // clear sites selection when changing client
                    />
                </Col>
                <Col xs="12">
                    <Form.Field
                        name="bulk_consent_enabled"
                        disabled={!isEligibleForBulk}
                        label={groupTrans('enable_bulk')}
                        inlineLabel={groupTrans('enable_bulk')}
                        helpText={
                            <>
                                <p>{groupTrans('enable_bulk_help')}</p>
                                <p>{groupTrans('enable_bulk_restrictions')}</p>
                            </>
                        }
                        type="Toggle"
                    />
                </Col>
                <Col xs="12" />
                <Col xs="12" md="6" className={cx('position-relative')}>
                    {disabledFeaturesMask}
                    <Row form className={cx('align-items-end', 'mb-3')}>
                        <Col xs="12" md>
                            <Form.Field
                                className={cx('mb-0')}
                                label={groupTrans('bulk_iframe_url')}
                                helpText={groupTrans('bulk_iframe_url_help')}
                                disabled={!form.bulk_consent_enabled}
                                name="bulk_iframe_url"
                                required
                            />
                        </Col>
                        <Col md="auto">
                            <Link
                                button
                                external
                                disabled={!form.bulk_consent_enabled}
                                to="/site-groups/download-iframe"
                                icon="md/ic_cloud_download"
                            >
                                {t('common:common.download')}
                            </Link>
                        </Col>
                    </Row>
                </Col>
                <Col xs="12" />
                <Col xs="12" md="6" className={cx('position-relative')}>
                    {disabledFeaturesMask}
                    <Form.Field
                        type="ChoiceGroup"
                        label={siteTrans('categories')}
                        helpText={siteTrans('categories_help')}
                        multiple
                        placeholder={t('common:common.loading')}
                        name="used_cookie_categories"
                        disabled={!form.bulk_consent_enabled}
                        options={cookieCategories}
                    />
                </Col>
                <Col xs="12" md="6" className={cx('position-relative')}>
                    {disabledFeaturesMask}
                    <Form.Label>
                        {siteTrans('categories_enabled_default')}
                    </Form.Label>
                    <Form.HelpText>
                        {siteTrans('categories_enabled_default_help')}
                    </Form.HelpText>
                    <Row>
                        <Col></Col>
                        <Col>{siteTrans('preselect')}</Col>
                        <Col>{siteTrans('execute')}</Col>
                    </Row>
                    {cookieCategories
                        .filter((c) => c.value !== 'necessary')
                        .map((cat) => (
                            <Row key={cat.value} className={cx('mb-2')}>
                                <Col
                                    className={cx(
                                        'd-flex',
                                        'align-items-center'
                                    )}
                                >
                                    <span>{cat.label}</span>
                                </Col>
                                <Col>
                                    <Form.Field
                                        className={cx('mb-0')}
                                        type="Toggle"
                                        disabled={!form.bulk_consent_enabled}
                                        name={`${cat.value}_enabled_default`}
                                    />
                                </Col>
                                <Col>
                                    <Form.Field
                                        className={cx('mb-0')}
                                        type="Toggle"
                                        disabled={!form.bulk_consent_enabled}
                                        name={`${cat.value}_executed_default`}
                                    />
                                </Col>
                            </Row>
                        ))}
                </Col>
                <Col xs="12" className={cx('mb-3', 'position-relative')}>
                    {disabledFeaturesMask}
                    <Form.Label>
                        {siteTrans('reset_consents_heading')}
                    </Form.Label>
                    <Form.HelpText>
                        {siteTrans('reset_consents_help')}
                    </Form.HelpText>
                    <ResetGroupConsentsButton
                        disabled={!form.bulk_consent_enabled}
                        groupUuid={group.uuid}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs="12" md="6" className={cx('position-relative')}>
                    {disabledFeaturesMask}
                    <Form.Field
                        name="consent_expires_after"
                        type="SingleDropdownInput"
                        options={consentExpiration.options.map((option) => ({
                            value: option,
                            label: dayjs
                                .duration(
                                    -1 * option, // -1 might be needed for Finnish lang if we ever support it
                                    'months'
                                )
                                .humanize(),
                        }))}
                        label={siteTrans('consent_expires_after')}
                        helpText={siteTrans('consent_expires_after_help')}
                    />
                </Col>
                {!!enabledFeatures[FEATURE_GRANULAR_CONSENT] && (
                    <Col xs="12" lg="6" className={cx('position-relative')}>
                        {disabledFeaturesMask}
                        <Form.Field
                            name="consent_policy"
                            type="SingleDropdownInput"
                            options={CONSENT_POLICIES.map((option) => ({
                                value: option,
                                label: siteTrans(
                                    `consent_policy.${CONSENT_POLICIES_LABELS[option]}`
                                ),
                            }))}
                            placeholder={siteTrans('consent_policy.help')}
                            label={siteTrans('consent_policy.label')}
                            helpText={siteTrans('consent_policy.help')}
                        />
                    </Col>
                )}
                {!!enabledFeatures[FEATURE_GRANULAR_CONSENT] && (
                    <Col xs="12" lg="6" className={cx('position-relative')}>
                        {disabledFeaturesMask}
                        <Form.Field
                            disabled={
                                form.consent_policy ===
                                CONSENT_POLICY_GRANULAR_OPTIN
                            }
                            name="autoblock_known"
                            label={siteTrans('autoblock_known')}
                            type="Toggle"
                        />
                        {!!form.autoblock_known &&
                            form.consent_policy ===
                                CONSENT_POLICY_GRANULAR_OPTIN && (
                                <Alert color="warning">
                                    {siteTrans('autoblock_known_disabled')}
                                </Alert>
                            )}
                    </Col>
                )}
                <Col xs="12">
                    <Form.Label required>{groupTrans('sites')}</Form.Label>
                    <Row>
                        <Col xs="12" sm="6">
                            <Form.Field
                                type="search"
                                name="search"
                                placeholder={t('common:common.search')}
                                onChange={handleSearchChange.callback}
                            />
                        </Col>
                    </Row>
                    <Form.Field
                        type="ChoiceGroup"
                        multiple
                        placeholder={sitesState.isLoading ? <Spinner /> : null}
                        name="sites"
                        disabled={sitesState.isError}
                        options={sites.data
                            .filter((i) => {
                                if (!filterSite(i, group)) {
                                    return false;
                                }
                                if (!form.client_uuid === i.client_uuid) {
                                    return false;
                                }

                                return true;
                            })
                            .map((site) => ({
                                value: site.uuid,
                                label: site.getDisplayName(t),
                            }))}
                    />
                </Col>
            </Row>
            <Form.Submit icon="md/ic_save" label={t('common:common.save')} />
        </>
    );
};

EditSiteGroupFormFields.propTypes = {
    group: PropTypes.object,
    form: PropTypes.shape({
        name: PropTypes.string,
        client_uuid: PropTypes.string,
        bulk_consent_enabled: PropTypes.bool,
        sites: PropTypes.arrayOf(PropTypes.string),
        search: PropTypes.string,
        autoblock_known: PropTypes.bool,
        consent_policy: PropTypes.number,
    }),
    onChange: PropTypes.func,
    canSelectOwner: PropTypes.bool,
};

EditSiteGroupFormFields.defaultProps = {
    group: {},
    form: {},
    onChange: _noop,
    canSelectOwner: false,
};

export default EditSiteGroupFormFields;
