import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'helpers/classNames';
import splitMultilineText from 'helpers/splitMultilineText';
import styles from './HtmlContent.scss';

const cx = classNames(styles);

const HtmlContent = ({ wrapperTag = 'div', itemTag = 'p', contents = '' }) => {
    if (!contents) {
        return null;
    }

    const paragraphs = splitMultilineText(contents);
    if (!paragraphs.length) {
        return null;
    }

    const Wrapper = wrapperTag || 'div';
    const Item = itemTag || 'p';
    return (
        <Wrapper>
            {paragraphs.map((item, index) => (
                <Item key={index} dangerouslySetInnerHTML={{ __html: item }} />
            ))}
        </Wrapper>
    );
};

HtmlContent.propTypes = {
    wrapperTag: PropTypes.string,
    itemTag: PropTypes.string,
    contents: PropTypes.string,
};

export default HtmlContent;
