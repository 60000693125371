import NotFound from 'views/other/NotFound';
import React from 'react';
import Spinner from 'components/Spinner';
import Switch from 'components/Switch';
import { Table } from 'reactstrap';
import classNames from 'helpers/classNames';
import styles from './RolesAndPermissionsView.scss';
import useListing from 'hooks/useListing';
import useTranslate from 'hooks/useTranslate';

const cx = classNames(styles);

const RolesAndPermissionsView = ({}) => {
    const [trans] = useTranslate('common:permissions.roles');
    const [res, fetchMeta] = useListing('/admin/roles');

    if (fetchMeta.isLoading) {
        return <Spinner />;
    }

    const roles = res.data || [];

    if (!roles.length) {
        return <NotFound />;
    }
    const permissions = Object.keys(roles[0].permissions);
    return (
        <Table striped responsive size="sm">
            <thead>
                <tr>
                    <th>Permission</th>
                    {roles.map((role) => (
                        <th key={role.name}>{trans(role.name)}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {permissions.map((permission) => (
                    <tr key={permission}>
                        <th scope="row">{permission}</th>
                        {roles.map((role) => (
                            <td key={role.name}>
                                <Switch
                                    className={cx('align-middle')}
                                    disabled
                                    checked={!!role.permissions[permission]}
                                />
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

RolesAndPermissionsView.propTypes = {};

export default RolesAndPermissionsView;
