import React, { useMemo, useState } from 'react';

import ChooseIconTab from '../ChooseIconTab';
import Modal from 'components/Modal';
import PropTypes from 'prop-types';
import Spinner from 'components/Spinner';
import Tabs from 'components/Tabs';
import UploadIconTab from '../UploadIconTab';
import classNames from 'helpers/classNames';
import styles from './ChooseIconModal.scss';
import useListing from 'hooks/useListing';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const ChooseIconModal = ({
    value,
    isOpen,
    onChange,
    setModalSize,
    ...props
}) => {
    const [page, setPage] = useState(1);
    const { t } = useTranslation();
    const [icons, iconsMeta] = useListing(
        '/common/icons',
        { style: ['solid', 'regular'], per_page: 96, page },
        isOpen
    );

    // CONST is here and not at the top level because of translations
    const TABS = [
        {
            label: t('components:icon_picker_field.icons'),
            key: 'icons',
            component: ChooseIconTab,
        },
        {
            label: t('components:icon_picker_field.upload'),
            key: 'upload',
            component: UploadIconTab,
        },
    ];

    const initialTab = useMemo(() => {
        const index = TABS.findIndex((tab) => {
            if (value.indexOf('uploads/') === 0) {
                return tab.key === 'upload';
            }

            return tab.key === 'icons';
        });

        return index;
    }, [value]);

    return (
        <Modal
            {...props}
            isOpen={isOpen}
            title={t('components:icon_picker_field.choose_icon')}
        >
            <Modal.Body>
                {iconsMeta.isLoading && <Spinner />}
                {iconsMeta.isSuccess && (
                    <Tabs
                        initialTab={initialTab}
                        onChange={onChange}
                        value={value}
                        icons={icons.data || []}
                        tabs={TABS}
                        paginationInfo={{
                            setPage,
                            currentPage: page,
                            lastPage: icons?.last_page,
                        }}
                        setModalSize={setModalSize}
                    />
                )}
            </Modal.Body>
        </Modal>
    );
};

ChooseIconModal.propTypes = {
    value: PropTypes.string,
    isOpen: PropTypes.bool,
    setModalSize: PropTypes.func,
    onChange: PropTypes.func,
};

export default ChooseIconModal;
