import React, { useState } from 'react';

import CookiesListItem from '../CookiesListItem';
import EditCookieDataModal from '../../components/EditCookieDataModal';
import Pagination from 'components/Pagination';
import Spinner from 'components/Spinner';
import { Table } from 'reactstrap';
import useListing from 'hooks/useListing';
import { useTranslation } from 'react-i18next';
import useUrlQuery from 'hooks/useUrlQuery';

const CookiesEmptyTab = () => {
    const { t } = useTranslation();
    const [query] = useUrlQuery();
    const [activeModal, setActiveModal] = useState(null);
    const [pendingCookies, setPendingCookies] = useState([]);
    const [finishedCookies, setFinishedCookies] = useState([]);
    const params = { ...query, empty: 1 };
    const [cookies, fetchMeta, err, reload] = useListing(
        '/admin/cookies',
        params
    );

    if (fetchMeta.isLoading) {
        return <Spinner />;
    }
    return (
        <>
            <Table>
                <thead>
                    <tr>
                        <th>
                            {t(
                                'admin:cookies.cookies_list_view.cookie_listing_columns.cookie_name'
                            )}
                        </th>
                        <th>
                            {t(
                                'admin:cookies.cookies_list_view.cookie_listing_columns.category'
                            )}
                        </th>
                        <th>
                            {t(
                                'admin:cookies.cookies_list_view.cookie_listing_columns.goal'
                            )}
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {cookies.data.map((cookie, index) => (
                        <>
                            <CookiesListItem
                                key={`cookie-${cookie.id}`}
                                cookie={cookie}
                                isLoading={pendingCookies.includes(cookie.id)}
                                status={finishedCookies.find(
                                    (item) => item.id === cookie.id
                                )}
                                onCookieSelected={() =>
                                    setActiveModal(cookie.id)
                                }
                            />
                            <EditCookieDataModal
                                key={`modal-${cookie.id}`}
                                cookie={cookie}
                                onClose={() => setActiveModal(null)}
                                onSubmit={() => {
                                    setPendingCookies((pendingCookies) => [
                                        ...pendingCookies,
                                        cookie.id,
                                    ]);
                                }}
                                onRequestEnd={(result) => {
                                    setPendingCookies((pendingCookies) =>
                                        pendingCookies.filter(
                                            (item) => item !== cookie.id
                                        )
                                    );
                                    setFinishedCookies((finishedCookies) => [
                                        ...finishedCookies,
                                        { id: cookie.id, result },
                                    ]);
                                }}
                                isOpen={activeModal === cookie.id}
                            />
                        </>
                    ))}
                </tbody>
            </Table>
            <Pagination
                currentPage={cookies.current_page}
                lastPage={cookies.last_page}
            />
        </>
    );
};

export default CookiesEmptyTab;
