import { Col, Row } from 'reactstrap';
import React, { useRef } from 'react';

import PropTypes from 'prop-types';
import Switch from 'components/Switch';
import _noop from 'lodash/noop';
import classNames from 'helpers/classNames';
import styles from './Toggle.scss';

const cx = classNames(styles);

const Toggle = ({
    name,
    value,
    inlineLabel,
    invalid,
    onChange,
    disabled,
    ...props
}) => {
    const checked = typeof value === 'undefined' ? undefined : Boolean(value);

    const inputRef = useRef(null);

    /**
     * Trigger click on input element on switch's button click.
     */
    const handleSwitchClick = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    return (
        <label className={cx('label')}>
            <input
                ref={inputRef}
                type="checkbox"
                tabIndex="-1"
                value={1}
                name={name}
                onChange={onChange}
                className={cx('checkbox', 'form-control', {
                    'is-invalid': invalid,
                })}
                checked={checked}
            />
            <Row noGutters className={cx('row', { disabled })}>
                <Col xs="auto" className={cx('switchContainer', 'px-0')}>
                    <Switch
                        checked={!!checked}
                        onChange={handleSwitchClick}
                        disabled={disabled}
                        invalid={invalid}
                    />
                </Col>
                <Col xs className={cx('pl-2')}>
                    {inlineLabel}
                </Col>
            </Row>
        </label>
    );
};

Toggle.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    inlineLabel: PropTypes.node,
    onChange: PropTypes.func,
    errorFeedback: PropTypes.node,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    invalid: PropTypes.bool,
};

export default Toggle;
