import React, { useEffect, useMemo, useState } from 'react';

import Form from 'components/Form';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';
import classNames from 'helpers/classNames';
import styles from './SearchField.scss';
import useUrlQuery from 'hooks/useUrlQuery';

const cx = classNames(styles);

const SearchField = ({ label, ...props }) => {
    const [query, updateQuery] = useUrlQuery();
    const [search, setSearch] = useState(query.search || '');

    const updateSearchInQuery = useMemo(
        () =>
            _debounce((newSearch) => {
                updateQuery({
                    ...query,
                    search: newSearch,
                    page: undefined, // when searching, go back to first page
                });
            }, 300),
        [JSON.stringify(query)]
    );

    useEffect(() => updateSearchInQuery(search), [search]);
    return (
        <>
            {!!label && <Form.Label>{label}</Form.Label>}
            <Input
                {...props}
                value={search}
                name="search"
                type="text"
                onChange={(e) => setSearch(e.target.value)}
            />
        </>
    );
};

SearchField.propTypes = {
    label: PropTypes.node,
};

SearchField.defaultProps = {
    label: '',
};

export default SearchField;
