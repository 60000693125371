import { useEffect, useState } from 'react';

import API from 'helpers/api';
import Client from 'models/Client';
import useApiNamespace from 'hooks/useApiNamespace';
import useRequest from 'hooks/useRequest';

const useClient = (clientUuid) => {
    const namespace = useApiNamespace();
    const [client, setClient] = useState(null);
    const [res, err, fetchingMeta, reload] = useRequest(
        API.get(`/${namespace}/clients/${clientUuid}`),
        !!clientUuid
    );

    useEffect(() => {
        if (!fetchingMeta.isSuccess) {
            if (client !== null) {
                setClient(null);
            }

            return;
        }

        if (typeof res !== 'object') {
            return;
        }

        setClient(new Client(res));
    }, [fetchingMeta.isSuccess]);

    return [
        client,
        {
            isSuccess: fetchingMeta.isSuccess && client instanceof Client,
            isLoading: fetchingMeta.isLoading || !client,
            isError: fetchingMeta.isError,
        },
        reload,
    ];
};

export default useClient;
