import { Col, Row } from 'reactstrap';

import API from 'helpers/api';
import EditThirdPartyScriptForm from '../components/EditThirdPartyScriptForm';
import Notification from 'components/Notification';
import React from 'react';
import Spinner from 'components/Spinner';
import classNames from 'helpers/classNames';
import { convertImageBase64ToBlob } from 'helpers/images';
import styles from './EditThirdPartyScriptView.scss';
import useExecuteRequest from 'hooks/useExecuteRequest';
import { useParams } from 'react-router-dom';
import useRequest from 'hooks/useRequest';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const EditThirdPartyScriptView = () => {
    const { t } = useTranslation();
    const { script: scriptUUID } = useParams();
    const [save, saveRes, saveErr, saveMeta] = useExecuteRequest();
    const [script, fetchErr, fetchMeta] = useRequest(
        API.get(`/admin/third-party-scripts/${scriptUUID}`)
    );

    if (fetchMeta.isLoading || !script) {
        return <Spinner />;
    }

    const handleSubmit = (input) => {
        const imageFile = convertImageBase64ToBlob(input.logo || '');
        const url = `/admin/third-party-scripts/${scriptUUID}`;
        const saveRequest = API.request('PUT', url, {
            ...input,
            logo: script.logo || undefined,
            newLogo: imageFile || undefined,
        });
        save(saveRequest);
    };

    return (
        <>
            {saveMeta.isSuccess && (
                <Notification type="success">Changes saved</Notification>
            )}
            {saveMeta.isError && (
                <Notification type="error">{saveErr.message}</Notification>
            )}
            <h1>
                {t(
                    'admin:third-party-scripts.edit_third_party_script_view.title',
                    { name: script.name }
                )}
            </h1>
            <Row>
                <Col xs="12" sm="8" md="4">
                    <EditThirdPartyScriptForm
                        isSaving={saveMeta.isLoading}
                        saveError={saveErr}
                        onSubmit={handleSubmit}
                        script={script}
                    />
                </Col>
            </Row>
        </>
    );
};

EditThirdPartyScriptView.propTypes = {};

EditThirdPartyScriptView.defaultProps = {};

export default EditThirdPartyScriptView;
