import {
    CLIENT_TYPE_ADMIN_OPTIONS,
    CLIENT_TYPE_BUSINESS,
} from 'constants/clientTypes';
import { Col, Row } from 'reactstrap';
import React, { useCallback, useEffect, useState } from 'react';

import API from 'helpers/api';
import Form from 'components/Form';
import Notification from 'components/Notification';
import Spinner from 'components/Spinner';
import classNames from 'helpers/classNames';
import { countries } from 'constants/geoData';
import styles from './CreateClientView.scss';
import { url } from 'core/Router';
import useAppState from 'hooks/useAppState';
import useExecuteRequest from 'hooks/useExecuteRequest';
import { useHistory } from 'react-router-dom';
import useTranslate from 'hooks/useTranslate';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const INITIAL_VALUES = {
    name: '',
    vat_id: '',
    email: '',
    street: '',
    street_number: '',
    apartment: '',
    postcode: '',
    city: '',
    country: '',
    dev_urls_number: 1,
    new_user: false,
    user_full_name: '',
    user_display_name: '',
    user_email: '',
    user_password: '',
    user_password_confirmation: '',
    client_type: CLIENT_TYPE_BUSINESS,
};

const CreateClientView = () => {
    const { t } = useTranslation();
    const [trans] = useTranslate('common:edit_client_view.fields');
    const { vatCountries } = useAppState();
    const history = useHistory();
    const [request, res, err, saveStatus] = useExecuteRequest();
    const handleSubmit = useCallback(
        (form) => {
            const { new_user } = form;
            let data = { ...form };

            if (!new_user) {
                data = {
                    new_user: false,
                    apartment: data.apartment,
                    city: data.city,
                    country_code: data.country_code,
                    email: data.email,
                    name: data.name,
                    postcode: data.postcode,
                    dev_urls_number: data.dev_urls_number,
                    street: data.street,
                    street_number: data.street_number,
                    vat_id: data.vat_id,
                    client_type: data.client_type,
                };
            }
            request(API.post('/admin/clients', data));
        },
        [request]
    );

    // redirect to new client after success
    useEffect(() => {
        if (!saveStatus.isSuccess) {
            return;
        }

        history.push(url('admin.clients.edit', { client: res.client }));
    }, [res, saveStatus.isSuccess]);
    return (
        <>
            <h1>{t('admin:clients.create_client_view.title')}</h1>
            {saveStatus.isLoading && <Spinner />}
            {saveStatus.isSuccess && (
                <Notification type="success">{res.message}</Notification>
            )}
            {saveStatus.isError && (
                <Notification type="error">
                    <p>
                        {t('admin:clients.create_client_view.failed_to_create')}
                    </p>
                    <p>{err.message}</p>
                    {Object.values(err.validation).map((el, index) => (
                        <p key={index}>{el}</p>
                    ))}
                </Notification>
            )}
            <Row>
                <Col xs="12" lg="6">
                    <Form
                        initialValues={INITIAL_VALUES}
                        error={err}
                        method="POST"
                        className={cx({
                            'd-none': saveStatus.isLoading,
                        })}
                        onSubmit={handleSubmit}
                    >
                        {({ data: formData }) => (
                            <>
                                <Row>
                                    <Col xs="12" md="6">
                                        <Form.Field
                                            label={trans('name')}
                                            name="name"
                                            required
                                        />
                                    </Col>
                                    <Col xs="12" md="6">
                                        <Form.Field
                                            label={trans('email')}
                                            type="email"
                                            name="email"
                                            required
                                        />
                                    </Col>
                                    <Col xs="12" md="6">
                                        <Form.Field
                                            label={trans('street')}
                                            name="street"
                                            required
                                        />
                                    </Col>
                                    <Col xs="12" md="6">
                                        <Form.Field
                                            label={trans('street_number')}
                                            name="street_number"
                                            required
                                        />
                                    </Col>
                                    <Col xs="12" md="6">
                                        <Form.Field
                                            label={trans('apartment')}
                                            name="apartment"
                                        />
                                    </Col>
                                    <Col xs="12" md="6">
                                        <Form.Field
                                            label={trans('postcode')}
                                            name="postcode"
                                            required
                                        />
                                    </Col>
                                    <Col xs="12" md="6">
                                        <Form.Field
                                            label={trans('city')}
                                            name="city"
                                            required
                                        />
                                    </Col>
                                    <Col xs="12" md="6">
                                        <Form.Field
                                            label={trans('country_code')}
                                            name="country_code"
                                            type="Select"
                                            showDefaultOption
                                            required
                                            options={countries}
                                        />
                                    </Col>
                                </Row>
                                <Row className={cx('align-items-end')}>
                                    <Col xs="12" md="6">
                                        <Form.Field
                                            type="SingleDropdownInput"
                                            required
                                            helpText={t(
                                                'common:client_profile.client_type.help'
                                            )}
                                            label={t(
                                                'common:client_profile.client_type.title'
                                            )}
                                            options={CLIENT_TYPE_ADMIN_OPTIONS(
                                                t
                                            )}
                                            name="client_type"
                                        />
                                    </Col>
                                    <Col xs="12" md="6">
                                        <Form.Field
                                            name="vat_id"
                                            required={
                                                formData.client_type ===
                                                    CLIENT_TYPE_BUSINESS &&
                                                vatCountries.includes(
                                                    formData.country_code
                                                )
                                            }
                                            disabled={
                                                formData.client_type !==
                                                    CLIENT_TYPE_BUSINESS ||
                                                !vatCountries.includes(
                                                    formData.country_code
                                                )
                                            }
                                            helpText={trans('vat_id_help')}
                                            label={trans('vat_id')}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" md="6">
                                        <Form.Field
                                            label={trans('dev_urls_number')}
                                            name="dev_urls_number"
                                            type="number"
                                            min={1}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" md="6">
                                        <Form.Field
                                            type="Toggle"
                                            name="new_user"
                                            inlineLabel={t(
                                                'admin:field_labels.client.new_user'
                                            )}
                                        />
                                    </Col>
                                </Row>
                                {!!formData.new_user && (
                                    <>
                                        <h4>
                                            {t(
                                                'admin:clients.create_client_view.new_user_title'
                                            )}
                                        </h4>
                                        <Row>
                                            <Col xs="12" md="6">
                                                <Form.Field
                                                    label={t(
                                                        'admin:field_labels.user.full_name'
                                                    )}
                                                    name="user_full_name"
                                                    required
                                                />
                                            </Col>
                                            <Col xs="12" md="6">
                                                <Form.Field
                                                    label={t(
                                                        'admin:field_labels.user.display_name'
                                                    )}
                                                    name="user_display_name"
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <Form.Field
                                                    type="email"
                                                    label={t(
                                                        'admin:field_labels.user.email'
                                                    )}
                                                    name="user_email"
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12" md="6">
                                                <Form.Field
                                                    type="password"
                                                    label={t(
                                                        'admin:field_labels.user.password'
                                                    )}
                                                    name="user_password"
                                                    required
                                                />
                                            </Col>
                                            <Col xs="12" md="6">
                                                <Form.Field
                                                    type="password"
                                                    label={t(
                                                        'admin:field_labels.user.password_confirmation'
                                                    )}
                                                    name="user_password_confirmation"
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                )}
                                <Form.Submit icon="md/ic_save">
                                    {t('common:buttons.save')}
                                </Form.Submit>
                            </>
                        )}
                    </Form>
                </Col>
            </Row>
        </>
    );
};

export default CreateClientView;
