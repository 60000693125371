import React, { useState } from 'react';

import AsyncDropdown from 'components/AsyncDropdown';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import classNames from 'helpers/classNames';
import styles from './SelectClient.scss';
import useApiNamespace from 'hooks/useApiNamespace';
import useClient from 'hooks/useClient';
import useListingLoadMore from 'hooks/useListingLoadMore';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const SelectClient = ({
    placeholder = '',
    invalid = false,
    disabled = false,
    onChange = _noop,
    toggleClassName = '',
    value,
    ...props
}) => {
    const { t } = useTranslation();
    const apiNS = useApiNamespace();
    const [selectedClientUuid] = (
        Array.isArray(value) ? value : [value]
    ).filter(Boolean);
    const [searchValue, setSearchValue] = useState('');
    const [clients, fetchMeta] = useListingLoadMore(
        `${apiNS}/clients`,
        { search: searchValue },
        !disabled // no need to load not selected options if input is disabled
    );

    const [selectedClient, selectedFetchMeta] = useClient(selectedClientUuid);
    const fixSelectedClients =
        selectedClientUuid && selectedClient ? [selectedClient] : [];
    const isLoadingSelected =
        !!selectedClientUuid && selectedFetchMeta.isLoading;
    const placeholderLabel = isLoadingSelected
        ? t('common:common.loading')
        : placeholder;

    return (
        <AsyncDropdown
            {...props}
            className={cx('dropdown', 'form-control', {
                'is-invalid': invalid,
                disabled,
            })}
            disabled={disabled}
            searchable
            toggleClassName={cx(toggleClassName, 'custom-select')}
            onChoose={onChange}
            invalid={invalid}
            options={[
                ...fixSelectedClients,
                ...clients.data.filter(
                    (i) =>
                        selectedClientUuid !== i.uuid &&
                        !fixSelectedClients.find((s) => s.uuid === i.uuid)
                ),
            ].map((c) => ({
                value: c.uuid,
                label: c.name || c.email,
            }))}
            value={[selectedClientUuid]}
            isLoadingOptions={isLoadingSelected}
            isLoadingMore={fetchMeta.isLoadingMore}
            onSearch={(newSearch) => setSearchValue(newSearch)}
            renderLabel={(option) => (
                <span className={cx('value')}>{option.label}</span>
            )}
            onLoadMore={fetchMeta.hasMore ? fetchMeta.loadMore : null}
            renderMainLabel={() =>
                fixSelectedClients.length ? (
                    <span className={cx('value')}>
                        {fixSelectedClients[0].name}
                    </span>
                ) : (
                    placeholderLabel
                )
            }
        />
    );
};

SelectClient.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    placeholder: PropTypes.string,
    invalid: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    toggleClassName: PropTypes.string,
};

export default SelectClient;
