import React, { useCallback, useMemo } from 'react';

import { CustomInput } from 'reactstrap';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import classNames from 'helpers/classNames';
import styles from './Select.scss';

const cx = classNames(styles);

const defaultOptionValue = '';
const getInputvalue = ({ value, multiple, showDefaultOption }) => {
    if (multiple) {
        if (value && Array.isArray(value) && value.length) {
            return value;
        }
        return showDefaultOption ? [defaultOptionValue] : [];
    }

    if (value) {
        if (Array.isArray(value)) {
            return value[0];
        }
        return value;
    }
    return showDefaultOption ? defaultOptionValue : '';
};

const Select = ({
    options,
    multiple,
    onChange,
    allowDefaultOption,
    showDefaultOption,
    defaultOptionLabel,
    value,
    ...props
}) => {
    const id = useMemo(Math.random);
    const handleChange = useCallback(
        (e) => {
            const selectedValues = [...e.target.options]
                .filter((i) => i.selected)
                .map((i) => i.value || null);
            if (multiple) {
                onChange(selectedValues);
                return;
            }

            const newValue = selectedValues.length ? selectedValues[0] : null;
            onChange(newValue);
        },
        [onChange, options, multiple]
    );

    const inputValue = getInputvalue({ value, multiple, showDefaultOption });
    return (
        <CustomInput
            {...props}
            onChange={handleChange}
            id={`select-component-${id}`}
            type="select"
            multiple={multiple}
            className={cx()}
            value={inputValue}
        >
            {showDefaultOption && (
                <option value="" disabled={!allowDefaultOption}>
                    {defaultOptionLabel}
                </option>
            )}
            {options.map((option) => (
                <option
                    key={option.value}
                    value={option.value}
                    disabled={!!option.disabled}
                >
                    {option.label}
                </option>
            ))}
        </CustomInput>
    );
};

Select.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    multiple: PropTypes.bool,
    showDefaultOption: PropTypes.bool,
    allowDefaultOption: PropTypes.bool,
    defaultOptionLabel: PropTypes.node,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            label: PropTypes.node,
        })
    ),
    value: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string,
        PropTypes.number,
    ]),
};

Select.defaultProps = {
    className: '',
    onChange: _noop,
    multiple: false,
    showDefaultOption: false,
    allowDefaultOption: false,
    defaultOptionLabel: '---',
    options: [],
    value: undefined,
};

export default Select;
