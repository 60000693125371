import Icon from 'components/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import _noop from 'lodash/noop';
import classNames from 'helpers/classNames';
import styles from './ImagePickerPreview.scss';

const cx = classNames(styles);

const ImagePickerPreview = ({
    className,
    renderPreview,
    src,
    allowDelete,
    allowEdit,
    onEdit,
    onDelete,
}) => {
    return (
        <div
            className={cx(className, 'previewContainer', {
                empty: !src,
                allowEdit,
            })}
        >
            {renderPreview({ src })}
            <div className={cx('actions')}>
                {!!allowEdit && (
                    <button
                        type="button"
                        className={cx('editBtn')}
                        onClick={onEdit}
                    >
                        <Icon icon="md/ic_mode_edit" />
                    </button>
                )}
                {!!allowDelete && (
                    <button
                        type="button"
                        className={cx('deleteBtn')}
                        onClick={onDelete}
                    >
                        <Icon icon="md/ic_delete" />
                    </button>
                )}
            </div>
        </div>
    );
};

/* eslint-disable react/prop-types */
const defaultRenderPreview = ({ src }) => (
    <div
        className={cx('preview', { empty: !src })}
        style={{ backgroundImage: `url('${src}')` }}
    />
);
/* eslint-enable react/prop-types */

ImagePickerPreview.propTypes = {
    renderPreview: PropTypes.func,
    className: PropTypes.string,
    src: PropTypes.string,
    allowDelete: PropTypes.bool,
    allowEdit: PropTypes.bool,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
};

ImagePickerPreview.defaultProps = {
    renderPreview: defaultRenderPreview,
    className: '',
    src: '',
    invalid: false,
    allowDelete: false,
    allowEdit: false,
    onDelete: _noop,
    onEdit: _noop,
};

export default ImagePickerPreview;
