import React, { useCallback, useEffect } from 'react';

import API from 'helpers/api';
import Form from 'components/Form';
import InfoBarMessageFormFields from '../components/InfoBarMessageFormFields';
import Notification from 'components/Notification';
import classNames from 'helpers/classNames';
import styles from './CreateInfoBarMessageView.scss';
import { url } from 'core/Router';
import useApiNamespace from 'hooks/useApiNamespace';
import useExecuteRequest from 'hooks/useExecuteRequest';
import { useHistory } from 'react-router-dom';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const CreateInfoBarMessageView = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const notify = useNotifications();
    const namespace = useApiNamespace();
    const [save, saveRes, saveErr, saveMeta] = useExecuteRequest();
    const handleSubmit = useCallback(
        (data) => {
            save(API.post(`/${namespace}/info-bar-messages`, data));
        },
        [save]
    );
    const INITIAL_VALUES = {
        type: 'success',
        status: true,
    };

    useEffect(() => {
        if (saveMeta.isSuccess) {
            notify.success('Info Bar Message created.');
            history.push(url('admin.infoBarMessages'));
        }
    }, [saveRes, saveMeta.isSuccess]);
    return (
        <>
            <h1>{t('admin:bar.title')}</h1>
            {saveMeta.isError && (
                <Notification type="error">
                    <p>{t('admin:bar.error')}</p>
                    <p>{saveErr.message}</p>
                    {Object.values(saveErr.validation).map((el, index) => (
                        <p key={index}>{el}</p>
                    ))}
                </Notification>
            )}
            <Form
                initialValues={INITIAL_VALUES}
                error={saveErr}
                method="POST"
                isSubmitting={saveMeta.isLoading}
                onSubmit={handleSubmit}
            >
                <InfoBarMessageFormFields />
            </Form>
        </>
    );
};

export default CreateInfoBarMessageView;
