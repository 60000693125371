import React, { useCallback, useEffect } from 'react';

import API from 'helpers/api';
import EditSiteGroupForm from 'views/common/site-groups/components/EditSiteGroupForm';
import Spinner from 'components/Spinner';
import _get from 'lodash/get';
import classNames from 'helpers/classNames';
import useExecuteRequest from 'hooks/useExecuteRequest';
import useNotifications from 'hooks/useNotifications';
import { useParams } from 'react-router-dom';
import useRequest from 'hooks/useRequest';
import { useTranslation } from 'react-i18next';

const cx = classNames();

const EditGroupView = () => {
    const { t } = useTranslation();
    const notify = useNotifications();
    const { group: groupUuid } = useParams();
    const apiUrl = `/common/site-groups/${groupUuid}`;
    const [group, fetchErr, fetchState, reload] = useRequest(API.get(apiUrl));
    const [request, res, err, saveState] = useExecuteRequest();

    const handleSubmit = useCallback(
        (form) => request(API.put(apiUrl, form)),
        [request]
    );

    useEffect(() => {
        if (fetchState.isError) {
            notify.error(fetchErr.message);
        }
    }, [fetchState.isError]);
    useEffect(() => {
        if (saveState.isSuccess) {
            notify.success(t('common:site_groups.common.saved'));
            reload();
        }
        if (saveState.isError) {
            notify.error(err.message);
        }
    }, [saveState.isError, saveState.isSuccess]);

    return (
        <>
            <h1>{t('common:site_groups.common.edit')}</h1>
            {saveState.isLoading && <Spinner />}
            {!group && <Spinner />}
            {!!group && (
                <div className={cx({ 'd-none': saveState.isLoading })}>
                    <EditSiteGroupForm
                        onSubmit={handleSubmit}
                        error={err}
                        group={group}
                        initialValues={{
                            ...group,
                            sites: group.sites.map((i) => i.uuid),
                            used_cookie_categories: _get(
                                group,
                                'sites[0].used_cookie_categories'
                            ),
                            performance_enabled_default: _get(
                                group,
                                'sites[0].performance_enabled_default'
                            ),
                            functional_enabled_default: _get(
                                group,
                                'sites[0].functional_enabled_default'
                            ),
                            advertising_enabled_default: _get(
                                group,
                                'sites[0].advertising_enabled_default'
                            ),
                            performance_executed_default: _get(
                                group,
                                'sites[0].performance_executed_default'
                            ),
                            functional_executed_default: _get(
                                group,
                                'sites[0].functional_executed_default'
                            ),
                            advertising_executed_default: _get(
                                group,
                                'sites[0].advertising_executed_default'
                            ),
                            consent_expires_after: _get(
                                group,
                                'sites[0].consent_expires_after'
                            ),
                            consent_policy: _get(
                                group,
                                'sites[0].consent_policy'
                            ),
                            autoblock_known: _get(
                                group,
                                'sites[0].autoblock_known'
                            ),
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default EditGroupView;
