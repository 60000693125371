import { Col, Row } from 'reactstrap';
import React, { useCallback, useEffect } from 'react';

import API from 'helpers/api';
import Button from 'components/Button';
import DevUrl from 'components/DevUrl';
import Form from 'components/Form';
import Site from 'models/Site';
import _get from 'lodash/get';
import classNames from 'helpers/classNames';
import { clearCache as clearPlansCache } from 'hooks/usePlans';
import { url } from 'core/Router';
import useClients from 'hooks/useClients';
import useExecuteRequest from 'hooks/useExecuteRequest';
import { useHistory } from 'react-router-dom';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';

const cx = classNames();
const INITIAL_VALUES = {
    name: '',
    base_url: '',
    privacy_policy_url: '',
    additional_link: '',
    dev_urls: [''],
    plan: null,
    widget_languages: ['en'],
    used_cookie_categories: ['necessary'],
};
const CreateSiteView = () => {
    const { t } = useTranslation();
    const notify = useNotifications();
    const history = useHistory();
    const [request, res, err, creationState] = useExecuteRequest();
    const [clients] = useClients({ page: 1, per_page: -1 });

    const handleSubmit = useCallback(
        (form) => {
            request(
                API.post('/admin/sites', {
                    ...form,
                    plan_uuid: form.plan_uuid ? form.plan_uuid.uuid : null,
                    client_uuid: Array.isArray(form.client_uuid)
                        ? form.client_uuid[0]
                        : form.client_uuid,
                })
            );
        },
        [request]
    );

    useEffect(() => {
        if (creationState.isSuccess) {
            notify.success(t('common:create_site_view.domain_created'));
            clearPlansCache();
            history.push(url('admin.sites'));
        }
        if (creationState.isError) {
            notify.error(err.message);
        }
    }, [creationState.isError, creationState.isSuccess]);
    return (
        <>
            <h1>{t('common:create_site_view.title')}</h1>

            <Form
                isSubmitting={creationState.isLoading}
                initialValues={INITIAL_VALUES}
                error={err}
                onSubmit={handleSubmit}
            >
                {({ data: formData, handleChange }) => {
                    const selectedClientUuid = Array.isArray(
                        formData.client_uuid
                    )
                        ? formData.client_uuid[0]
                        : formData.client_uuid;
                    const selectedClient = selectedClientUuid
                        ? clients.data.find(
                              (x) => x.uuid === selectedClientUuid
                          )
                        : false;
                    const newSite = new Site(formData);
                    const addDevUrl = () => {
                        handleChange('dev_urls', [
                            ...(formData.dev_urls || []),
                            '',
                        ]);
                    };
                    const resetDevUrls = () => {
                        handleChange('dev_urls', ['']);
                    };

                    const removeDevUrl = (index) => {
                        const urls = [...(formData.dev_urls || [])];
                        handleChange(
                            'dev_urls',
                            urls.filter((url, i) => i !== index)
                        );
                    };
                    return (
                        <Row>
                            <Col xs="12" lg="8">
                                <Row>
                                    <Col xs="12" md="6">
                                        <Form.Field
                                            label={t(
                                                'admin:field_labels.site.name'
                                            )}
                                            name="name"
                                            required
                                        />
                                    </Col>
                                    <Col xs="12" md="6">
                                        <Form.Field
                                            label={t(
                                                'admin:field_labels.site.client_uuid'
                                            )}
                                            type="DropdownInput"
                                            searchable
                                            name="client_uuid"
                                            required
                                            showDefaultOption
                                            disabled={!clients.data.length}
                                            onChange={() => resetDevUrls()}
                                            defaultOptionLabel={
                                                clients.data.length
                                                    ? t(
                                                          'common:common.select_owner'
                                                      )
                                                    : t('common:common.loading')
                                            }
                                            options={clients.data.map(
                                                (client) => ({
                                                    value: client.uuid,
                                                    label: client.name,
                                                })
                                            )}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" md="6">
                                        <Form.Field
                                            label={t(
                                                'admin:field_labels.site.plan'
                                            )}
                                            type="ChooseSiteSubscriptionPlan"
                                            name="plan_uuid"
                                            disabled={!formData.client_uuid}
                                            clientUuid={formData.client_uuid}
                                            trialEndsAt={_get(
                                                selectedClient,
                                                'trial_ends_at'
                                            )}
                                            required
                                        />
                                    </Col>
                                    <Col xs="12" md="6">
                                        <Form.Field
                                            label={t(
                                                'admin:field_labels.site.base_url'
                                            )}
                                            placeholder="https://yourdomain.com"
                                            type="url"
                                            name="base_url"
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" md="6">
                                        <Form.Field
                                            label={t(
                                                'admin:field_labels.site.privacy_policy_url'
                                            )}
                                            name="privacy_policy_url"
                                            placeholder="https://yourdomain.com/privacy-policy"
                                            type="url"
                                            required
                                        />
                                    </Col>
                                    <Col xs="12" md="6">
                                        {selectedClient &&
                                            formData.dev_urls.map(
                                                (item, index) => {
                                                    return (
                                                        <DevUrl
                                                            key={index}
                                                            index={index}
                                                            showLabelSuffix={
                                                                false
                                                            }
                                                            onRemoveDevUrl={() =>
                                                                removeDevUrl(
                                                                    index
                                                                )
                                                            }
                                                        />
                                                    );
                                                }
                                            )}
                                        {selectedClient.dev_urls_number >
                                            formData.dev_urls.length && (
                                            <Button
                                                icon="md/ic_add_box"
                                                className={cx('ml-1', 'mb-2')}
                                                onClick={() => {
                                                    addDevUrl();
                                                }}
                                            />
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" md="6">
                                        <Form.Field
                                            name="additional_link"
                                            placeholder="https://yourdomain.com/additional-link"
                                            label={t(
                                                'common:site_basic_settings_tab.additional_link'
                                            )}
                                        />
                                    </Col>
                                    <Col xs="12" md="6">
                                        <Form.Field
                                            label={t(
                                                'admin:field_labels.site.widget_languages'
                                            )}
                                            placeholder={t(
                                                'admin:field_labels.site.select_language'
                                            )}
                                            name="widget_languages"
                                            required
                                            disabled={!formData.plan}
                                            type="LanguageDropdown"
                                            multiple={
                                                newSite.getPlanFeature(
                                                    'lang_limit'
                                                ) === false
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Form.Submit icon="md/ic_save">
                                    {t('common:buttons.save')}
                                </Form.Submit>
                            </Col>
                        </Row>
                    );
                }}
            </Form>
        </>
    );
};

export default CreateSiteView;
