import Site from 'models/Site';
import useApiNamespace from 'hooks/useApiNamespace';
import useListing from 'hooks/useListing';

const useSites = (params, execute = true) => {
    const namespace = useApiNamespace();
    const [res, ...other] = useListing(`${namespace}/sites`, params, execute);
    const response = {
        ...res,
        data: res.data.map((i) => new Site(i)),
    };

    return [response, ...other];
};

export default useSites;
