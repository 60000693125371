import React, { useState } from 'react';

import Button from 'components/Button';
import ConfigureConfigRegeneration from '../ConfigureConfigRegeneration';
import PropTypes from 'prop-types';
import classNames from 'helpers/classNames';
import styles from './RegenerateConfigForAllSites.scss';

const cx = classNames(styles);

const RegenerateConfigForAllSites = ({ ...props }) => {
    const [isConfigureModalOpen, setIsConfigureModalOpen] = useState(false);

    return (
        <>
            <Button
                {...props}
                icon="md/ic_autorenew"
                label="Regenerate configs"
                onClick={() => setIsConfigureModalOpen(true)}
            />
            <ConfigureConfigRegeneration
                isOpen={isConfigureModalOpen}
                onClose={() => setIsConfigureModalOpen(false)}
            />
        </>
    );
};

RegenerateConfigForAllSites.propTypes = {};

RegenerateConfigForAllSites.defaultProps = {};

export default RegenerateConfigForAllSites;
