import _get from 'lodash/get';
import dayjs from 'helpers/dayjs';
import { useMemo } from 'react';

const useCustomersChart = (apiResponse = {}) => {
    const chartData = useMemo(() => {
        const data = {
            series: [
                {
                    name: 'Clients',
                    data: [],
                },
                {
                    name: 'Sites',
                    data: [],
                },
            ],
            options: {
                chart: {
                    height: 350,
                    fontFamily: 'Noto Sans, sans-serif',
                    toolbar: {
                        show: false,
                    },
                },
                grid: {
                    padding: {
                        top: 20,
                        left: 60,
                        right: 20,
                    },
                },
                stroke: {
                    curve: 'smooth',
                },
                xaxis: {
                    categories: [],
                },
                dataLabels: {
                    enabled: false,
                },
                tooltip: {
                    x: false,
                },
            },
        };
        if (!apiResponse) {
            return data;
        }

        const sets = ['clients', 'sites'];
        for (let i = 29; i >= 0; i--) {
            const date = dayjs().subtract(i, 'day').format('YYYY-MM-DD');
            data.options.xaxis.categories.push(date);

            for (const set of sets) {
                const index = sets.indexOf(set);
                const value = _get(apiResponse, `${set}.${date}.count`) || 0;
                data.series[index].data.push(value);
            }
        }

        return data;
    }, [apiResponse]);

    return chartData;
};

export default useCustomersChart;
