import { Col, Row } from 'reactstrap';
import EditSiteGroupFormFields from '../EditSiteGroupFormFields';
import Form from 'components/Form';
import PropTypes from 'prop-types';
import React from 'react';
import _get from 'lodash/get';
import _noop from 'lodash/noop';
import useAppState from 'hooks/useAppState';
import { useClientPolicy } from 'policies/ClientPolicy';

const INITIAL_VALUES = {
    name: '',
    bulk_consent_enabled: false,
    sites: [],
    search: '',
    autoblock_known: false,
};

const EditSiteGroupForm = ({ initialValues, group, onSubmit, error }) => {
    const { user } = useAppState();
    const canSelectOwner = useClientPolicy('viewAny');

    const handleSubmit = (formData) => {
        const data = { ...formData };
        if (!canSelectOwner) {
            data.client_uuid = _get(user, 'client.uuid');
        }

        onSubmit(data);
    };
    return (
        <Form
            initialValues={{
                ...INITIAL_VALUES,
                client_uuid: canSelectOwner ? null : user.client_uuid,
                ...initialValues,
            }}
            error={error}
            onSubmit={handleSubmit}
        >
            <Row>
                <Col xs="12" xl="10">
                    <EditSiteGroupFormFields
                        group={group}
                        canSelectOwner={canSelectOwner}
                    />
                </Col>
            </Row>
        </Form>
    );
};

EditSiteGroupForm.propTypes = {
    initialValues: PropTypes.object,
    group: PropTypes.object,
    onSubmit: PropTypes.func,
    error: PropTypes.instanceOf(Error),
};

EditSiteGroupForm.defaultProps = {
    initialValues: {},
    group: {},
    onSubmit: _noop,
    error: null,
};

export default EditSiteGroupForm;
