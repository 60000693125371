import React, { useCallback, useEffect, useState } from 'react';

import API from 'helpers/api';
import ChoosePlanModal from 'components/ChoosePlanModal/ChoosePlanModal';
import PropTypes from 'prop-types';
import Site from 'models/Site';
import SiteListItem from './SiteListItem';
import _get from 'lodash/get';
import useApiNamespace from 'hooks/useApiNamespace';
import useAppState from 'hooks/useAppState';
import useExecuteRequest from 'hooks/useExecuteRequest';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';

const SiteListItemContainer = ({ item, noDelete, allowUpgrade, ...props }) => {
    const site = item;
    const { user } = useAppState();
    const [deletedSiteUuid, setDeletedSiteUuid] = useState(null);
    const [scannedSiteUuid, setScannedSiteUuid] = useState(null);
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const notify = useNotifications();

    const { t } = useTranslation();
    const urlNamespace = user.is_admin ? 'admin' : 'dash';
    const apiNamespace = useApiNamespace();
    const [del, , delErr, delMeta] = useExecuteRequest();
    const [upgrade, , error, upgradeState] = useExecuteRequest();
    const [startScan, , startScanErr, startScanMeta] = useExecuteRequest();
    const handleDelete = (site) => {
        setDeletedSiteUuid(site.uuid);
        const monit = t('common:site_list_item.are_you_sure_delete_site', {
            name: site.getDisplayName(t),
            url: site.base_url,
        });
        if (!confirm(monit)) {
            return;
        }

        del(API.del(`${apiNamespace}/sites/${site.uuid}`));
    };

    const handlePlanChosen = useCallback(
        (plan) => {
            const url = `/client/sites/${site.uuid}/change-plan`;
            upgrade(API.put(url, { plan_uuid: plan.uuid }));
        },
        [upgrade, site]
    );

    const handleManualScanStart = (site) => {
        setScannedSiteUuid(site.uuid);
        startScan(API.post(`${apiNamespace}/sites/${site.uuid}/manual-scan`));
    };

    useEffect(() => {
        if (startScanMeta.isSuccess) {
            notify.success(t('common:site_list_item.scan_started'));
        }
        if (startScanMeta.isError) {
            notify.error(startScanErr.message);
        }
    }, [startScanMeta.isSuccess, startScanMeta.isError]);

    useEffect(() => {
        if (delMeta.isSuccess) {
            notify.success(t('common:site_list_item.site_deleted'));
        }
        if (delMeta.isError) {
            notify.error(delErr.message);
        }
    }, [delMeta.isSuccess, delMeta.isError]);

    useEffect(() => {
        if (upgradeState.isSuccess) {
            notify.success(t('common:common.saved'));
            window.location.reload();
        }
        if (upgradeState.isError) {
            notify.error(error.message);
        }
    }, [upgradeState.isSuccess, upgradeState.isError]);

    if (delMeta.isSuccess) {
        return null;
    }

    return (
        <>
            <SiteListItem
                {...props}
                item={site}
                onDelete={noDelete ? undefined : handleDelete}
                onStartManualScan={handleManualScanStart}
                isDeleting={site.uuid === deletedSiteUuid && delMeta.isLoading}
                isStartingManualScan={
                    site.uuid === scannedSiteUuid && startScanMeta.isLoading
                }
                isUpgrading={allowUpgrade ? upgradeState.isLoading : false}
                onUpgrade={
                    allowUpgrade ? () => setShowUpgradeModal(true) : undefined
                }
                urlNamespace={urlNamespace}
            />
            {!!showUpgradeModal && (
                <ChoosePlanModal
                    title={t('client:sites.sites_list_view.change_plan')}
                    onClose={() => setShowUpgradeModal(false)}
                    siteUuid={site.uuid}
                    isModalOpen
                    trialEndsAt={_get(site, 'client.trial_ends_at')}
                    isLoading={upgradeState.isLoading}
                    onPlanChosen={handlePlanChosen}
                />
            )}
        </>
    );
};

SiteListItemContainer.propTypes = {
    item: PropTypes.instanceOf(Site),
    noDelete: PropTypes.bool,
    allowUpgrade: PropTypes.bool,
};

export default SiteListItemContainer;
