import { Prompt, useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Button from 'components/Button';
import Modal from 'components/Modal';
import PropTypes from 'prop-types';
import { hidePreloader } from 'helpers/mainPreloader';
import useTranslate from 'hooks/useTranslate';

const RouteLeavingGuard = ({ when, navigate, shouldBlockNavigation }) => {
    const [trans] = useTranslate('common:leaving_guard');
    const history = useHistory();
    const Location = history.location;
    const [modalVisible, setModalVisible] = useState(false);
    const [lastLocation, setLastLocation] = useState(Location || null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    const closeModal = () => {
        setModalVisible(false);
    };
    const handleBlockedNavigation = (nextLocation) => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            setModalVisible(true);
            setLastLocation(nextLocation);
            return false;
        }

        return true;
    };
    const handleConfirmNavigationClick = () => {
        setModalVisible(false);
        setConfirmedNavigation(true);
    };

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            navigate(lastLocation.pathname);
        }
    }, [confirmedNavigation, lastLocation]);

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser);
        return () => {
            window.removeEventListener('beforeunload', alertUser);
        };
    }, [when]);

    const alertUser = (e) => {
        if (when) {
            e.preventDefault();
            e.returnValue = trans('description');
            hidePreloader();
        }
    };

    return (
        <>
            <Prompt when={when} message={handleBlockedNavigation} />
            <Modal
                size="md"
                title={trans('title')}
                isOpen={modalVisible}
                onClose={closeModal}
            >
                <Modal.Body>
                    <p>{trans('description')}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="button"
                        color="primary"
                        label={trans('continue_editing')}
                        onClick={closeModal}
                    />
                    <Button
                        outline
                        color="warning"
                        label={trans('confirm_leaving')}
                        onClick={handleConfirmNavigationClick}
                    />
                </Modal.Footer>
            </Modal>
        </>
    );
};

RouteLeavingGuard.propTypes = {
    when: PropTypes.bool,
    navigate: PropTypes.func,
    shouldBlockNavigation: PropTypes.func,
};

RouteLeavingGuard.defaultProps = {
    when: undefined,
};

export default RouteLeavingGuard;
