import AnimatedList from 'components/AnimatedList/AnimatedList';
import Form from 'components/Form';
import GroupListItem from '../components/GroupListItem';
import Link from 'components/Link';
import Pagination from 'components/Pagination';
import PropTypes from 'prop-types';
import React from 'react';
import { Row } from 'reactstrap';
import SearchField from 'components/SearchField';
import Spinner from 'components/Spinner';
import classNames from 'helpers/classNames';
import styles from './GroupsListView.scss';
import { url } from 'core/Router';
import useAppState from 'hooks/useAppState';
import useListing from 'hooks/useListing';
import { useTranslation } from 'react-i18next';
import useUrlQuery from 'hooks/useUrlQuery';

const cx = classNames(styles);

const GroupsListView = () => {
    const { t } = useTranslation();
    const { user } = useAppState();
    const [query] = useUrlQuery();
    const [list, fetchMeta] = useListing('/common/site-groups', query);
    return (
        <>
            <div className={cx('mb-3')}>
                <h1 className={cx('mb-3')}>
                    {t('common:site_groups.list.title')}
                </h1>
                <h2 className={cx('h5', 'font-weight-normal', 'mb-3')}>
                    {t('common:site_groups.list.intro')}
                </h2>
                <Row className={cx('align-items-center', 'my-4')}>
                    <div className={cx('px-3')}>
                        <Link
                            button
                            icon="md/ic_add"
                            to={url(
                                user.is_admin
                                    ? 'admin.siteGroups.create'
                                    : 'dash.siteGroups.create'
                            )}
                        >
                            {t('common:site_groups.common.create')}
                        </Link>
                    </div>
                    <Form
                        className={cx('col-lg-4', 'mt-lg-0', 'mt-4')}
                        initialValues={{ search: '' }}
                        onSubmit={() => null}
                    >
                        <div
                            className={cx(
                                'inline-search',
                                'd-lg-flex',
                                'align-items-center'
                            )}
                        >
                            <SearchField
                                label={t('common:site_groups.common.search')}
                            />
                        </div>
                    </Form>
                </Row>
            </div>
            {fetchMeta.isLoading && <Spinner />}
            {!fetchMeta.isLoading && fetchMeta.isSuccess && (
                <>
                    <AnimatedList
                        list={list.data}
                        itemComponent={GroupListItem}
                        itemKey="uuid"
                    />

                    <Pagination
                        className={cx('mt-3')}
                        currentPage={list.current_page}
                        lastPage={list.last_page}
                    />
                </>
            )}
        </>
    );
};

GroupsListView.propTypes = {};

GroupsListView.defaultProps = {};

export default GroupsListView;
