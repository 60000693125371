import { Col, Row } from 'reactstrap';
import React, { useCallback, useEffect, useState } from 'react';

import API from 'helpers/api';
import Form from 'components/Form';
import Notification from 'components/Notification';
import Spinner from 'components/Spinner';
import classNames from 'helpers/classNames';
import { countries } from 'constants/geoData';
import styles from './CreateClientView.scss';
import { url } from 'core/Router';
import useExecuteRequest from 'hooks/useExecuteRequest';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const INITIAL_VALUES = {
    name: '',
    vat_id: '',
    email: '',
    street: '',
    street_number: '',
    apartment: '',
    postcode: '',
    city: '',
    country_code: '',
    new_user: false,
    user_full_name: '',
    user_display_name: '',
    user_email: '',
    user_password: '',
    user_password_confirmation: '',
};

const CreateClientView = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [request, res, err, saveStatus] = useExecuteRequest();
    const handleSubmit = useCallback(
        (data) => {
            const { new_user } = data;

            if (!new_user) {
                data = {
                    new_user: new_user,
                    apartment: data.apartment,
                    city: data.city,
                    country_code: data.country_code,
                    email: data.email,
                    name: data.name,
                    postcode: data.postcode,
                    street: data.street,
                    street_number: data.street_number,
                    vat_id: data.vat_id,
                };
            }
            request(API.post('/client/clients', data));
        },
        [request]
    );

    const [isCreatingNewUser, setIsCreatingNewUser] = useState(false);

    const handleCheckboxChange = (name, value) => {
        setIsCreatingNewUser(value);
    };

    // redirect to new client after success
    useEffect(() => {
        if (!saveStatus.isSuccess) {
            return;
        }

        history.push(url('dash.clients.edit', { client: res.client }));
    }, [res, saveStatus.isSuccess]);
    return (
        <>
            <h1>{t('client:clients.create_client_view.title')}</h1>
            {saveStatus.isSuccess && (
                <Notification type="success">{res.message}</Notification>
            )}
            {saveStatus.isError && (
                <Notification type="error">
                    <p>
                        {t(
                            'client:clients.create_client_view.failed_to_create'
                        )}
                    </p>
                    <p>{err.message}</p>
                    {Object.values(err.validation).map((el, index) => (
                        <p key={index}>{el}</p>
                    ))}
                </Notification>
            )}
            <Form
                error={err}
                initialValues={INITIAL_VALUES}
                method="POST"
                isSubmitting={saveStatus.isLoading}
                onSubmit={handleSubmit}
            >
                <Row>
                    <Col xs="12" lg="6">
                        <Row>
                            <Col xs="12" md="6">
                                <Form.Field
                                    label={t(
                                        'common:edit_client_view.fields.name'
                                    )}
                                    name="name"
                                    required
                                />
                            </Col>
                            <Col xs="12" md="6">
                                <Form.Field
                                    label={t(
                                        'common:edit_client_view.fields.vat_id'
                                    )}
                                    name="vat_id"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" md="6">
                                <Form.Field
                                    label={t(
                                        'common:edit_client_view.fields.email'
                                    )}
                                    type="email"
                                    name="email"
                                    required
                                />
                            </Col>
                            <Col xs="12" md="6">
                                <Form.Field
                                    label={t(
                                        'common:edit_client_view.fields.street'
                                    )}
                                    name="street"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" md="6">
                                <Form.Field
                                    label={t(
                                        'common:edit_client_view.fields.street_number'
                                    )}
                                    name="street_number"
                                />
                            </Col>
                            <Col xs="12" md="6">
                                <Form.Field
                                    label={t(
                                        'common:edit_client_view.fields.apartment'
                                    )}
                                    name="apartment"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" md="6">
                                <Form.Field
                                    label={t(
                                        'common:edit_client_view.fields.postcode'
                                    )}
                                    name="postcode"
                                />
                            </Col>
                            <Col xs="12" md="6">
                                <Form.Field
                                    label={t(
                                        'common:edit_client_view.fields.city'
                                    )}
                                    name="city"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" md="6">
                                <Form.Field
                                    label={t(
                                        'common:edit_client_view.fields.country_code'
                                    )}
                                    name="country_code"
                                    type="Select"
                                    showDefaultOption
                                    options={countries}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" md="6">
                                <Form.Field
                                    type="Toggle"
                                    name="new_user"
                                    inlineLabel={t(
                                        'client:field_labels.client.new_user'
                                    )}
                                    onChange={handleCheckboxChange}
                                />
                            </Col>
                        </Row>
                        {isCreatingNewUser && (
                            <>
                                <h4>
                                    {t(
                                        'client:clients.create_client_view.new_user_title'
                                    )}
                                </h4>
                                <Row>
                                    <Col xs="12" md="6">
                                        <Form.Field
                                            label={t(
                                                'client:field_labels.user.full_name'
                                            )}
                                            name="user_full_name"
                                            required
                                        />
                                    </Col>
                                    <Col xs="12" md="6">
                                        <Form.Field
                                            label={t(
                                                'client:field_labels.user.display_name'
                                            )}
                                            name="user_display_name"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <Form.Field
                                            type="email"
                                            label={t(
                                                'client:field_labels.user.email'
                                            )}
                                            name="user_email"
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" md="6">
                                        <Form.Field
                                            type="password"
                                            label={t(
                                                'client:field_labels.user.password'
                                            )}
                                            name="user_password"
                                            required
                                        />
                                    </Col>
                                    <Col xs="12" md="6">
                                        <Form.Field
                                            type="password"
                                            label={t(
                                                'client:field_labels.user.password_confirmation'
                                            )}
                                            name="user_password_confirmation"
                                            required
                                        />
                                    </Col>
                                </Row>
                            </>
                        )}
                        <Form.Submit icon="md/ic_save">
                            {t('common:buttons.save')}
                        </Form.Submit>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default CreateClientView;
