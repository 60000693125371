import { Col } from 'reactstrap';
import Form from 'components/Form';
import PropTypes from 'prop-types';
import React from 'react';
import _get from 'lodash/get';
import classNames from 'helpers/classNames';
import styles from './CookieDataFormFields.scss';
import useCookieCategories from 'hooks/useCookieCategories';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const CookieDataFormFields = () => {
    const { t } = useTranslation();
    const [cookieCategories] = useCookieCategories();
    return (
        <>
            <Form.Group>
                <h2 className={cx('h5', 'border-primary')}>
                    {t(
                        'admin:cookies.cookies_list_view.cookie_data_form_fields.title'
                    )}
                </h2>
                <Form.Row>
                    <Col sm="12">
                        <Form.Field
                            type="Select"
                            label={t('admin:field_labels.cookie.category')}
                            required
                            showDefaultOption
                            defaultOptionLabel={t(
                                'admin:cookies.cookies_list_view.cookie_data_form_fields.select_category'
                            )}
                            name="category"
                            options={cookieCategories}
                        />
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col sm="12">
                        <Form.Field
                            type="textarea"
                            rows={2}
                            name="goal"
                            label={t('admin:field_labels.cookie.goal')}
                        />
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col sm="12">
                        <Form.Field
                            name="q1"
                            label={t('admin:field_labels.cookie.q1')}
                        />
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col sm="12">
                        <Form.Field
                            name="q2"
                            label={t('admin:field_labels.cookie.q2')}
                        />
                    </Col>
                </Form.Row>
                {/* <Form.Row>
                    <Col sm="12">
                        <Form.Field
                            name="q3"
                            label={t('admin:field_labels.cookie.q3')}
                        />
                    </Col>
                </Form.Row> */}
            </Form.Group>
        </>
    );
};

CookieDataFormFields.propTypes = {};

CookieDataFormFields.defaultProps = {};

export default CookieDataFormFields;
