import React, { useCallback, useMemo } from 'react';
import { CustomInput } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'helpers/classNames';
import styles from './FilePicker.scss';

const cx = classNames(styles);

const FilePicker = ({ value, onChange, multiple, ...props }) => {
    const inputId = useMemo(() => {
        return `file-input-${Math.random()}`;
    }, []);
    const handleChange = useCallback(
        (e) => {
            if (multiple) {
                onChange(Array.from(e.target.files));
            } else {
                onChange(e.target.files[0] || null);
            }
        },
        [onChange]
    );

    return (
        <CustomInput
            {...props}
            id={inputId}
            type="file"
            onChange={handleChange}
            multiple={multiple}
        />
    );
};

FilePicker.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    multiple: PropTypes.bool,
};

export default FilePicker;
