import { Alert, Col, Row } from 'reactstrap';

import Icon from 'components/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'helpers/classNames';
import styles from './InfoBox.scss';

const cx = classNames(styles);

const InfoBox = ({
    icon,
    children,
    color = 'primary',
    className = '',
    ...props
}) => (
    <Row>
        <Col xs="12" lg="6">
            <Alert color={color} className={cx(className, 'info-box')}>
                {!!icon && <Icon className={cx('mr-3')} icon={icon} />}
                {children}
            </Alert>
        </Col>
    </Row>
);

InfoBox.colors = ['primary', 'danger', 'warning'];

InfoBox.propTypes = {
    icon: PropTypes.string,
    color: PropTypes.oneOf([...InfoBox.colors]),
    className: PropTypes.string,
    children: PropTypes.node,
};

export default InfoBox;
