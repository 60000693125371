import { Col, Row } from 'reactstrap';

import AnimatedList from 'components/AnimatedList/AnimatedList';
import ClientListItem from 'partials/clients/ClientListItem';
import Link from 'components/Link';
import Pagination from 'components/Pagination';
import React from 'react';
import SearchField from 'components/SearchField';
import Spinner from 'components/Spinner';
import classNames from 'helpers/classNames';
import styles from './ClientListView.scss';
import { url } from 'core/Router';
import useClients from 'hooks/useClients';
import { useTranslation } from 'react-i18next';
import useUrlQuery from 'hooks/useUrlQuery';

const cx = classNames(styles);

const ClientsListView = () => {
    const { t } = useTranslation();
    const [query] = useUrlQuery();
    const [clients, fetchMeta, err, reload] = useClients({
        ...query,
        without_current: true,
    });

    return (
        <>
            <Row>
                <Col>
                    <h1 className={cx('mb-0')}>
                        {t('admin:clients.clients_list_view.title')}
                    </h1>
                </Col>
            </Row>
            <Row className={cx('my-3')}>
                <Col md="auto" xs="6" className={cx('pr-2, pr-md-3')}>
                    <Link
                        button
                        icon="md/ic_add"
                        className={cx('w-100')}
                        to={url('admin.clients.create')}
                    >
                        {t('admin:clients.clients_list_view.add_new')}
                    </Link>
                </Col>
                <Col md="auto" xs="6" className={cx('pl-2, pl-md-3')}>
                    <Link
                        button
                        className={cx('w-100')}
                        to={url('admin.clients.invite')}
                    >
                        {t('admin:clients.clients_list_view.invite_reseller')}
                    </Link>
                </Col>
                <Col xs="12" md="4" lg="3" className={cx('mt-3', 'mt-md-0')}>
                    <SearchField placeholder={t('common:common.search')} />
                </Col>
            </Row>
            {fetchMeta.isLoading && <Spinner />}
            {!fetchMeta.isLoading && fetchMeta.isSuccess && (
                <>
                    <AnimatedList
                        list={clients.data}
                        itemComponent={ClientListItem}
                        itemKey="uuid"
                        onDeleted={() => reload()}
                    />
                    <Pagination
                        className={cx('mt-3')}
                        currentPage={clients.current_page}
                        lastPage={clients.last_page}
                    />
                </>
            )}
        </>
    );
};

export default ClientsListView;
