import React, { useEffect } from 'react';

import API from 'helpers/api';
import Button from 'components/Button';
import Form from 'components/Form';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import PropTypes from 'prop-types';
import classNames from 'helpers/classNames';
import styles from './VerifyCodeModal.module.scss';
import useExecuteRequest from 'hooks/useExecuteRequest';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const VerifyCodeModal = ({ onClose, ...props }) => {
    const { t } = useTranslation();
    const notify = useNotifications();
    const [verify, resVer, errVer, verifyState] = useExecuteRequest();
    const trans = (key, ...args) => t(`guest:login_view.${key}`, ...args);
    const handleClose = () => {
        // don't allow closing when submit is running
        if (verifyState.isLoading) {
            return;
        }
        onClose();
    };

    const handleSubmit = (form) => verify(API.post('/token', form));

    useEffect(() => {
        if (verifyState.isSuccess) {
            window.location.href = '/';
        }
        if (verifyState.isError && errVer.status !== 422) {
            notify.error(errVer.message);
        }
    }, [verifyState.isSuccess, verifyState.isError, errVer]);

    return (
        <Modal
            {...props}
            size="md"
            title={
                <>
                    <Icon className={cx('mr-2')} icon="md/ic_lock_outline" />
                    {trans('enter_token')}
                </>
            }
            disabled={verifyState.isLoading}
            onClose={handleClose}
        >
            <Form
                initialValues={{ code: '' }}
                error={errVer}
                onSubmit={handleSubmit}
            >
                <Modal.Body>
                    <p>{trans('description')}</p>
                    <Form.Field
                        required
                        name="code"
                        type="string"
                        disabled={verifyState.isLoading}
                        placeholder={trans('enter_token_placeholder')}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Form.Submit
                        loading={verifyState.isLoading}
                        label={trans('submit')}
                    />
                    <Button
                        type="button"
                        color="link"
                        onClick={handleClose}
                        label={trans('cancel')}
                    />
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

VerifyCodeModal.propTypes = {
    onClose: PropTypes.func,
    onChange: PropTypes.func,
    loading: PropTypes.bool,
};

export default VerifyCodeModal;
