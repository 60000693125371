import ClientPolicy from 'policies/ClientPolicy';
import FormattedDate from 'components/FormattedDate';
import InfoBox from 'components/InfoBox';
import { Link } from 'react-router-dom';
import { PAYMENT_DETAILS_TAB } from 'views/common/account/Billing/tabs';
import PropTypes from 'prop-types';
import React from 'react';
import _get from 'lodash/get';
import classNames from 'helpers/classNames';
import dayjs from 'helpers/dayjs';
import styles from './ClientTrialExpirationInfo.scss';
import { url } from 'core/Router';
import useAppState from 'hooks/useAppState';
import { useMemo } from 'react';
import useTranslate from 'hooks/useTranslate';

const cx = classNames(styles);
const FALSE_SHOW_TRIAL_RESPONSE = [false, 0, Date.now()];

const ClientTrialExpirationInfo = () => {
    const { user } = useAppState();
    const [trans] = useTranslate('components:trial_expiration');

    const [showTrialInfo, trialDaysLeft, trialEndDate] = useMemo(() => {
        // don't show trial info if user can't view billing
        if (!ClientPolicy.authorize('viewBilling', user, user.client)) {
            return FALSE_SHOW_TRIAL_RESPONSE;
        }

        // don't show trial info if this is a subclient
        if (user.client.manager_uuid !== user.client.uuid) {
            return FALSE_SHOW_TRIAL_RESPONSE;
        }

        // don't show trial info if client has no trial date setup
        if (!user.client.trial_ends_at) {
            return FALSE_SHOW_TRIAL_RESPONSE;
        }

        // calculate how many days are left in trial period
        const trialEndDate = dayjs.unix(user.client.trial_ends_at);
        const trialDaysLeft = trialEndDate.diff(dayjs(), 'day', true);

        // don't show trial info if client has payment details filled
        // or there are more than 3 days left in trial
        if (user.client.has_filled_payment_details || trialDaysLeft >= 4) {
            return FALSE_SHOW_TRIAL_RESPONSE;
        }

        // find user's paid sites
        const paidSites = user.sites.filter((site) => {
            return !!site.plan && site.plan.config_name !== 'free';
        });

        // if there are no paid sites, no need to show trial info
        if (!paidSites.length) {
            return FALSE_SHOW_TRIAL_RESPONSE;
        }

        return [true, trialDaysLeft, trialEndDate];
    }, [user]);

    if (!showTrialInfo) {
        return null;
    }

    return (
        <InfoBox
            color={trialDaysLeft > 0 ? 'warning' : 'danger'}
            icon={
                trialDaysLeft > 0
                    ? 'md/ic_hourglass_empty'
                    : 'md/ic_error_outline'
            }
        >
            {trialDaysLeft <= 0 && (
                <>
                    <span>{trans('ended.before_link_text')}</span>{' '}
                    <Link
                        to={url('dash.billing.tab', {
                            tab: PAYMENT_DETAILS_TAB,
                        })}
                    >
                        {trans('link_text')}
                    </Link>{' '}
                    <span>{trans('after_link_text')}</span>
                </>
            )}
            {trialDaysLeft > 0 && (
                <>
                    <span>
                        {trans('ending.before_date_text')}{' '}
                        <strong>
                            <FormattedDate from={trialEndDate} format="L LT" />
                        </strong>
                        {trans('ending.before_link_text')}
                    </span>{' '}
                    <Link
                        to={url('dash.billing.tab', {
                            tab: PAYMENT_DETAILS_TAB,
                        })}
                    >
                        {trans('link_text')}
                    </Link>{' '}
                    <span>{trans('after_link_text')}</span>
                </>
            )}
        </InfoBox>
    );
};

ClientTrialExpirationInfo.propTypes = {};

export default ClientTrialExpirationInfo;
