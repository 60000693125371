import { Col, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';

import ConfirmIconPick from '../ConfirmIconPick/ConfirmIconPick';
import Pagination from 'components/Pagination/Pagination';
import PropTypes from 'prop-types';
import classNames from 'helpers/classNames';
import styles from './ChooseIconTab.scss';

const cx = classNames(styles);

const ChooseIconTab = ({
    icons,
    value,
    onChange,
    paginationInfo,
    setModalSize,
}) => {
    const [pickedIcon, setPickedIcon] = useState(null);

    useEffect(() => {
        setModalSize('lg');
    }, []);

    /**
     * Handle size of modal for confirmation view. Save icon to state to open confirmation view.
     * @param {string} icon - name of picked icon
     */
    const handlePickedIcon = (icon) => {
        setModalSize('md');
        setPickedIcon(icon.filename);
    };

    return (
        <>
            {pickedIcon && (
                <ConfirmIconPick
                    onConfirm={onChange}
                    onCancel={() => setPickedIcon(null)}
                    icon={pickedIcon}
                    setModalSize={setModalSize}
                ></ConfirmIconPick>
            )}
            {!pickedIcon && (
                <>
                    <Row form>
                        {icons.map((icon) => (
                            <Col
                                xs="2"
                                sm="2"
                                lg="1"
                                key={icon.key}
                                className={cx('iconCol')}
                            >
                                <button
                                    onClick={() => {
                                        handlePickedIcon(icon);
                                    }}
                                    className={cx('iconItem', 'w-100', {
                                        isSelected: icon.filename === value,
                                    })}
                                >
                                    <span className={cx('iconWrapper')}>
                                        <img
                                            src={icon.url}
                                            alt={icon.name}
                                            className={cx('icon')}
                                        ></img>
                                    </span>
                                </button>
                            </Col>
                        ))}
                    </Row>
                    {paginationInfo && (
                        <Pagination
                            className={cx('mt-3')}
                            onChange={paginationInfo.setPage}
                            currentPage={paginationInfo.currentPage}
                            lastPage={paginationInfo.lastPage}
                        />
                    )}
                </>
            )}
        </>
    );
};

ChooseIconTab.propTypes = {
    icons: PropTypes.arrayOf(
        PropTypes.shape({
            filename: PropTypes.string,
            key: PropTypes.string,
        })
    ),
    paginationInfo: PropTypes.shape({
        setPage: PropTypes.func,
        currentPage: PropTypes.number,
        lastPage: PropTypes.number,
    }),
    value: PropTypes.string,
    onChange: PropTypes.func,
    setModalSize: PropTypes.func,
};

export default ChooseIconTab;
