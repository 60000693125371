import Authorize from 'core/Authorize/Authorize';
import { Badge } from 'reactstrap';
import Link from 'components/Link';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'helpers/classNames';
import styles from './ClientBadge.scss';
import { useTranslation } from 'react-i18next';
import useUrl from 'hooks/useUrl';

const cx = classNames(styles);

const ClientBadge = ({ client }) => {
    const { t } = useTranslation();
    const url = useUrl();
    if (!client) {
        return null;
    }

    const label = t('common:site_list_item.badge.client');
    const href = url('userNS.clients.edit', { client: client.uuid });
    return (
        <Badge color="info" className={cx('mr-2')}>
            <Authorize
                policy={Authorize.ClientPolicy}
                action="view"
                model={client}
            >
                {(canViewClient) => {
                    const content = `${label}: ${client.name || '---'}`;
                    if (!canViewClient) {
                        return content;
                    }

                    return (
                        <Link className={cx('text-light')} to={href}>
                            {content}
                        </Link>
                    );
                }}
            </Authorize>
        </Badge>
    );
};

ClientBadge.propTypes = {
    client: PropTypes.shape({
        uuid: PropTypes.string,
        name: PropTypes.string,
    }),
};

ClientBadge.defaultProps = {};

export default ClientBadge;
