import React, { useCallback, useEffect } from 'react';

import API from 'helpers/api';
import PropTypes from 'prop-types';
import ThirdPartyScriptListItem from './ThirdPartyScriptListItem';
import useExecuteRequest from 'hooks/useExecuteRequest';
import useNotifications from 'hooks/useNotifications';

const ThirdPartyScriptListItemContainer = ({ item }) => {
    const [req, delRes, delErr, delMeta] = useExecuteRequest();
    const notify = useNotifications();
    const handleDelete = useCallback(() => {
        if (
            !confirm(
                `Are you sure you want to delete this script?, This will affect ${item.site_scripts_count} instances of this script configured for sites.`
            )
        ) {
            return;
        }

        req(API.del(`/admin/third-party-scripts/${item.uuid}`));
    }, [item.uuid]);

    useEffect(() => {
        if (delMeta.isSuccess) {
            notify.success(
                <>
                    <strong>{item.name}</strong> deleted
                </>
            );
        }
        if (delMeta.isError) {
            notify.success(delErr.message);
        }
    }, [delMeta.isSuccess, delMeta.isError]);

    if (delMeta.isSuccess) {
        return null;
    }

    return (
        <ThirdPartyScriptListItem
            item={item}
            onDelete={handleDelete}
            isDeleting={delMeta.isLoading}
        />
    );
};

ThirdPartyScriptListItemContainer.propTypes = {
    item: PropTypes.shape({
        uuid: PropTypes.string,
        name: PropTypes.string,
        site_scripts_count: PropTypes.number,
    }),
};

export default ThirdPartyScriptListItemContainer;
