export const BANNER_LOAD = 'banner_load';
export const EDIT_CURRENT = 'edit';
export const GTM_LOAD = 'gtm_load';
export const AUTOBLOCK = 'autoblock';
export const MANUAL = 'manual';

export const INTEGRATION_METHODS = [
    BANNER_LOAD,
    EDIT_CURRENT,
    GTM_LOAD,
    AUTOBLOCK,
    MANUAL,
];
