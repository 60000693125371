import _get from 'lodash/get';
import dayjs from 'helpers/dayjs';
import styles from './HomeView.scss';
import { useMemo } from 'react';

const useConsentsChart = (apiResponse = {}) => {
    const chartData = useMemo(() => {
        const series = {
            total: {
                name: 'Total',
                data: [],
            },
            denies: {
                name: 'Denies',
                data: [],
            },
            optins: {
                name: 'Optins',
                data: [],
            },
            withdrawals: {
                name: 'Withdrawals',
                data: [],
            },
            resets: {
                name: 'Resets',
                data: [],
            },
        };
        const data = {
            type: 'area',
            series: [],
            options: {
                chart: {
                    height: 350,
                    fontFamily: 'Noto Sans, sans-serif',
                    toolbar: {
                        show: false,
                    },
                },
                grid: {
                    padding: {
                        top: 20,
                        left: 60,
                        right: 20,
                    },
                },
                stroke: {
                    curve: 'smooth',
                },
                xaxis: {
                    categories: [],
                },
                dataLabels: {
                    enabled: false,
                },
                tooltip: {
                    x: false,
                },
                colors: [
                    styles['total-color'],
                    styles['optout-color'],
                    styles['optin-color'],
                    styles['withdrawal-color'],
                    styles['reset-color'],
                ],
            },
        };
        if (!apiResponse) {
            return data;
        }

        const sets = Object.keys(series);
        for (let i = 29; i >= 0; i--) {
            const date = dayjs().subtract(i, 'day').format('YYYY-MM-DD');
            data.options.xaxis.categories.push(date);

            for (const set of sets) {
                const value = parseInt(
                    _get(apiResponse, `consents.${date}.${set}`) || 0
                );
                series[set].data.push(value);
            }
        }

        data.series = Object.values(series);
        return data;
    }, [apiResponse]);

    return chartData;
};

export default useConsentsChart;
