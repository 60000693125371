import { Badge } from 'reactstrap';
import Link from 'components/Link';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'helpers/classNames';
import styles from './ResellerBadge.scss';
import { useTranslation } from 'react-i18next';
import useUrl from 'hooks/useUrl';

const cx = classNames(styles);

const ResellerBadge = ({ client }) => {
    const { t } = useTranslation();
    const url = useUrl();
    if (!client || client.uuid === client.manager_uuid) {
        return null;
    }

    const label = t('common:site_list_item.badge.reseller');
    const href = url('userNS.clients.edit', { client: client.manager_uuid });
    return (
        <Badge color="info" className={cx('mr-2')}>
            <Link className={cx('text-light')} to={href}>
                {label}: {client.manager.name}
            </Link>
        </Badge>
    );
};

ResellerBadge.propTypes = {
    client: PropTypes.shape({
        uuid: PropTypes.string,
        name: PropTypes.string,
        manager_uuid: PropTypes.string,
        manager: PropTypes.shape({
            name: PropTypes.string,
        }),
    }),
};

ResellerBadge.defaultProps = {};

export default ResellerBadge;
