import AnimatedList from 'components/AnimatedList/AnimatedList';
import Authorize from 'core/Authorize/Authorize';
import CustomScriptListItem from './components/CustomScriptListItem';
import Link from 'components/Link';
import Pagination from 'components/Pagination';
import React from 'react';
import Spinner from 'components/Spinner';
import classNames from 'helpers/classNames';
import styles from './CustomScriptsListView.scss';
import { url } from 'core/Router';
import { useCustomScriptPolicy } from 'policies/CustomScriptPolicy';
import useListing from 'hooks/useListing';
import { useTranslation } from 'react-i18next';
import useUrlQuery from 'hooks/useUrlQuery';

const cx = classNames(styles);

const CustomScriptsListView = () => {
    const { t } = useTranslation();
    const [query] = useUrlQuery();
    const canViewListing = useCustomScriptPolicy('viewAny');
    const [list, fetchMeta] = useListing(
        '/client/custom-scripts',
        query,
        canViewListing
    );

    return (
        <>
            <div className={cx('mb-3')}>
                <h1 className={cx('mb-3')}>
                    {t('client:custom-scripts.custom_scripts_list_view.title')}
                </h1>
                <Authorize
                    policy={Authorize.CustomScriptPolicy}
                    action="create"
                >
                    <Link
                        button
                        icon="md/ic_add"
                        to={url('dash.customScripts.create')}
                    >
                        {t(
                            'client:custom-scripts.custom_scripts_list_view.new_script'
                        )}
                    </Link>
                </Authorize>
            </div>
            {fetchMeta.isLoading && <Spinner />}
            {!fetchMeta.isLoading && fetchMeta.isSuccess && (
                <div className={cx('px-3')}>
                    <AnimatedList
                        list={list.data}
                        itemComponent={CustomScriptListItem}
                        itemKey="uuid"
                    />
                    <Pagination
                        className={cx('mt-3')}
                        currentPage={list.current_page}
                        lastPage={list.last_page}
                    />
                </div>
            )}
        </>
    );
};

CustomScriptsListView.propTypes = {};

CustomScriptsListView.defaultProps = {};

export default CustomScriptsListView;
