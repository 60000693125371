import 'react-image-crop/dist/ReactCrop.css';

import React, { useState } from 'react';
import { getCroppedBase64, getImageData } from 'helpers/images';

import Button from 'components/Button';
import { CustomInput } from 'reactstrap';
import Modal from 'components/Modal';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';
import Spinner from 'components/Spinner';
import classNames from 'helpers/classNames';
import styles from './EditImageModal.scss';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const allowedTypes = ['image/png', 'image/jpeg'];
const EditImageModal = ({
    isOpen,
    onClose,
    onChange,
    width,
    height,
    aspect,
}) => {
    const [image, setImage] = useState('');
    const [isRenderingImage, setIsRenderingImage] = useState(false);
    const [isProcessingConfirm, setIsProcessingConfirm] = useState(false);
    const [fileName, setFileName] = useState('');
    const [crop, setCrop] = useState({ aspect });
    const { t } = useTranslation();

    const resetState = () => {
        setImage('');
        setIsProcessingConfirm(false);
        setIsRenderingImage(false);
        setFileName('');
        setCrop({ aspect });
    };

    /**
     *
     * When user selects a new file, do the following:
     * 1. validate selected file
     * 2. remove previous image from preview
     * 3. show loading animation
     * 4. update selected file's name in the input component
     * 5. convert File object to base64 and set it as new preview
     * 6. hide loading animation
     */
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        if (!allowedTypes.includes(file.type)) {
            alert(t('components:image_picker_field.invalid_file_type'));
            return;
        }

        setImage('');
        setFileName(file.name);
        setIsRenderingImage(true);
        setIsProcessingConfirm(false);
        try {
            const data = await getImageData(file);
            const crop = { width: 100, heigth: 100 };
            // automatically select part of image for crop
            if (aspect) {
                const isHorizontal = aspect >= 1;
                crop.width = isHorizontal ? width || 100 : undefined;
                crop.height = isHorizontal ? undefined : height || 100;
                crop.aspect = aspect;
            } else if (height || width) {
                crop.width = ((width || 1) / data.width) * 100;
                crop.height = ((height || 1) / data.height) * 100;
            }

            setCrop(crop);
            setImage(data.src);
        } catch (err) {
            alert(err.message);
        }

        setIsRenderingImage(false);
    };

    /**
     * When user clicks "confirm", do the following
     * 1. Show loading indicator on the "confirm" button
     * 2. Crop image using a helper function
     * 3. Call onChange callback
     * 4. Reset state
     * 5. Close modal
     */
    const handleConfirm = async () => {
        setIsProcessingConfirm(true);
        let newImage = '';
        try {
            newImage = await getCroppedBase64(image, crop);
        } catch (err) {
            setIsProcessingConfirm(false);
            alert(err.message);
            return;
        }

        onChange(newImage);
        resetState();
        onClose();
    };

    const handleCropChange = (_, cropData) => {
        const data = {
            ...cropData,
            width: cropData.width || 100, // make sure we don't use empty crop
            height: cropData.height || 100, // make sure we don't use empty crop
        };
        setCrop(data);
    };

    const handleClose = () => {
        resetState();
        onClose();
    };
    return (
        <Modal
            isOpen={isOpen}
            onClose={handleClose}
            title={t('components:image_picker_field.modal_title')}
        >
            <Modal.Body>
                {!image && (
                    <CustomInput
                        className={cx('input')}
                        onChange={handleFileChange}
                        label={fileName || ''}
                        accept={allowedTypes.join(', ')}
                        id={Math.random()}
                        type="file"
                    />
                )}
                {(isRenderingImage || isProcessingConfirm) && <Spinner />}
                {!!image && !isProcessingConfirm && (
                    <div className={cx('text-center')}>
                        <ReactCrop
                            keepSelection
                            disabled={isProcessingConfirm}
                            className={cx('cropper')}
                            src={image}
                            onChange={handleCropChange}
                            minHeight={height || undefined}
                            maxHeight={height || undefined}
                            minWidth={width || undefined}
                            maxWidth={width || undefined}
                            crop={{ ...crop, unit: '%' }}
                        />
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    disabled={isProcessingConfirm}
                    outline
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    disabled={!image}
                    loading={isProcessingConfirm}
                    onClick={handleConfirm}
                >
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

EditImageModal.propTypes = {
    isOpen: PropTypes.bool,
    src: PropTypes.string,
    aspect: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
};

EditImageModal.defaultProps = {};

export default EditImageModal;
