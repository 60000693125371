import { Col, Row, Table } from 'reactstrap';
import React, { useEffect } from 'react';

import API from 'helpers/api';
import Button from 'components/Button';
import Link from 'components/Link';
import Pagination from 'components/Pagination';
import Spinner from 'components/Spinner';
import classNames from 'helpers/classNames';
import styles from './InfoBarMessageListView.scss';
import { url } from 'core/Router';
import useApiNamespace from 'hooks/useApiNamespace';
import useExecuteRequest from 'hooks/useExecuteRequest';
import useInfoBarMessages from 'hooks/useInfoBarMessages';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';
import useUrlQuery from 'hooks/useUrlQuery';

const cx = classNames(styles);

const InfoBarMessageListView = () => {
    const { t } = useTranslation();
    const [query] = useUrlQuery();
    const [infoBarMessages, fetchMeta, fetchErr, reload] =
        useInfoBarMessages(query);
    const notify = useNotifications();

    const apiNamespace = useApiNamespace();
    const [
        del,
        delRes,
        delErr,
        {
            isSuccess: isDeleted,
            isLoading: isDeleting,
            isError: isDeletionError,
        },
    ] = useExecuteRequest();
    const handleDelete = (infoBarMessage) => {
        const monit = t(
            'admin:info-bar-messages.info_bar_message_list_view.are_you_sure_delete'
        );
        if (!confirm(monit)) {
            return;
        }

        del(
            API.del(`${apiNamespace}/info-bar-messages/${infoBarMessage.uuid}`)
        );
    };

    useEffect(() => {
        if (isDeleted) {
            notify.success(
                t('admin:info-bar-messages.info_bar_message_list_view.deleted')
            );
            reload();
        }
        if (isDeletionError) {
            notify.error(delErr.message);
        }
    }, [isDeletionError, isDeleted]);
    return (
        <>
            <Row>
                <Col>
                    <h1>
                        {t(
                            'admin:info-bar-messages.info_bar_message_list_view.title'
                        )}
                    </h1>
                </Col>
                <Col xs="auto">
                    <Link button to={url('admin.infoBarMessages.create')}>
                        {t(
                            'admin:info-bar-messages.info_bar_message_list_view.add_new_info_bar_message'
                        )}
                    </Link>
                </Col>
            </Row>
            {fetchMeta.isLoading && <Spinner />}
            {!fetchMeta.isLoading && fetchMeta.isSuccess && (
                <>
                    <Table>
                        <thead>
                            <tr>
                                <th>
                                    {t(
                                        'admin:info-bar-messages.info_bar_message_listing_columns.content'
                                    )}
                                </th>
                                <th>
                                    {t(
                                        'admin:info-bar-messages.info_bar_message_listing_columns.type'
                                    )}
                                </th>
                                <th>
                                    {t(
                                        'admin:info-bar-messages.info_bar_message_listing_columns.status'
                                    )}
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {infoBarMessages.data.map(
                                (infoBarMessage, index) => (
                                    <tr key={infoBarMessage.uuid}>
                                        <td>
                                            {infoBarMessage
                                                .info_bar_translations.length >
                                            0
                                                ? infoBarMessage
                                                      .info_bar_translations[0]
                                                      .content
                                                : 'no translation for current language'}
                                        </td>
                                        <td>
                                            {t(
                                                `admin:bar.label_${infoBarMessage.type}`
                                            )}
                                        </td>
                                        <td>
                                            {infoBarMessage.status
                                                ? t(
                                                      'admin:info-bar-messages.info_bar_message_list_view.status.enabled'
                                                  )
                                                : t(
                                                      'admin:info-bar-messages.info_bar_message_list_view.status.disabled'
                                                  )}
                                        </td>
                                        <td>
                                            <div
                                                className={cx(
                                                    'd-flex',
                                                    'flex-xl-row',
                                                    'flex-column',
                                                    'justify-content-end'
                                                )}
                                            >
                                                <Link
                                                    to={url(
                                                        'admin.infoBarMessages.edit',
                                                        {
                                                            message:
                                                                infoBarMessage.uuid,
                                                        }
                                                    )}
                                                    button
                                                    icon="md/ic_build"
                                                >
                                                    {t(
                                                        'admin:info-bar-messages.info_bar_message_listing_columns.edit'
                                                    )}
                                                </Link>
                                                <Button
                                                    icon="md/ic_delete_forever"
                                                    className={cx(
                                                        'ml-xl-3',
                                                        'mt-3',
                                                        'mt-xl-0'
                                                    )}
                                                    disabled={isDeleting}
                                                    loading={isDeleting}
                                                    color="danger"
                                                    onClick={() =>
                                                        handleDelete(
                                                            infoBarMessage
                                                        )
                                                    }
                                                >
                                                    {t(
                                                        'admin:info-bar-messages.info_bar_message_listing_columns.delete'
                                                    )}
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </Table>
                    <Pagination
                        currentPage={infoBarMessages.current_page}
                        lastPage={infoBarMessages.last_page}
                    />
                </>
            )}
        </>
    );
};

export default InfoBarMessageListView;
