import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import classNames from 'helpers/classNames';
import styles from './SecondarySideBar.scss';
import useAppState from 'hooks/useAppState';

const cx = classNames(styles);

const SecondarySideBar = ({ children }) => {
    const { isSidebarExpanded, updateAppState } = useAppState();

    // make space for secondary sidebar when component renders
    useEffect(() => {
        updateAppState('isSecondaryMenuVisible', true);

        // remove space for secondary sidebar when component is destroyed
        return () => updateAppState('isSecondaryMenuVisible', false);
    }, []);

    return (
        <div className={cx('secondarySideBar', { isSidebarExpanded })}>
            {children}
        </div>
    );
};

SecondarySideBar.propTypes = {
    children: PropTypes.node,
};

SecondarySideBar.defaultProps = {};

export default SecondarySideBar;
