import React, { useEffect, useState } from 'react';

import _isEqual from 'lodash/isEqual';
import useMaybeSetState from 'hooks/useMaybeSetState';

const reorderItems = (list, oldIndex, newIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(oldIndex, 1);
    result.splice(newIndex, 0, removed);

    return result;
};

const useReorderableList = (initialList) => {
    const maybeSetState = useMaybeSetState();
    const [items, setItems] = useState(initialList);
    useEffect(() => {
        if (_isEqual(initialList, items)) {
            return;
        }

        maybeSetState(() => setItems(initialList));
    }, [JSON.stringify(initialList)]);
    const handleReorder = (oldIndex, newIndex) => {
        const newItems = reorderItems(items, oldIndex, newIndex);
        setItems(newItems);
    };

    return [items, handleReorder];
};

export default useReorderableList;
