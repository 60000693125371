import { useMemo } from 'react';

const useConsentsPerSiteChart = (apiResponse = {}) => {
    const chartData = useMemo(() => {
        const counts = [];
        const data = {
            height: 1400,
            type: 'bar',
            series: [],
            options: {
                chart: {
                    fontFamily: 'Noto Sans, sans-serif',
                    toolbar: {
                        show: false,
                    },
                },
                grid: {
                    padding: {
                        top: 20,
                        left: 60,
                        right: 20,
                    },
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                xaxis: {
                    categories: [],
                },
            },
        };
        if (!apiResponse) {
            return data;
        }

        apiResponse.consents_per_site.map((site) => {
            data.options.xaxis.categories.push(site.base_url);
            counts.push(site.consents_count);
        });

        data.height = 100 + (apiResponse.consents_per_site.length * 40 || 40);
        data.series = [{ data: counts, name: 'Consents' }];
        return data;
    }, [apiResponse]);

    return chartData;
};

export default useConsentsPerSiteChart;
