import _get from 'lodash/get';
import { useEffect } from 'react';

class FlashDataBag {
    data = {};

    constructor(source = null) {
        if (!source) {
            return;
        }

        this.data = {
            errors: _get(source, 'errors', {}),
            input: _get(source, 'input', {}),
            status: _get(source, 'status', {}),
        };

        this.errors = this.errors.bind(this);
        this.input = this.input.bind(this);
        this.status = this.status.bind(this);
        this.clear = this.clear.bind(this);
    }

    errors(key = '', def = []) {
        if (!key) {
            return this.data.errors || {};
        }
        return _get(this.data, `errors.${key}`, def);
    }

    input(key = '', def = '') {
        if (!key) {
            return this.data.input || {};
        }
        return _get(this.data, `input.${key}`, def);
    }

    status() {
        return _get(this.data, 'status', '');
    }

    clear() {
        this.data = {};
    }
}

const bag = new FlashDataBag(window.__APP_FLASH_DATA__ || {});

const useFlashData = () => {
    useEffect(() => bag.clear, []); // clear bag on unmount
    return bag;
};

export default useFlashData;
