import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import React, { useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import classNames from 'helpers/classNames';
import styles from './DragDropList.scss';
import useIsFirstRender from 'hooks/useIsFirstRender';
import useReorderableList from './useReorderableList';

const cx = classNames(styles);

const DragDropList = ({
    list,
    itemKey,
    itemComponent,
    className,
    listActiveClassName,
    itemClassName,
    direction,
    onReorder,
    ...props
}) => {
    const id = useMemo(() => `droppable-${Math.random()}`, []);
    const [items, handleReorder] = useReorderableList(list);
    const isFirstRender = useIsFirstRender();
    const handleDragEnd = (result) => {
        if (
            !result.destination ||
            result.destination.index === result.source.index
        ) {
            // dropped outside container or dropped to the same place
            return;
        }

        handleReorder(result.source.index, result.destination.index);
    };
    useEffect(() => {
        // don't emit reorder event on first render
        if (isFirstRender) {
            return;
        }

        onReorder(items);
    }, [JSON.stringify(items)]);
    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId={id} direction={direction}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        className={cx('dragDropList', className, {
                            [listActiveClassName]: snapshot.isDraggingOver,
                            isDragging: snapshot.isDraggingOver,
                        })}
                        {...provided.droppableProps}
                    >
                        {items.map((item, index) => (
                            <Draggable
                                key={item[itemKey]}
                                index={index}
                                draggableId={item[itemKey]}
                            >
                                {(provided, snapshot) => {
                                    const Item = itemComponent;
                                    return (
                                        <div
                                            {...provided.draggableProps}
                                            ref={provided.innerRef}
                                            className={
                                                typeof itemClassName ===
                                                'function'
                                                    ? itemClassName(item)
                                                    : itemClassName
                                            }
                                        >
                                            <Item
                                                {...props}
                                                index={index + 1}
                                                item={item}
                                                dragHandleProps={
                                                    provided.dragHandleProps
                                                }
                                            />
                                        </div>
                                    );
                                }}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

DragDropList.propTypes = {
    className: PropTypes.string,
    listActiveClassName: PropTypes.string,
    placeholderClassName: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.object),
    itemKey: PropTypes.string,
    direction: PropTypes.string,
    itemComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    itemClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    onReorder: PropTypes.func,
};

DragDropList.defaultProps = {
    className: '',
    itemClassName: '',
    listActiveClassName: '',
    placeholderClassName: '',
    list: [],
    itemKey: 'type',
    direction: 'vertical',
    itemComponent: () => null,
    onReorder: console.log,
};

export default DragDropList;
