import {
    Dropdown as BsDropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from 'reactstrap';
import React, { useEffect, useMemo, useState } from 'react';

import Button from 'components/Button';
import Icon from 'components/Icon';
import PropTypes from 'prop-types';
import Spinner from 'components/Spinner';
import _debounce from 'lodash/debounce';
import _noop from 'lodash/noop';
import _trim from 'lodash/trim';
import classNames from 'helpers/classNames';
import styles from './AsyncDropdown.scss';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const AsyncDropdown = ({
    className = '',
    toggleClassName = '',
    onChoose = _noop,
    placeholder = '',
    icon = '',
    outline = false,
    color = 'primary',
    disabled = false,
    multiple = false,
    options = [],
    isLoadingOptions = false,
    isLoadingMore = false,
    onSearch = _noop,
    onLoadMore = null,
    value = null,
    searchable = false,
    renderMainLabel = null,
    invalid = false,
    renderLabel = (i) => i.label,
}) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const currValue = Array.isArray(value) ? value : [value];
    const handleTogle = () => setIsOpen((prevOpen) => !prevOpen);
    const handleChoose = (item) => {
        if (disabled) {
            return;
        }

        if (!multiple) {
            const newValue = currValue.includes(item.value) ? null : item.value;
            onChoose(newValue);
            return;
        }

        const newValue = [...currValue]
            .filter((i) => i !== item.value)
            .filter((i) => !!i);

        // only add selected item if not currently added (to allow for deseleting)
        if (!currValue.includes(item.value)) {
            newValue.push(item.value);
        }
        onChoose(newValue);
    };

    const handleSearch = useMemo(() => _debounce(onSearch, 300), []);

    useEffect(() => {
        handleSearch(searchValue);
    }, [searchValue]);

    const selectedItems = options.filter((i) => currValue.includes(i.value));
    return (
        <BsDropdown
            className={className}
            isOpen={isOpen}
            toggle={handleTogle}
            aria-invalid={invalid}
        >
            <DropdownToggle
                className={cx('toggle', toggleClassName)}
                color={color}
                caret
                outline={outline}
                disabled={disabled}
            >
                {!!icon && <Icon icon={icon} className={cx('mr-1')} />}
                {renderMainLabel ? (
                    renderMainLabel({ selectedItems })
                ) : (
                    <>
                        {!currValue.length && !!placeholder && placeholder}
                        {(currValue.length > 0 || !placeholder) &&
                            t('common:common.number_options_selected', {
                                number: currValue.length,
                            })}
                    </>
                )}
            </DropdownToggle>
            <DropdownMenu className={cx('menu')}>
                {!!searchable && (
                    <input
                        autoFocus
                        className={cx('ml-4', 'my-2')}
                        placeholder={t('common:common:type_to_filter')}
                        onChange={(e) => setSearchValue(e.target.value)}
                        value={searchValue}
                    />
                )}
                {isLoadingOptions ? (
                    <Spinner />
                ) : (
                    <>
                        {options.map((item) => (
                            <DropdownItem
                                disabled={item.disabled}
                                active={currValue.includes(item.value)}
                                key={item.value}
                                onClick={() => handleChoose(item)}
                            >
                                {renderLabel(item)}
                            </DropdownItem>
                        ))}
                    </>
                )}
                {typeof onLoadMore === 'function' && (
                    <div className={cx('text-center', 'mt-2')}>
                        <Button
                            loading={isLoadingMore}
                            size="sm"
                            label={t('common:common.show_more')}
                            onClick={onLoadMore}
                        />
                    </div>
                )}
            </DropdownMenu>
        </BsDropdown>
    );
};

AsyncDropdown.propTypes = {
    className: PropTypes.string,
    toggleClassName: PropTypes.string,
    onChoose: PropTypes.func,
    placeholder: PropTypes.node,
    icon: PropTypes.string,
    outline: PropTypes.bool,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    multiple: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            label: PropTypes.node,
        })
    ),
    isLoadingOptions: PropTypes.bool,
    isLoadingMore: PropTypes.bool,
    onSearch: PropTypes.func,
    onLoadMore: PropTypes.func,
    value: PropTypes.any,
    searchable: PropTypes.bool,
    renderLabel: PropTypes.func,
    invalid: PropTypes.bool,
    renderMainLabel: PropTypes.func,
};

export default AsyncDropdown;
