import React, { useCallback, useEffect } from 'react';

import API from 'helpers/api';
import Form from 'components/Form';
import InfoBarMessageFormFields from '../components/InfoBarMessageFormFields';
import Notification from 'components/Notification';
import Spinner from 'components/Spinner';
import classNames from 'helpers/classNames';
import styles from './EditInfoBarMessageView.scss';
import useApiNamespace from 'hooks/useApiNamespace';
import useExecuteRequest from 'hooks/useExecuteRequest';
import useNotifications from 'hooks/useNotifications';
import { useParams } from 'react-router-dom';
import useRequest from 'hooks/useRequest';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const EditInfoBarMessageView = () => {
    const { t } = useTranslation();
    const notify = useNotifications();
    const namespace = useApiNamespace();
    const { message: messageUuid } = useParams();
    const [infoBarMessage, messageFetchErr, fetchMeta] = useRequest(
        API.get(`/admin/info-bar-messages/${messageUuid}`)
    );
    const [save, saveRes, saveErr, saveMeta] = useExecuteRequest();
    const handleSubmit = useCallback(
        (data) => {
            save(
                API.put(`/${namespace}/info-bar-messages/${messageUuid}`, data)
            );
        },
        [save]
    );

    useEffect(() => {
        if (saveMeta.isSuccess) {
            notify.success('Info Bar Message updated.');
        }

        if (!saveMeta.isSuccess) {
            return;
        }
    }, [saveRes, saveMeta.isSuccess]);
    return (
        <>
            <h1>
                {t('admin:info-bar-messages.info_bar_message_edit_view.title')}
            </h1>
            {(fetchMeta.isLoading || saveMeta.isLoading) && <Spinner />}
            {saveMeta.isError && (
                <Notification type="error">
                    <p>{t('admin:bar.error')}</p>
                    <p>{saveErr.message}</p>
                    {Object.values(saveErr.validation).map((el, index) => (
                        <p key={index}>{el}</p>
                    ))}
                </Notification>
            )}
            <Form
                initialValues={infoBarMessage}
                error={saveErr}
                method="POST"
                className={cx({
                    'd-none': fetchMeta.isLoading || saveMeta.isLoading,
                })}
                onSubmit={handleSubmit}
            >
                <InfoBarMessageFormFields />
            </Form>
        </>
    );
};

export default EditInfoBarMessageView;
