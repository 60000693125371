import Dropdown from 'components/Dropdown';
import PropTypes from 'prop-types';
import React from 'react';
import _noop from 'lodash/noop';
import classNames from 'helpers/classNames';
import styles from './DropdownInput.scss';

const cx = classNames(styles);

const DropdownInput = ({
    options = [],
    multiple = false,
    placeholder = '',
    invalid = false,
    disabled = false,
    onChange = _noop,
    toggleClassName = '',
    ...props
}) => {
    return (
        <Dropdown
            invalid={invalid}
            className={cx('dropdown', 'form-control', {
                'is-invalid': invalid,
                disabled,
            })}
            multiple={multiple}
            disabled={disabled}
            toggleClassName={cx(toggleClassName, 'custom-select')}
            label={placeholder}
            onChoose={onChange}
            options={options}
            renderLabel={(option) => (
                <span className={cx('value')}>{option.label}</span>
            )}
            {...props}
        />
    );
};

DropdownInput.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            label: PropTypes.node,
        })
    ),
    placeholder: PropTypes.node,
    onChange: PropTypes.func,
    multiple: PropTypes.bool,
    disabled: PropTypes.bool,
    invalid: PropTypes.bool,
    toggleClassName: PropTypes.string,
};

export default DropdownInput;
