import React, { useCallback, useEffect, useState } from 'react';

import Button from 'components/Button';
import ChooseIconModal from './components/ChooseIconModal';
import ImagePickerPreview from '../ImagePicker/components/ImagePickerPreview/ImagePickerPreview';
import PropTypes from 'prop-types';
import classNames from 'helpers/classNames';
import styles from './IconPicker.scss';
import useInstanceSettings from 'hooks/useInstanceSettings';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const IconPicker = ({
    value = '',
    name,
    invalid = false,
    required = false,
    onChange,
    disabled = false,
    ...props
}) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { banner_static_files_url } = useInstanceSettings();

    // Modal size state to controll size of modal in children props.
    const [modalSize, setModalSize] = useState('lg');

    const handleChange = useCallback(
        (icon) => {
            if (!icon) {
                return;
            }

            setIsModalOpen(false);
            onChange(icon);
        },
        [onChange]
    );
    return (
        <div
            className={cx('control', 'form-control', { 'is-invalid': invalid })}
        >
            <input type="hidden" name={name} value={value} />
            {!value && (
                <div>{t('components:icon_picker_field.no_icon_selected')}</div>
            )}
            <ImagePickerPreview
                allowEdit={true}
                onEdit={() => setIsModalOpen(true)}
                src={`${banner_static_files_url}/icons/${value}`}
            />
            <ChooseIconModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onChange={handleChange}
                value={value}
                setModalSize={setModalSize}
                size={modalSize}
            />
        </div>
    );
};

IconPicker.propTypes = {
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    invalid: PropTypes.bool,
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
};

export default IconPicker;
