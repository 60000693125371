import React, { useCallback, useEffect, useMemo } from 'react';

import API from 'helpers/api';
import ChoosePlanModal from 'components/ChoosePlanModal';
import _get from 'lodash/get';
import dayjs from 'dayjs';
import { url } from 'core/Router';
import useAppState from 'hooks/useAppState';
import useExecuteRequest from 'hooks/useExecuteRequest';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';

const ChooseFirstSitePlanModal = () => {
    const notify = useNotifications();
    const { t } = useTranslation();
    const {
        user: { sites, client },
    } = useAppState();
    const [request, res, error, submitState] = useExecuteRequest();
    const first_site = useMemo(() => sites[0], [JSON.stringify(sites)]);

    const isModalOpen = useMemo(() => {
        // failed to find first site
        if (!first_site || typeof first_site !== 'object') {
            return false;
        }

        // site not configured = has no url and no subscription plan
        return !first_site.base_url && !first_site.plan;
    }, [sites, first_site]);

    const subscribeToPlan = useCallback(
        (plan) => {
            const url = `/client/sites/${first_site.uuid}/subscribe`;
            request(API.post(url, { plan_uuid: plan.uuid }));
        },
        [request]
    );

    useEffect(() => {
        if (submitState.isSuccess) {
            notify.success(t('common:common.saved'));
            const siteEditUrl = url('dash.sites.edit', {
                site: first_site.uuid,
            });
            if (location.pathname === siteEditUrl) {
                window.location.reload();
                return;
            }
            window.location.href = siteEditUrl;
        }
        if (submitState.isError) {
            notify.error(error.message);
            //reload site when notification closes, as he probably did set plan before.
            setTimeout(() => window.location.reload(), 2 * 1000);
        }
    }, [submitState.isSuccess, submitState.isError]);

    if (!isModalOpen) {
        return null;
    }

    const trialEndsAt =
        _get(client, 'trial_ends_at') || dayjs().add(14, 'day').unix();

    return (
        <ChoosePlanModal
            title={t('components:choose_plan.default_title')}
            isModalOpen
            modalDisabled
            isLoading={submitState.isLoading}
            siteUuid={first_site.uuid}
            clientUuid={_get(client, 'uuid')}
            trialEndsAt={trialEndsAt}
            onPlanChosen={subscribeToPlan}
        />
    );
};

export default ChooseFirstSitePlanModal;
