import { Col, Row } from 'reactstrap';

import CookieMetaSuggestionsTab from '../components/CookieMetaSuggestionsTab';
import CookiesAllTab from '../components/CookiesAllTab';
import CookiesEmptyTab from '../components/CookiesEmptyTab';
import React from 'react';
import SearchField from 'components/SearchField';
import Tabs from 'components/Tabs';
import classNames from 'helpers/classNames';
import { useTranslation } from 'react-i18next';
import useUrlQuery from 'hooks/useUrlQuery';

const cx = classNames();

const getTabs = (t) => [
    {
        label: t('admin:cookies.cookies_list_view.tabs.empty_descriptions'),
        component: CookiesEmptyTab,
    },
    {
        label: t('admin:cookies.cookies_list_view.tabs.all_cookies'),
        component: CookiesAllTab,
    },
    {
        label: t(
            'admin:cookies.cookies_list_view.tabs.suggestions_from_clients'
        ),
        component: CookieMetaSuggestionsTab,
    },
];

const CookiesListView = () => {
    const { t } = useTranslation();
    // Get url query to reset page on tab change
    const [query, updateQuery] = useUrlQuery();

    /**
     * On tab change reset page so tab won't load with previously selected page that may not exist in this tab
     */
    const onTabChange = () => {
        updateQuery({ ...query, page: undefined });
    };

    return (
        <>
            <Row>
                <Col>
                    <h1>Cookies</h1>
                </Col>
            </Row>
            <Row className={cx('mb-3')}>
                <Col xs="12" md="4" lg="3">
                    <SearchField label="Search" />
                </Col>
            </Row>
            <Tabs onTabChange={onTabChange} tabs={getTabs(t)} />
        </>
    );
};

export default CookiesListView;
