import { Col, Row } from 'reactstrap';
import React, { useCallback, useEffect } from 'react';

import API from 'helpers/api';
import Form from 'components/Form';
import Notification from 'components/Notification';
import Spinner from 'components/Spinner';
import classNames from 'helpers/classNames';
import styles from './CreateUserView.scss';
import { url } from 'core/Router';
import useAppState from 'hooks/useAppState';
import useExecuteRequest from 'hooks/useExecuteRequest';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const CreateUserView = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [request, res, err, saveStatus] = useExecuteRequest();
    const { user } = useAppState();
    const handleSubmit = useCallback(
        (data) => {
            request(
                API.post('/client/users', {
                    ...data,
                    preferred_language: Array.isArray(data.preferred_language)
                        ? data.preferred_language[0]
                        : data.preferred_language,
                    client_uuid: Array.isArray(data.client_uuid)
                        ? data.client_uuid[0]
                        : data.client_uuid,
                })
            );
        },
        [request]
    );
    // redirect to new client after success
    useEffect(() => {
        if (!saveStatus.isSuccess) {
            return;
        }

        history.push(url('dash.users.edit', { user: res.user }));
    }, [res, saveStatus.isSuccess]);
    return (
        <>
            <h1>{t('client:users.create_user_view.title')}</h1>
            {saveStatus.isSuccess && (
                <Notification type="success">{res.message}</Notification>
            )}
            {saveStatus.isError && (
                <Notification type="error">
                    <p>
                        {t('client:users.create_user_view.failed_to_create')}:
                    </p>
                    <p>{err.message}</p>
                    {Object.values(err.validation).map((el, index) => (
                        <p key={index}>{el}</p>
                    ))}
                </Notification>
            )}
            <Form
                initialValues={{
                    client_uuid: user.client.uuid,
                    preferred_language: 'en',
                }}
                error={err}
                method="POST"
                isSubmitting={saveStatus.isLoading}
                onSubmit={handleSubmit}
            >
                <Row>
                    <Col xs="12" lg="6">
                        <Row>
                            <Col xs="12" md="6">
                                <Form.Field
                                    label={t(
                                        'client:field_labels.user.full_name'
                                    )}
                                    name="full_name"
                                    required
                                />
                            </Col>
                            <Col xs="12" md="6">
                                <Form.Field
                                    label={t(
                                        'client:field_labels.user.display_name'
                                    )}
                                    name="display_name"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" md="6">
                                <Form.Field
                                    type="email"
                                    label={t('client:field_labels.user.email')}
                                    name="email"
                                    required
                                />
                            </Col>
                            {!user.is_reseller && !user.is_admin && (
                                <Form.Field type="hidden" name="client_uuid" />
                            )}
                            {(user.is_reseller || user.is_admin) && (
                                <Col xs="12" md="6">
                                    <Form.Field
                                        label={t(
                                            'client:field_labels.user.client_uuid'
                                        )}
                                        type="SelectClient"
                                        name="client_uuid"
                                        required
                                        placeholder={t(
                                            'common:common.select_owner'
                                        )}
                                    />
                                </Col>
                            )}
                        </Row>
                        <Row>
                            <Col xs="12" md="6">
                                <Form.Field
                                    type="password"
                                    label={t(
                                        'client:field_labels.user.password'
                                    )}
                                    name="password"
                                    required
                                />
                            </Col>
                            <Col xs="12" md="6">
                                <Form.Field
                                    type="password"
                                    label={t(
                                        'client:field_labels.user.password_confirmation'
                                    )}
                                    name="password_confirmation"
                                    required
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" lg="6">
                                <Form.Field
                                    label={t(
                                        'client:field_labels.user.preferred_language'
                                    )}
                                    name="preferred_language"
                                    required
                                    adminOnly
                                    type="LanguageDropdown"
                                />
                            </Col>
                        </Row>
                        <Form.Submit icon="md/ic_save">
                            {t('common:buttons.save')}
                        </Form.Submit>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default CreateUserView;
