import React, { useMemo } from 'react';
import getSiteTabs, { BASIC_SETTINGS_TAB } from './tabs';

import ErrorBoundary from 'core/ErrorBoundary';
import NotFound from 'views/other/NotFound/NotFound';
import SecondarySideBar from 'core/SecondarySideBar/SecondarySideBar';
import SettingsBarMenu from './components/SettingsBarMenu';
import Site from 'models/Site';
import Spinner from 'components/Spinner';
import _get from 'lodash/get';
import _trimEnd from 'lodash/trimEnd';
import classNames from 'helpers/classNames';
import styles from './EditSiteView.scss';
import useAppState from 'hooks/useAppState';
import { useParams } from 'react-router-dom';
import useSite from 'hooks/useSite';
import { useSitePolicy } from 'policies/SitePolicy';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const getActiveTabBySlug = (slug, tabs) => {
    const tabKey = slug || BASIC_SETTINGS_TAB;
    const activeTab = tabs.find((tab) => tab.key.replace('_', '-') === tabKey);
    return activeTab;
};

const EditSiteView = () => {
    const { t } = useTranslation();
    const { user } = useAppState();
    const { site: siteUuid, slug } = useParams();
    const [site, fetchingMeta, reload, err] = useSite(siteUuid);

    const canViewSite = useSitePolicy('view', site);

    const [tabs, activeTab] = useMemo(() => {
        if (!fetchingMeta.isSuccess || !(site instanceof Site)) {
            return [[], null];
        }

        const tabs = getSiteTabs(site, user, t);
        const activeTab = getActiveTabBySlug(slug, tabs);
        return [tabs, activeTab];
    }, [site, fetchingMeta.isSuccess, user, t, slug]);

    if (site && !canViewSite) {
        return <NotFound />;
    }

    return (
        <ErrorBoundary>
            <SecondarySideBar>
                <SettingsBarMenu site={site} tabs={tabs} />
            </SecondarySideBar>
            {fetchingMeta.isLoading && <Spinner color="primary" />}
            {!fetchingMeta.isLoading && !fetchingMeta.isSuccess && (
                <>
                    {!!err &&
                    !!err.status &&
                    err.status >= 400 &&
                    err.status <= 500 ? (
                        <NotFound />
                    ) : (
                        t('common:edit_site_view.something_wrong')
                    )}
                </>
            )}
            {/* position-relative needed for audit trail on mobile */}
            {fetchingMeta.isSuccess && (
                <div className={cx('position-relative')}>
                    {!!activeTab && (
                        <>
                            <h2 className={cx('activeTab')}>
                                {t(activeTab.label)}
                            </h2>
                            <p>
                                {t(
                                    `common:edit_site_view.tabs.${activeTab.key}_intro`
                                )}
                            </p>
                        </>
                    )}
                    {/* position relative needed for  */}
                    <div>
                        {activeTab ? (
                            <activeTab.component site={site} reload={reload} />
                        ) : (
                            <NotFound />
                        )}
                    </div>
                </div>
            )}
        </ErrorBoundary>
    );
};

export default EditSiteView;
