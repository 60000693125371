import { CustomPicker, SketchPicker } from 'react-color';
import React, { useState } from 'react';

import PropTypes from 'prop-types';
import classNames from 'helpers/classNames';
import styles from './ColorPicker.scss';

const cx = classNames(styles);

const ColorPicker = ({
    value,
    allowTransparency = true,
    onChange,
    ...props
}) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div>
            <div
                className={cx('value')}
                style={{ backgroundColor: value }}
                onClick={() => setIsOpen(true)}
            />
            {isOpen && (
                <>
                    <div
                        onClick={() => setIsOpen(false)}
                        className={cx('picker')}
                    >
                        <div onClick={(e) => e.stopPropagation()}>
                            <SketchPicker
                                disableAlpha={!allowTransparency}
                                color={value}
                                onChangeComplete={onChange}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

ColorPicker.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    isOpen: PropTypes.bool,
    allowTransparency: PropTypes.bool,
};

ColorPicker.defaultProps = {
    isOpen: false,
};

export default ColorPicker;
