import { Badge, Col, Row } from 'reactstrap';

import Authorize from 'core/Authorize/Authorize';
import Button from 'components/Button';
import ClientBadge from 'components/ClientBadge';
import Link from 'components/Link';
import PropTypes from 'prop-types';
import React from 'react';
import ResellerBadge from 'components/ResellerBadge';
import Site from 'models/Site';
import SiteGroupBadge from 'components/SiteGroupBadge';
import _get from 'lodash/get';
import classNames from 'helpers/classNames';
import styles from './SiteListItem.scss';
import { url } from 'core/Router';
import useAppState from 'hooks/useAppState';
import usePlanButtonConfig from './usePlanButtonConfig';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const SiteListItem = ({
    item,
    onDelete,
    onStartManualScan,
    onUpgrade,
    isDeleting,
    isStartingManualScan,
    isUpgrading,
    urlNamespace,
}) => {
    const site = item;
    const { t } = useTranslation();
    const { user } = useAppState();

    const planButtonProps = usePlanButtonConfig(site);

    const isProcessing = isUpgrading || isDeleting;

    return (
        <div
            className={cx('item', `border-${site.status ? 'primary' : 'dark'}`)}
        >
            <Row form className={cx('align-items-center')}>
                <Col xs="12" md="auto" className={cx('mobile-wrap')}>
                    {!!site.status ? (
                        <Badge className={cx('mr-2')} color="success">
                            {t('common:site_list_item.site_enabled')}
                        </Badge>
                    ) : (
                        <Badge className={cx('mr-2')} color="dark">
                            {t('common:site_list_item.site_disabled')}
                        </Badge>
                    )}
                    <Authorize policy={Authorize.ClientPolicy} action="viewAny">
                        <ClientBadge client={site.client} />
                    </Authorize>
                    {user.is_admin && <ResellerBadge client={site.client} />}
                    <SiteGroupBadge group={site.group} />
                    {user.is_admin && (
                        <Badge
                            className={cx('mr-2', 'user-select-auto')}
                            color="info"
                        >
                            {t('common:site_list_item.stripe_subscription')}{' '}
                            <span className={cx('user-select-all')}>
                                {site.subscription?.stripe_id || '---'}
                            </span>
                        </Badge>
                    )}
                </Col>
                <Col xs="12" md="auto" className={cx('mobile-wrap')}>
                    {site.getDisplayName(t)}
                    &nbsp;
                    {!!site.base_url && (
                        <>
                            <span className={cx('d-md-inline', 'd-none')}>
                                (
                            </span>
                            <Link external to={site.base_url}>
                                {site.base_url}
                            </Link>
                            <span className={cx('d-md-inline', 'd-none')}>
                                )
                            </span>
                        </>
                    )}
                </Col>
                <Col xs="12">
                    <Row form>
                        <Col xs="12" md="auto" className={cx('mt-3')}>
                            <Button
                                {...planButtonProps}
                                disabled={!onUpgrade || isProcessing}
                                loading={isUpgrading}
                                onClick={() => onUpgrade(site)}
                                className={cx('w-100')}
                            />
                        </Col>
                        <Authorize
                            policy={Authorize.SitePolicy}
                            action="update"
                            model={site}
                        >
                            <Col xs="6" md="auto" className={cx('mt-3')}>
                                <Link
                                    icon="md/ic_build"
                                    button
                                    disabled={isProcessing}
                                    className={cx('w-100')}
                                    to={url(`${urlNamespace}.sites.edit`, {
                                        site: site.uuid,
                                    })}
                                >
                                    {t('common:site_list_item.settings')}
                                </Link>
                            </Col>
                        </Authorize>
                        <Authorize
                            policy={Authorize.SitePolicy}
                            action="delete"
                            model={site}
                        >
                            <Col xs="6" md="auto" className={cx('mt-3')}>
                                <Button
                                    icon="md/ic_delete_forever"
                                    disabled={isProcessing}
                                    loading={isDeleting}
                                    color="danger"
                                    className={cx('w-100')}
                                    onClick={() => onDelete(site)}
                                >
                                    {t('common:site_list_item.remove')}
                                </Button>
                            </Col>
                        </Authorize>
                        <Authorize
                            policy={Authorize.SitePolicy}
                            action="triggerScan"
                            model={site}
                        >
                            <Col xs="12" md="auto" className={cx('mt-3')}>
                                <Button
                                    icon="md/ic_youtube_searched_for"
                                    disabled={isProcessing}
                                    loading={isStartingManualScan}
                                    color="warning"
                                    className={cx('w-100')}
                                    onClick={() => onStartManualScan(site)}
                                >
                                    {t('common:site_list_item.manual_scan')}
                                </Button>
                            </Col>
                        </Authorize>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

SiteListItem.propTypes = {
    item: PropTypes.instanceOf(Site),
    onDelete: PropTypes.func,
    onStartManualScan: PropTypes.func,
    onUpgrade: PropTypes.func,
    isUpgrading: PropTypes.bool,
    isDeleting: PropTypes.bool,
    isStartingManualScan: PropTypes.bool,
    urlNamespace: PropTypes.string,
};

export default SiteListItem;
