import Button from 'components/Button';
import Form from 'components/Form';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'helpers/classNames';
import styles from './DevUrl.scss';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const DevUrl = ({ index, onRemoveDevUrl, showLabelSuffix = true, noLabel }) => {
    const { t } = useTranslation();

    const label = t('common:site_basic_settings_tab.dev_url');
    const labelSuffix = showLabelSuffix ? index + 1 : '';
    return (
        <div
            key={`dev[${index}]`}
            className={cx(
                'd-flex',
                'align-items-start',
                'justify-content-between',
                'mb-3'
            )}
        >
            <Form.Field
                type="url"
                className={cx('mb-0', 'w-100')}
                name={`dev_urls[${index}]`}
                label={noLabel ? undefined : `${label} ${labelSuffix}`}
            />
            {index + 1 > 1 && (
                <Button
                    color="danger"
                    icon="md/ic_remove_circle"
                    title={t('common:common.remove')}
                    className={cx('ml-1', 'button')}
                    onClick={() => {
                        onRemoveDevUrl(index);
                    }}
                />
            )}
        </div>
    );
};

DevUrl.propTypes = {
    item: PropTypes.string,
    index: PropTypes.number,
    showLabelSuffix: PropTypes.bool,
    noLabel: PropTypes.bool,
    onRemoveDevUrl: PropTypes.func,
};
DevUrl.defaultProps = {};
export default DevUrl;
