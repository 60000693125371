import React, { useMemo } from 'react';

import { CustomInput } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'helpers/classNames';
import styles from './Radio.scss';

const cx = classNames(styles);

const Radio = ({
    label,
    value,
    options,
    checked,
    optionClassName,
    ...props
}) => {
    const id = useMemo(Math.random, []);
    return options.map((option, i) => {
        const Label = option.label;
        const checked = value === option.value;
        return (
            <CustomInput
                {...props}
                key={option.value}
                className={cx(optionClassName, 'radio')}
                id={`radio-${id}-${option.value}`}
                type="radio"
                label={<Label checked={checked} option={option} />}
                checked={checked}
                value={option.value}
            />
        );
    });
};

Radio.propTypes = {
    optionClassName: PropTypes.string,
    label: PropTypes.node,
    value: PropTypes.string,
    checked: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
            value: PropTypes.string,
        })
    ),
};

export default Radio;
