import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Dropdown from 'components/Dropdown';
import PropTypes from 'prop-types';
import SecondarySideBarMenu from 'core/SecondarySideBarMenu';
import Spinner from 'components/Spinner';
import classNames from 'helpers/classNames';
import styles from './SettingsBarMenu.scss';
import useAppState from 'hooks/useAppState';
import { useMedia } from 'react-use';
import { useSitePolicy } from 'policies/SitePolicy';
import useSites from 'hooks/useSites';
import { useTranslation } from 'react-i18next';
import useUrl from 'hooks/useUrl';

const cx = classNames(styles);

const SettingsBarMenu = ({ site, tabs }) => {
    const { user } = useAppState();
    const url = useUrl();
    const { t } = useTranslation();
    const history = useHistory();
    const { slug: siteSlug } = useParams();
    const isMobile = useMedia('(max-width: 767px)');
    const allowDomainSwitcher = useSitePolicy('viewAny');
    const [sites, , , reloadSites] = useSites(
        {
            // load all possible sites
            per_page: -1,
            // if user is admin, restrict to this client's visible sites
            // don't have to do it if user is client because it's automatic
            clients:
                !!site && user.is_admin
                    ? [site.client.manager_uuid]
                    : undefined,
        },
        !!site && allowDomainSwitcher
    );

    useEffect(() => {
        // reload list of domains when site's name or base url changes
        // but only after sites have been fetched initially
        // and only if domain switcher is allowed
        if (site && allowDomainSwitcher && sites.data.length > 0) {
            reloadSites();
        }
    }, [site?.name, site?.base_url]);

    if (!site && !isMobile) {
        return <Spinner className={cx('spinner')} />;
    }
    const siteLabel = site ? site.getDisplayName(t) : '';

    return (
        <>
            {!isMobile && (
                <Dropdown
                    alwaysMainLabelOnToggle
                    disabled={!allowDomainSwitcher}
                    className={cx('domain-switch-dropdown', {
                        'd-none': !site,
                    })}
                    toggleClassName={cx('domain-switch-dropdown-toggle')}
                    label={
                        site ? (
                            <span className={cx('selectedDomain')}>
                                {siteLabel}
                            </span>
                        ) : (
                            ''
                        )
                    }
                    icon="subway/sharing"
                    options={
                        sites.data.length > 0
                            ? sites.data.map((site) => ({
                                  label: site.getDisplayName(t),
                                  value: site.uuid,
                              }))
                            : [
                                  {
                                      value: null,
                                      label: t('common:common.loading'),
                                  },
                              ]
                    }
                    onChoose={(val) => {
                        const [uuid] = val;
                        if (!uuid) {
                            return;
                        }
                        const path = url('userNS.sites.edit.slug', {
                            site: uuid,
                            slug: siteSlug,
                        });
                        history.push(path);
                    }}
                    renderLabel={(item) => item.label}
                    searchable
                    value={[site.uuid]}
                />
            )}
            <SecondarySideBarMenu links={tabs} mobileLabel={siteLabel} />
        </>
    );
};

SettingsBarMenu.propTypes = {
    site: PropTypes.object,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string,
            icon: PropTypes.string,
            label: PropTypes.node,
            key: PropTypes.string,
        })
    ),
};

SettingsBarMenu.defaultProps = {
    site: {},
    tabs: [],
};

export default SettingsBarMenu;
