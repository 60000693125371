import { Col, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import SitePolicy, { useSitePolicy } from 'policies/SitePolicy';

import AnimatedList from 'components/AnimatedList';
import Authorize from 'core/Authorize/Authorize';
import Form from 'components/Form';
import Link from 'components/Link';
import NotFound from 'views/other/NotFound';
import Pagination from 'components/Pagination';
import SearchField from 'components/SearchField';
import SiteListItem from 'partials/sites/SiteListItem';
import Spinner from 'components/Spinner';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import _trim from 'lodash/trim';
import classNames from 'helpers/classNames';
import { url } from 'core/Router';
import useAppState from 'hooks/useAppState';
import useSites from 'hooks/useSites';
import { useTranslation } from 'react-i18next';
import useUrlQuery from 'hooks/useUrlQuery';

const cx = classNames();

const SitesListView = () => {
    const { t } = useTranslation();
    const { user } = useAppState();
    const [query, updateQuery] = useUrlQuery();
    const [clients, setClients] = useState(query.clients ? query.clients : []);
    const canViewSites = useSitePolicy('viewAny');

    useEffect(() => {
        updateQuery({
            ...query,
            clients,
            page: undefined, // when changing clients filter, go back to first page
        });
    }, [JSON.stringify(clients)]);
    const [sites, fetchMeta] = useSites({ ...query, clients }, canViewSites);

    if (!canViewSites) {
        return <NotFound />;
    }

    return (
        <>
            <div className={cx('mb-3')}>
                <h1 className={cx('mb-3')}>
                    {t('client:sites.sites_list_view.title')}
                </h1>
                <h2 className={cx('h5')}>
                    {t('client:sites.sites_list_view.intro')}
                </h2>
                <Row form className={cx('align-items-end')}>
                    <Authorize policy={Authorize.SitePolicy} action="create">
                        <Col xs="12" md="auto">
                            <div className={cx('my-3')}>
                                <Link
                                    button
                                    icon="md/ic_add"
                                    to={url('dash.sites.create')}
                                >
                                    {t(
                                        'client:sites.sites_list_view.add_domain'
                                    )}
                                </Link>
                            </div>
                        </Col>
                    </Authorize>
                    <Col xs="12" lg="9">
                        <Form
                            initialValues={{ search: '', clients }}
                            onSubmit={() => null}
                        >
                            <Form.Row>
                                <Col xs="12" md="4" lg="3">
                                    <SearchField
                                        label={t('common:common.search')}
                                    />
                                </Col>
                                <Authorize
                                    policy={Authorize.ClientPolicy}
                                    action="viewAny"
                                >
                                    <Col
                                        xs="12"
                                        md="4"
                                        lg="3"
                                        className={cx('mt-3', 'mt-md-0')}
                                    >
                                        <Form.Field
                                            label={t(
                                                'common:common.filter_by_clients'
                                            )}
                                            name="clients"
                                            type="SelectClient"
                                            searchable
                                            placeholder={t(
                                                'common:common.select'
                                            )}
                                            value={clients}
                                            multiple
                                            onChange={(f, v) =>
                                                setClients([...v])
                                            }
                                        />
                                    </Col>
                                </Authorize>
                            </Form.Row>
                        </Form>
                    </Col>
                </Row>
            </div>
            {fetchMeta.isLoading && <Spinner />}
            {!fetchMeta.isLoading && fetchMeta.isSuccess && (
                <>
                    <AnimatedList
                        list={sites.data}
                        itemComponent={SiteListItem}
                        itemKey="uuid"
                        getItemProps={(site) => ({
                            allowUpgrade: SitePolicy.authorize(
                                'changePlan',
                                user,
                                site
                            ),
                        })}
                    />
                    <Pagination
                        className={cx('mt-3')}
                        currentPage={sites.current_page}
                        lastPage={sites.last_page}
                    />
                </>
            )}
        </>
    );
};

export default SitesListView;
