import React, { useCallback, useEffect } from 'react';

import API from 'helpers/api';
import EditSiteGroupForm from 'views/common/site-groups/components/EditSiteGroupForm';
import Spinner from 'components/Spinner';
import classNames from 'helpers/classNames';
import { url } from 'core/Router';
import useAppState from 'hooks/useAppState';
import useExecuteRequest from 'hooks/useExecuteRequest';
import { useHistory } from 'react-router-dom';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';

const cx = classNames();

const CreateGroupView = () => {
    const { t } = useTranslation();
    const notify = useNotifications();
    const { user } = useAppState();
    const history = useHistory();
    const [request, res, err, creationState] = useExecuteRequest();

    const handleSubmit = useCallback(
        (form) => {
            request(API.post('/common/site-groups', form));
        },
        [request]
    );

    useEffect(() => {
        if (creationState.isSuccess) {
            notify.success(t('common:site_groups.common.created'));
            const path = user.is_admin ? 'admin.siteGroups' : 'dash.siteGroups';
            history.push(url(path));
        }
        if (creationState.isError) {
            notify.error(err.message);
        }
    }, [creationState.isError, creationState.isSuccess]);
    return (
        <>
            <h1>{t('common:site_groups.common.create')}</h1>
            {creationState.isLoading && <Spinner />}
            <div className={cx({ 'd-none': creationState.isLoading })}>
                <EditSiteGroupForm onSubmit={handleSubmit} error={err} />
            </div>
        </>
    );
};

export default CreateGroupView;
