import { Alert, Col, Row } from 'reactstrap';
import React, { useMemo } from 'react';

import Form from 'components/Form';
import Tabs from 'components/Tabs';
import classNames from 'helpers/classNames';
import styles from './InfoBarMessageFormFields.scss';
import useAppState from 'hooks/useAppState';
import useFormState from 'hooks/useFormState';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const getTypeOptions = (t) => {
    return [
        { value: 'success', label: t('admin:bar.label_success') },
        { value: 'warning', label: t('admin:bar.label_warning') },
        { value: 'danger', label: t('admin:bar.label_danger') },
    ];
};

const getTabs = (t, langs) => {
    return langs.map((el) => ({
        label: el.label,
        component: () => (
            <Form.Field
                type="textarea"
                rows={4}
                label={`${t('admin:field_labels.bar.content')} - ${el.label}`}
                name={`translations[${el.value}][content]`}
            />
        ),
    }));
};

const InfoBarMessageFormFields = () => {
    const { t } = useTranslation();
    const { data: formData } = useFormState();
    const { availableLanguages, adminLanguages } = useAppState();
    const langs = useMemo(() => {
        // Find which languages should be rendered. Search for languages available for admin panel in list of all supported languages
        const renderLanguages = availableLanguages.filter((i) => {
            return adminLanguages.includes(i.value);
        });

        return renderLanguages;
    }, [availableLanguages, adminLanguages]);

    const tabs = useMemo(() => getTabs(t, langs), [t, langs]);

    return (
        <Row>
            <Col xs="12" lg="6">
                <Row>
                    <Col xs="12">
                        <Tabs tabs={tabs} />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <Form.Field
                            label={t('admin:field_labels.bar.type')}
                            name="type"
                            required
                            type="Select"
                            options={getTypeOptions(t)}
                        />
                    </Col>
                    {formData.type === 'danger' && (
                        <Col xs="12">
                            <Alert color={formData.type}>
                                {t(
                                    'admin:field_labels.bar.type_danger_warning'
                                )}
                            </Alert>
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col xs="12" md="6">
                        <Form.Field
                            type="Toggle"
                            name="status"
                            inlineLabel={t('admin:field_labels.bar.status')}
                        />
                    </Col>
                </Row>
                <Form.Submit icon="md/ic_save">
                    {t('common:buttons.save')}
                </Form.Submit>
            </Col>
        </Row>
    );
};

InfoBarMessageFormFields.propTypes = {};

InfoBarMessageFormFields.defaultProps = {};

export default InfoBarMessageFormFields;
