import Form from 'components/Form';
import PropTypes from 'prop-types';
import React from 'react';
import ScriptLogo from 'components/ScriptLogo';
import _noop from 'lodash/noop';
import classNames from 'helpers/classNames';
import splitMultilineText from 'helpers/splitMultilineText';
import styles from './EditThirdPartyScriptForm.scss';
import useCookieCategories from 'hooks/useCookieCategories';
import useScriptCategories from 'hooks/useScriptCategories';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const EditThirdPartyScriptForm = ({
    isSaving,
    script,
    onSubmit,
    saveError,
}) => {
    const { t } = useTranslation();
    const [categories, categoriesMeta] = useScriptCategories();
    const [cookieCategories] = useCookieCategories();

    return (
        <Form
            error={saveError}
            onSubmit={(data, e) =>
                onSubmit(
                    {
                        ...data,
                        autoblock_urls: splitMultilineText(
                            data.autoblock_urls || ''
                        ),
                    },
                    e
                )
            }
            initialValues={{
                ...script,
                autoblock_urls: (script.autoblock_urls || []).join('\n'),
            }}
        >
            {({ data: formData }) => (
                <>
                    <Form.Field
                        disabled={isSaving}
                        label={t('admin:field_labels.third_party_script.logo')}
                        name="logo"
                        type="ImagePicker"
                        renderPreview={ScriptLogo}
                        required
                        previewClassName={cx('picker')}
                    />
                    <Form.Field
                        disabled={isSaving}
                        label={t('admin:field_labels.third_party_script.name')}
                        name="name"
                        type="text"
                        required
                    />
                    <Form.Field
                        disabled={isSaving}
                        label={t(
                            'admin:field_labels.third_party_script.consent_key'
                        )}
                        name="consent_key"
                        type="text"
                        maxLength={20}
                        minLength={1}
                        pattern="[a-z0-9-_]*"
                        required
                    />
                    <Form.Field
                        disabled={isSaving}
                        label={t(
                            'admin:field_labels.third_party_script.your_script'
                        )}
                        name="content"
                        type="textarea"
                        rows={10}
                        placeholder={t(
                            'common:site_third_party_scripts.edit_script_form.your_script_placeholder'
                        )}
                    />
                    <Form.Field
                        type="Select"
                        label={t(
                            'admin:field_labels.third_party_script.category'
                        )}
                        name="category"
                        required
                        disabled={
                            isSaving ||
                            categoriesMeta.isLoading ||
                            categoriesMeta.isError
                        }
                        showDefaultOption
                        defaultOptionLabel={
                            categoriesMeta.isLoading
                                ? t('common:common.loading')
                                : t('common:common.select')
                        }
                        options={categories.data}
                    />
                    <Form.Field
                        disabled={isSaving}
                        inlineLabel={t(
                            'admin:field_labels.third_party_script.allows_custom_categories'
                        )}
                        name="allows_custom_categories"
                        type="Toggle"
                    />
                    <Form.Field
                        disabled={isSaving}
                        inlineLabel={t(
                            'admin:field_labels.third_party_script.allows_multiple_categories'
                        )}
                        name="allows_multiple_categories"
                        type="Toggle"
                    />
                    <Form.Field
                        disabled={isSaving}
                        type="ChoiceGroup"
                        label={t(
                            'admin:field_labels.third_party_script.cookie_categories'
                        )}
                        required
                        multiple={!!formData.allows_multiple_categories}
                        name="cookie_categories"
                        options={cookieCategories}
                    />
                    <Form.Field
                        disabled={isSaving}
                        label={t(
                            'admin:field_labels.third_party_script.privacy_policy_url'
                        )}
                        name="privacy_policy_url"
                        type="url"
                    />
                    <Form.Field
                        disabled={isSaving}
                        label={t(
                            'admin:field_labels.third_party_script.privacy_settings_url'
                        )}
                        name="privacy_settings_url"
                        type="url"
                    />
                    <Form.Field
                        disabled={isSaving}
                        label={t(
                            'admin:field_labels.third_party_script.autoblock_urls'
                        )}
                        helpText={t(
                            'admin:field_labels.third_party_script.autoblock_urls_help'
                        )}
                        rows={6}
                        name="autoblock_urls"
                        type="textarea"
                    />
                    <Form.Submit loading={isSaving} icon="md/ic_save">
                        {t('common:buttons.save')}
                    </Form.Submit>
                </>
            )}
        </Form>
    );
};

EditThirdPartyScriptForm.propTypes = {
    isSaving: PropTypes.bool,
    script: PropTypes.object,
    onSubmit: PropTypes.func,
    saveError: PropTypes.instanceOf(Error),
};

EditThirdPartyScriptForm.defaultProps = {
    isSaving: false,
    script: {},
    onSubmit: _noop,
    saveError: null,
};

export default EditThirdPartyScriptForm;
