import BasePolicy from './BasePolicy';
import usePolicy from 'hooks/usePolicy';

export const VIEW_ALL_PERMISSION = 'CUSTOM_SCRIPTS.VIEW_ALL';
export const VIEW_OWN_PERMISSION = 'CUSTOM_SCRIPTS.VIEW_OWN';
export const CREATE_PERMISSION = 'CUSTOM_SCRIPTS.CREATE';
export const UPDATE_ALL_PERMISSION = 'CUSTOM_SCRIPTS.UPDATE_ALL';
export const UPDATE_OWN_PERMISSION = 'CUSTOM_SCRIPTS.UPDATE_OWN';

export default class CustomScriptPolicy extends BasePolicy {
    static viewAny(user) {
        return user.canAny([VIEW_ALL_PERMISSION, VIEW_OWN_PERMISSION]);
    }

    static view(user, script) {
        if (user.can(VIEW_ALL_PERMISSION)) {
            return true;
        }

        if (user.can(VIEW_OWN_PERMISSION)) {
            return CustomScriptPolicy.userOwnsModel(user, script);
        }
    }

    static create(user) {
        return user.can(CREATE_PERMISSION);
    }

    static update(user, script) {
        // user with specific permission can manage all scripts
        if (user.can(UPDATE_ALL_PERMISSION)) {
            return true;
        }

        // user can update scripts belonging to their own client
        if (
            user.can(UPDATE_OWN_PERMISSION) &&
            CustomScriptPolicy.userOwnsModel(user, script)
        ) {
            return true;
        }
    }

    static delete(user, script) {
        return CustomScriptPolicy.update(user, script);
    }
}

/**
 * Check if user is allowed to perform an action
 * @param {string} permission
 * @param {script} model
 * @returns {boolean}
 */
export const useCustomScriptPolicy = (permission, model) => {
    return usePolicy(CustomScriptPolicy, permission, model);
};
