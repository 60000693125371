import React, { useMemo } from 'react';

import { CustomInput } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'helpers/classNames';
import styles from './Checkbox.scss';

const cx = classNames(styles);

const Checkbox = ({ label, value, errorFeedback, ...props }) => {
    const id = useMemo(Math.random);
    return (
        <CustomInput
            {...props}
            className={cx('checkbox')}
            id={`checkox-component-${id}`}
            type="checkbox"
            label={label || 'checkbox label'}
            value={1}
            checked={typeof value === 'undefined' ? undefined : Boolean(value)}
        >
            {errorFeedback}
        </CustomInput>
    );
};

Checkbox.propTypes = {
    className: PropTypes.string,
    label: PropTypes.node,
    errorFeedback: PropTypes.node,
    value: PropTypes.bool,
};

export default Checkbox;
