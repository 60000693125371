import { Col, Row } from 'reactstrap';

import API from 'helpers/api';
import EditCustomScriptForm from '../components/EditCustomScriptForm';
import Notification from 'components/Notification';
import React from 'react';
import Spinner from 'components/Spinner';
import classNames from 'helpers/classNames';
import { convertImageBase64ToBlob } from 'helpers/images';
import styles from './EditCustomScriptView.scss';
import { useCustomScriptPolicy } from 'policies/CustomScriptPolicy';
import useExecuteRequest from 'hooks/useExecuteRequest';
import { useParams } from 'react-router-dom';
import useRequest from 'hooks/useRequest';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const EditCustomScriptView = () => {
    const { t } = useTranslation();
    const { script: scriptUUID } = useParams();
    const [save, , saveErr, saveMeta] = useExecuteRequest();
    const [script, , fetchMeta] = useRequest(
        API.get(`/client/custom-scripts/${scriptUUID}`)
    );
    const canUpdate = useCustomScriptPolicy('update', script);
    console.log(fetchMeta);

    if (fetchMeta.isLoading) {
        return <Spinner />;
    }

    const handleSubmit = (input) => {
        if (!canUpdate) {
            return;
        }
        const imageFile = convertImageBase64ToBlob(input.logo || '');
        const url = `/client/custom-scripts/${scriptUUID}`;
        const saveRequest = API.request('PUT', url, {
            ...input,
            logo: script.logo || undefined,
            newLogo: imageFile || undefined,
        });
        save(saveRequest);
    };

    if (!script) {
        return null;
    }

    return (
        <>
            {saveMeta.isSuccess && (
                <Notification type="success">
                    {t('client:custom-scripts.edit_custom_script_view.saved')}
                </Notification>
            )}
            {saveMeta.isError && (
                <Notification type="error">{saveErr.message}</Notification>
            )}
            <h1>
                {t('client:custom-scripts.edit_custom_script_view.title', {
                    name: script.name,
                })}
            </h1>
            <Row>
                <Col xs="12" sm="8" md="4">
                    <EditCustomScriptForm
                        isSaving={saveMeta.isLoading}
                        saveError={saveErr}
                        onSubmit={handleSubmit}
                        script={script}
                    />
                </Col>
            </Row>
        </>
    );
};

EditCustomScriptView.propTypes = {};

EditCustomScriptView.defaultProps = {};

export default EditCustomScriptView;
