import React, { useEffect, useRef, useState } from 'react';

import Form from 'components/Form';
import ImagePickerPreview from 'components/Form/inputs/ImagePicker/components/ImagePickerPreview/ImagePickerPreview';
import PropTypes from 'prop-types';
import api from 'helpers/api';
import classNames from 'helpers/classNames';
import styles from './UploadIconTab.scss';
import useExecuteRequest from 'hooks/useExecuteRequest';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);
const ALLOWED_TYPES = ['image/png', 'image/jpeg', 'image/svg+xml'];

const UploadIconTab = ({ onChange, setModalSize }) => {
    const { t } = useTranslation();
    const [uploadedFile, setUploadedFile] = useState(null);
    const [isValidationError, setIsValidationError] = useState(false);

    const [upload, uploadRes, uploadErr, uploadMeta] = useExecuteRequest();

    // Set modal size to wanted size
    useEffect(() => {
        setModalSize('md');
    }, []);

    const handleUpload = (form) => {
        const file = form.file;
        if (!file || uploadMeta.isLoading) {
            return;
        }

        if (!ALLOWED_TYPES.includes(file.type)) {
            alert(t('components:image_picker_field.invalid_file_type'));
            return;
        }

        upload(api.request('post', '/common/icons', form));
    };

    useEffect(() => {
        if (uploadMeta.isSuccess) {
            onChange(uploadRes.filename);
        }
    }, [uploadMeta.isSuccess]);

    /**
     * Show preview of uploaded file if the file is svg
     * @param {object} fileInfo - info about uploaded file to input file
     */
    const handleFilePreview = (fileInfo) => {
        // Validate if it's right file type
        if (fileInfo?.type !== 'image/svg+xml') {
            // Set validation error to true to show right message
            setIsValidationError(true);

            // Reset info about preview to hide it
            setUploadedFile(null);
            return;
        }

        // Hide validation error message
        setIsValidationError(false);

        showPreview(fileInfo);
    };

    const showPreview = (file) => {
        var image = URL.createObjectURL(file);
        setUploadedFile(image);
    };

    return (
        <Form
            error={uploadErr}
            initialValues={{ file: null }}
            onSubmit={handleUpload}
            isSubmitting={uploadMeta.isLoading}
            className={'uploadIconTabForm'}
        >
            <div className={cx('d-flex justify-content-center')}>
                <Form.Field
                    required
                    label={t('components:icon_picker_field.choose_file')}
                    type="FilePicker"
                    name="file"
                    onChange={(name, value) => handleFilePreview(value)}
                    accept={ALLOWED_TYPES.join(', ')}
                    className={cx('w-100')}
                />
            </div>

            {isValidationError && (
                <div className={cx('text-danger', 'mb-3')}>
                    {t('components:icon_picker_field.wrong_file_error')}
                    {'Error! Wrong file. Acceptable format: SVG'}
                </div>
            )}

            {uploadedFile && (
                <ImagePickerPreview className={cx('mb-3')} src={uploadedFile} />
            )}
            <div className={cx('d-flex', 'justify-content-end')}>
                <Form.Submit
                    disabled={isValidationError}
                    label={t('components:icon_picker_field.upload_and_confirm')}
                />
            </div>
        </Form>
    );
};

UploadIconTab.propTypes = {
    onChange: PropTypes.func,
    setModalSize: PropTypes.func,
};

export default UploadIconTab;
2;
