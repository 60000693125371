import React, { useState } from 'react';

import AuthViewLayout from 'views/auth/components/AuthViewLayout';
import { Col } from 'reactstrap';
import Form from 'components/Form';
import Icon from 'components/Icon';
import classNames from 'helpers/classNames';
import { countries } from 'constants/geoData';
import styles from './RegisterView.scss';
import { url } from 'core/Router';
import useAppState from 'hooks/useAppState';
import useFlashData from 'hooks/useFlashData';
import useInstanceSettings from 'hooks/useInstanceSettings';
import useLang from 'hooks/useLang';
import useTranslate from 'hooks/useTranslate';
import useUrlQuery from 'hooks/useUrlQuery';

const cx = classNames(styles);

const INITIAL_VALUES = {
    full_name: '',
    email: '',
    password: '',
    password_confirmation: '',
    company_name: '',
    preferred_language: 'en',
    country_code: '',
    has_accepted_terms: false,
};
const BENEFITS = [
    'script_management',
    'periodic_scan',
    'automated_policy',
    'trails',
    'banner_customization',
    'statistics',
    'language_management',
    'optin_rates',
    'javascript_api',
];
const RegisterView = () => {
    const { changeLanguage, lang: currentLang } = useLang();
    const { adminLanguages } = useAppState();
    const { is_whitelabel, allow_login } = useInstanceSettings();
    const [trans] = useTranslate('guest:register_view');
    const [language, setLanguage] = useState(
        currentLang || INITIAL_VALUES.preferred_language
    );
    const [country, setCountry] = useState(INITIAL_VALUES.country_code);

    const handleLangChange = (name, value) => {
        const newLang = value[0] || 'en';
        setLanguage(newLang);
        changeLanguage(newLang);
    };
    const handleCountryChange = (name, value) => {
        setCountry(value);
    };
    const flash = useFlashData();
    const [{ selectedPlan, inviteCode, gclid }] = useUrlQuery();

    return (
        <AuthViewLayout
            title={trans('title')}
            footer={
                allow_login && (
                    <h1 className={cx('heading')}>
                        {trans(
                            is_whitelabel
                                ? 'have_account_whitelabel'
                                : 'have_account'
                        )}{' '}
                        <a href={url('auth.login')}>{trans('log_in')}</a>
                    </h1>
                )
            }
            sidebar={
                !is_whitelabel && (
                    <Col className={cx('benefits-column', 'text-left')}>
                        <Col className={cx('inner', 'text-left')}>
                            <img
                                src="/img/cookie_consent_management_platform_statistics.png"
                                alt=""
                                className={cx('mb-4')}
                            />
                            <h5 className={cx('font-weight-bold')}>
                                {trans(
                                    'cookiefirst_register_advertisement_title'
                                )}
                            </h5>
                            <p>
                                {trans(
                                    'cookiefirst_register_advertisement_text'
                                )}
                            </p>
                            <div
                                className={cx(
                                    'benefits-list',
                                    'font-weight-bold',
                                    'mt-3',
                                    'mb-4'
                                )}
                            >
                                {BENEFITS.map((benefit) => (
                                    <p key={benefit} className={cx('mb-1')}>
                                        <Icon
                                            icon="md/ic_done"
                                            color="primary"
                                            className={cx('mr-2')}
                                        />
                                        {trans(
                                            `cookiefirst_benefits.${benefit}`
                                        )}
                                    </p>
                                ))}
                            </div>
                            <a
                                href="https://cookiefirst.com"
                                className={cx(
                                    'font-weight-bold',
                                    'btn',
                                    'mb-2',
                                    'text-center'
                                )}
                            >
                                {trans(
                                    'cookiefirst_register_advertisement_button'
                                )}
                            </a>
                        </Col>
                    </Col>
                )
            }
        >
            <Form
                initialValues={{
                    ...INITIAL_VALUES,
                    ...flash.input(),
                    preferred_language: language,
                }}
                error={{ validation: flash.errors() }}
                method="POST"
                action={url('auth.register')}
            >
                {({ data: formData }) => (
                    <>
                        {adminLanguages.length > 1 && (
                            <Form.Field
                                label={trans('language')}
                                name="preferred_language"
                                className={cx('mb-2')}
                                required
                                adminOnly
                                onChange={handleLangChange}
                                type="LanguageDropdown"
                            />
                        )}
                        <input
                            type="hidden"
                            name="preferred_language"
                            value={language}
                        />
                        <Form.Field
                            className={cx('mb-2')}
                            placeholder={trans('full_name')}
                            type="text"
                            name="full_name"
                            required
                        />
                        <Form.Field
                            className={cx('mb-2')}
                            placeholder={trans('company_name')}
                            type="text"
                            name="company_name"
                        />
                        <Form.Field
                            className={cx('mb-2')}
                            placeholder={trans('email')}
                            type="email"
                            name="email"
                            required
                        />
                        <Form.Field
                            className={cx('mb-2')}
                            placeholder={trans('password')}
                            type="password"
                            name="password"
                            required
                        />
                        <Form.Field
                            className={cx('mb-2')}
                            placeholder={trans('password_confirmation')}
                            type="password"
                            name="password_confirmation"
                            required
                        />
                        <Form.Field
                            label={trans('country_code')}
                            name="country_code"
                            placeholder="---"
                            type="SingleDropdownInput"
                            searchable
                            required
                            options={countries}
                            onChange={handleCountryChange}
                        />
                        <input
                            type="hidden"
                            name="country_code"
                            value={country}
                        />
                        {!!selectedPlan && (
                            <input
                                type="hidden"
                                name="stripe_plan"
                                value={selectedPlan}
                            />
                        )}
                        {!!inviteCode && (
                            <input
                                type="hidden"
                                name="invite_code"
                                value={inviteCode}
                            />
                        )}
                        {!!gclid && (
                            <input
                                type="hidden"
                                name="conversion_id"
                                value={gclid}
                            />
                        )}
                        <Form.Field
                            className={cx('mb-2')}
                            type="Toggle"
                            name="has_accepted_terms"
                            inlineLabel={
                                <div className={cx('terms-label')}>
                                    <span>{trans('before_terms_label')}</span>{' '}
                                    <a
                                        href="https://cookiefirst.com/legal/general-terms-conditions/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {trans('terms_and_conditions')}
                                    </a>{' '}
                                    <span>{trans('before_dpa_label')}</span>{' '}
                                    <a
                                        href="https://cookiefirst.com/legal/data-processing-agreement/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {trans('data_processing_agreement')}
                                    </a>
                                    .
                                </div>
                            }
                        />
                        <Form.Submit
                            id="signup"
                            block
                            disabled={!formData.has_accepted_terms}
                            className={cx('font-weight-bold')}
                        >
                            {trans('sign_up')}
                        </Form.Submit>
                    </>
                )}
            </Form>
        </AuthViewLayout>
    );
};

export default RegisterView;
