import Button from 'components/Button/Button';
import Col from 'reactstrap/es/Col';
import Form from 'components/Form/Form';
import ImagePickerPreview from 'components/Form/inputs/ImagePicker/components/ImagePickerPreview/ImagePickerPreview';
import PropTypes from 'prop-types';
import React from 'react';
import Row from 'reactstrap/es/Row';
import classNames from 'helpers/classNames';
import styles from './ConfirmIconPick.scss';
import useInstanceSettings from 'hooks/useInstanceSettings';
import { useTranslation } from 'react-i18next';

const cx = classNames(styles);

const ConfirmIconPick = ({ onConfirm, onCancel, icon, setModalSize }) => {
    const { t } = useTranslation();
    const { banner_static_files_url } = useInstanceSettings();

    return (
        <div className={cx('wrapper')}>
            <ImagePickerPreview
                src={`${banner_static_files_url}/icons/${icon}`}
            />
            <Form.Row className={cx('justify-content-end', 'mt-3')}>
                <Col xs="auto">
                    <Button
                        outline
                        label={t('common:confirm_modal.cancel')}
                        onClick={() => {
                            setModalSize('lg');
                            onCancel();
                        }}
                    />
                </Col>
                <Col xs="auto">
                    <Button
                        label={t('common:confirm_modal.confirm')}
                        onClick={() => {
                            onConfirm(icon);
                        }}
                    />
                </Col>
            </Form.Row>
        </div>
    );
};

ConfirmIconPick.propTypes = {
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    icon: PropTypes.string,
    setModalSize: PropTypes.func,
};

export default ConfirmIconPick;
