import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import classNames from 'helpers/classNames';
import styles from './Switch.module.scss';

const cx = classNames(styles);

const Switch = ({
    onChange,
    checked,
    disabled,
    color = 'primary',
    className = '',
    invalid,
    ...props
}) => {
    const handleChange = useCallback(() => onChange(), [onChange]);
    return (
        <button
            {...props}
            onClick={handleChange}
            className={cx(className, 'button', `text-${color}`, {
                checked,
                disabled,
            })}
            disabled={disabled}
            aria-invalid={invalid}
            type="button"
            role="checkbox"
            aria-checked={checked}
        />
    );
};

Switch.propTypes = {
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    invalid: PropTypes.bool,
    disabled: PropTypes.bool,
    color: PropTypes.string,
    className: PropTypes.string,
};

export default Switch;
